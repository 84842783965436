import React from 'react';

import styled, { css } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';

interface SortLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  isSorted?: boolean;
  onClick?: () => void;
}

const Layout = styled.button`
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  font: inherit;
  color: inherit;
  cursor: pointer;
`;

interface IconWrapperProps {
  isSorted?: boolean;
}

const IconWrapper = styled.span<IconWrapperProps>`
  position: absolute;
  right: ${toRem(-theme.table.label.offset)};

  ${({ isSorted }) =>
    isSorted &&
    css`
      svg {
        transform: rotateZ(180deg);
      }
    `}
`;

const SortLabel: React.FC<SortLabelProps> = ({
  label,
  isSorted,
  onClick,
}: SortLabelProps) => {
  return (
    <Layout onClick={onClick}>
      {label}

      <IconWrapper isSorted={isSorted}>
        <Icon
          icon="Dropdown"
          width={11}
          height={11}
          fill={theme.color.text[88]}
        />
      </IconWrapper>
    </Layout>
  );
};

export default SortLabel;
