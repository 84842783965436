import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';
import { Label } from '../Typography';

interface GroupProps {
  title: string;
}

const Grouping = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${theme.color.background.light};
  border-radius: ${toRem(10)};
  margin-top: ${toRem(16)};
  padding: ${toRem(8)};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GroupLabel = styled(Label)`
  display: flex;
  font-size: ${toRem(11)};
  font-weight: ${theme.font.weight.semiBold};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const ToggleArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${toRem(20)};
  width: ${toRem(20)};
  background: ${theme.color.background.medium};
  border-radius: ${toRem(5)};
  cursor: pointer;
`;

interface ChildrenProps {
  isVisible?: boolean;
}

const ChildrenGroup = styled.div<ChildrenProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 0;
  overflow: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      height: auto;
      overflow: visible;
    `}
`;

const FilterGroup: React.FC<GroupProps> = ({ title, children }) => {
  const [isExpanded, setExpanded] = useState(true);

  return (
    <Grouping>
      <Header>
        <GroupLabel>{title}</GroupLabel>
        <ToggleArrow
          onClick={() => {
            setExpanded(!isExpanded);
          }}>
          {isExpanded ? (
            <Icon icon="DirectionUp" width={11} height={11} />
          ) : (
            <Icon icon="DirectionDown" width={11} height={11} />
          )}
        </ToggleArrow>
      </Header>
      <ChildrenGroup isVisible={isExpanded}>{children}</ChildrenGroup>
    </Grouping>
  );
};

export default FilterGroup;
