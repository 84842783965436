import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import {
  Button,
  FieldWrapper,
  H1,
  PasswordInput,
  H3,
} from '@awareness-ui/components';
import { toRem, theme } from '@awareness-ui/design';
import { getPasswordError } from '@awareness/util';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken, setRequiredPassword } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { logout } from '@awareness/logout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  flex-flow: column nowrap;
  align-items: center;
  max-width: 400px;
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 400px;
`;

const List = styled.li`
  font-size: ${toRem(16)};
  margin: ${toRem(18)} 0;
  font-family: ${theme.font.family.averta};
  font-weight: ${theme.font.weight.light};
`;

interface Props extends RouteChildrenProps {}

export const ChangePassword: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);
  const dispatch = useDispatch();
  const onUpdatePassword = async (event: React.MouseEvent) => {
    event.preventDefault();
    const passwordError = password && getPasswordError(password);
    if (password !== repeatPassword) {
      toast.error('Passwords do not match');
      return;
    } else if (passwordError) {
      toast.error(passwordError);
      return;
    }

    setLoading(true);
    const url = `${apiEndpoint}/me/password`;
    const body = new FormData();
    body.append('password', password);
    const method = 'POST';

    try {
      const response = await apiFetchAsync({ url, token, method, body });

      if (response.status !== 204) {
        throw new Error('Uh-oh! Changing your password failed.');
      }

      toast.success('Password updated!');
      setPassword('');
      setRepeatPassword('');
      dispatch(setRequiredPassword(false));
      dispatch(logout());
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  return (
    <>
      <Container>
        <Button
          style={{ alignSelf: 'flex-start' }}
          label="< Back"
          appearance="secondary"
          onClick={() => history.goBack()}
        />
      </Container>
      <FormContainer>
        <Form onSubmit={onUpdatePassword as any}>
          <H1>Change Password</H1>
          <Fieldset disabled={loading}>
            <FieldWrapper label="Password">
              <PasswordInput
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </FieldWrapper>
            <FieldWrapper
              label="Repeat Password"
              message={
                repeatPassword && password !== repeatPassword
                  ? 'Passwords do not match'
                  : ''
              }>
              <PasswordInput
                onChange={(e) => setRepeatPassword(e.currentTarget.value)}
              />
            </FieldWrapper>
          </Fieldset>
          <Button label="Submit" onClick={onUpdatePassword} />
        </Form>
        <div>
          <H3>Password Must Contain</H3>
          <ul>
            <List>Minimum length of eight (8) characters</List>
            <List>One Numeric (0-9)</List>
            <List>One Alphabetic lower-case(a-z)</List>
            <List>One Alphabetic upper-case(A-Z)</List>
            <List>One Special Character (ie. !@#$%^&)</List>
          </ul>
        </div>
      </FormContainer>
    </>
  );
};
