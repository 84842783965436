import { createSlice } from 'robodux';

import { ReduxState } from '@awareness/types';

export const LOCAL_API_ENDPOINT = 'http://localhost:4000';
export const STAGING_API_ENDPOINT = 'https://api.stage.hayden-awareness.com';
export const PRODUCTION_API_ENDPOINT = 'https://api.hayden-awareness.com';
export const API_ENDPOINTS = {
  local: LOCAL_API_ENDPOINT,
  stage: STAGING_API_ENDPOINT,
  prod: PRODUCTION_API_ENDPOINT,
};

type ApiEndpointType = 'local' | 'stage' | 'prod';
const API_ENDPOINT_TYPE = (process.env.REACT_APP_API_ENDPOINT_TYPE ||
  'prod') as ApiEndpointType;

export const DEFAULT_API_ENDPOINT = API_ENDPOINTS[API_ENDPOINT_TYPE];

const { actions, reducer, name } = createSlice({
  name: 'apiEndpoint',
  initialState: DEFAULT_API_ENDPOINT,
  reducers: {
    setApiEndpoint: (_, payload: string) => payload,
    resetApiEndpoint: () => DEFAULT_API_ENDPOINT,
  },
});
export const { setApiEndpoint, resetApiEndpoint } = actions;

export const getApiEndpoint = (state: ReduxState) => state.apiEndpoint;

const reducers = { [name]: reducer };

export { reducers };
