import { Reducer } from 'redux';

interface Reducers {
  [key: string]: Reducer;
}
export interface Package {
  reducers: Reducers;
}

const PACKAGES = [
  require('@awareness/alerts'),
  require('@awareness/api-endpoint'),
  require('@awareness/api-fetch'),
  require('@awareness/assets'),
  require('@awareness/auth'),
  require('@awareness/bootup'),
  require('@awareness/developer-settings'),
  require('@awareness/logout'),
  require('@awareness/user'),
  require('@awareness/channel'),
];

const use = (packages: Package[]) => {
  const reducers = combine<Reducers>(packages, 'reducers');
  const actions = combine(packages, 'actions');
  const effects = combine(packages, 'effects');
  const selectors = combine(packages, 'selectors');
  const sagas = combine<any>(packages, 'sagas');

  return {
    reducers,
    actions,
    effects,
    selectors,
    sagas,
  };
};

const combine = <T>(packages: Package[], name: string): T => {
  const merged: any = {};

  if (!name) {
    return merged;
  }

  packages.forEach((pkg: any) => {
    if (!pkg.hasOwnProperty(name)) {
      return;
    }

    Object.keys(pkg[name]).forEach((key) => {
      if (merged.hasOwnProperty(key)) {
        return;
      }

      merged[key] = pkg[name][key];
    });
  });

  return merged;
};

export default use(PACKAGES);
