export const PASSWORD_STRENGTH_ERROR = `Password must contain a lowercase letter (abc), uppercase letter (ABC), and a number (123) and special character (!@#$%^&).`;
export const PASSWORD_LENGTH_ERROR = `Password must be at least 8 characters long.`;
export const EMAIL_ERROR = `Email is invalid.`;
export const CELL_ERROR = `Cell number is invalid.`;

export function getPasswordError(password: string) {
  if (password.length < 8) {
    return PASSWORD_LENGTH_ERROR;
  }
  if (!password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*(\W|\d))/)) {
    return PASSWORD_STRENGTH_ERROR; // Password error message
  }
}

export function getEmailError(email?: string) {
  if (!email || !email.match(/.+@.+\..+/)) {
    return EMAIL_ERROR;
  }
}

export function formatCellNumber(cell: string) {
  return cell.replace(/[^0-9.]/g, '');
}

export function getCellNumberError(cell: string) {
  if (cell.length < 10) {
    return CELL_ERROR;
  }
}
