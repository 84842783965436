import { createSlice } from 'robodux';

import { ReduxState, ChannelUnit, UnitT } from '../types';
import {
  CHANNEL_AIR_TEMPRATURE,
  CHANNEL_RAIN_DIFF,
  CHANNEL_DELTA_RAIN,
  CHANNEL_TOTAL_RAIN,
  CHANNEL_BAROMETRIC_PRESSURE,
  CHANNEL_WIND_SPEED,
  CHANNEL_DEW_POINT,
  CHANNEL_WIND,
} from '@awareness/util';

const initialState: ChannelUnit = {
  [CHANNEL_AIR_TEMPRATURE]: '°F',
  [CHANNEL_DEW_POINT]: '°F',
  [CHANNEL_DELTA_RAIN]: 'in',
  [CHANNEL_RAIN_DIFF]: 'in',
  [CHANNEL_TOTAL_RAIN]: 'in',
  [CHANNEL_BAROMETRIC_PRESSURE]: 'hPa',
  [CHANNEL_WIND_SPEED]: 'mps',
  [CHANNEL_WIND]: 'mph',
};

const { actions, reducer, name } = createSlice({
  name: 'channelUnit',
  initialState,
  reducers: {
    updateChannelUnit: (state, payload: UnitT) => {
      return { ...state, [payload.name]: payload.unit };
    },
  },
});

export const { updateChannelUnit } = actions;

export const getChannelUnit = (state: ReduxState) => state.channelUnit;

const reducers = { [name]: reducer };

export { reducers };
