import { createTable } from 'robodux';
import { createSelector } from 'reselect';
import {
  Asset,
  AssetGeoJSON,
  AssetImage,
  AssetState,
  ReduxState,
  Threshold,
} from '@awareness/types';

const assetsSlice = createTable<Asset>({
  name: 'assets',
  initialState: {},
  extraReducers: {
    addAssetImage: (
      state: AssetState,
      { id, image }: { id: number; image: AssetImage }
    ) => {
      const asset = state[id];
      if (!asset) return state;
      const images = (asset.images || []).concat(image);
      return { ...state, [id]: { ...asset, images } };
    },
    patchAssetImage: (
      state: AssetState,
      { id, image }: { id: number; image: AssetImage }
    ) => {
      const asset = state[id];
      if (!asset || !asset.images) return state;
      const images = asset.images;
      const imageIndex = images.findIndex((el) => el.id === image.id);
      images[imageIndex] = image;
      return { ...state, [id]: { ...asset, images } };
    },
    deleteAssetImage: (
      state: AssetState,
      { id, imageId }: { id: number; imageId: number }
    ) => {
      const asset = state[id];
      if (!asset || !asset.images) return state;
      const images = asset.images.filter((el) => el.id !== imageId);
      return { ...state, [id]: { ...asset, images } };
    },
    addAssetThreshold: (
      state: AssetState,
      { id, threshold }: { id: number; threshold: Threshold }
    ) => {
      const asset = state[id];
      if (!asset) return state;
      const thresholds = (asset.thresholds || []).concat(threshold);
      return { ...state, [id]: { ...asset, thresholds } };
    },
    patchAssetThreshold: (
      state: AssetState,
      { id, threshold }: { id: number; threshold: Threshold }
    ) => {
      const asset = state[id];
      if (!asset || !asset.thresholds) return state;
      const thresholds = asset.thresholds;
      const thresholdIndex = thresholds.findIndex(
        (el) => el.id === threshold.id
      );
      thresholds[thresholdIndex] = threshold;
      return { ...state, [id]: { ...asset, thresholds } };
    },
    deleteAssetThreshold: (
      state: AssetState,
      { id, thresholdId }: { id: number; thresholdId: number }
    ) => {
      const asset = state[id];
      if (!asset || !asset.thresholds) return state;
      const thresholds = asset.thresholds.filter((el) => el.id !== thresholdId);
      return { ...state, [id]: { ...asset, thresholds } };
    },
  },
});

export const {
  set: setAssets,
  add: addAssets,
  remove: removeAssets,
  patch: patchAssets,
  reset: resetAssets,
} = assetsSlice.actions;

export const addAssetImage = (payload: { id: number; image: AssetImage }) => ({
  type: 'addAssetImage',
  payload,
});
export const patchAssetImage = (payload: {
  id: number;
  image: AssetImage;
}) => ({
  type: 'patchAssetImage',
  payload,
});
export const deleteAssetImage = (payload: { id: number; imageId: number }) => ({
  type: 'deleteAssetImage',
  payload,
});
export const addAssetThreshold = (payload: {
  id: number;
  threshold: Threshold;
}) => ({
  type: 'addAssetThreshold',
  payload,
});
export const patchAssetThreshold = (payload: {
  id: number;
  threshold: Threshold;
}) => ({
  type: 'patchAssetThreshold',
  payload,
});
export const deleteAssetThreshold = (payload: {
  id: number;
  thresholdId: number;
}) => ({
  type: 'deleteAssetThreshold',
  payload,
});

export const getAssets = (state: ReduxState) => state.assets;

export const getAssetsCount = createSelector(
  getAssets,
  (assets) => Object.keys(assets).length
);

export const getAssetById = createSelector(
  getAssets,
  (_: ReduxState, id: number | undefined) => id,
  (assets, id): Asset | undefined => (id !== undefined ? assets[id] : undefined)
);

export const getAssetsByIds = createSelector(
  getAssets,
  (_: ReduxState, ids: number[]) => ids,
  (assets, ids): Asset[] =>
    ids.map((id) => assets[id]).filter(Boolean) as Asset[]
);

export const getAssetGeoJSONList = createSelector(
  getAssets,
  (assets): AssetGeoJSON[] => transformAssetsToGeoJSON(Object.values(assets))
);

export const transformAssetsToGeoJSON = (assets: Asset[]) =>
  assets
    .map((asset) => {
      if (!asset) return;

      const {
        id,
        latitude,
        longitude,
        status,
        online,
        name,
        thingsquare_id,
        has_gateway,
        type,
        classification,
      } = asset;

      return {
        type: 'Feature',
        properties: {
          id,
          status,
          online,
          name,
          has_gateway,
          thingsquare_id,
          type,
          classification,
        },
        geometry: { type: 'Point', coordinates: [longitude, latitude] },
      };
    })
    .filter(Boolean) as AssetGeoJSON[];

const reducers = {
  [assetsSlice.name]: assetsSlice.reducer,
};

export { reducers };
