import React, { RefObject } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

import { Loader } from '../Loader';

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  appearance?: 'primary' | 'secondary' | 'tertiary';
  size?: 'large' | 'small';
  isLoading?: boolean;
  to?: string; // for router Link
  ref?: RefObject<HTMLButtonElement>;
}

const ButtonStyles = css<Partial<ButtonProps>>`
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: ${toRem(theme.button.height.large)};
  padding: 0 ${toRem(27.5)};
  border-radius: ${toRem(theme.button.height.large / 2)};
  border: none;
  background-color: ${theme.color.primary[100]};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${toRem(13.75)};
  color: ${theme.color.white};
  transition: background-color ${theme.transition.button.default};
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.primary[88]};
  }

  ${({ appearance }) =>
    appearance === 'secondary' &&
    css`
      border: ${toRem(1)} solid ${theme.color.border.medium};
      background-color: white;
      color: ${theme.color.text[100]};

      &:hover {
        background-color: ${theme.color.background.medium};
      }
    `}

  ${({ appearance }) =>
    appearance === 'tertiary' &&
    css`
      background-color: ${theme.color.tertiary[100]};
      color: ${theme.color.white};

      &:hover {
        background-color: ${theme.color.tertiary[88]};
      }
    `}
  
  ${({ size }) =>
    size === 'small' &&
    css`
      height: ${toRem(theme.button.height.small)};
      padding: 0 ${toRem(16.5)};
      border-radius: ${toRem(theme.button.height.small / 2)};
      font-size: ${toRem(11)};
    `}
  
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #e8e8e8;
      color: ${theme.color.text[66]};
      cursor: auto;

      &:hover {
        background-color: #e8e8e8;
      }
    `}
`;

const Layout = styled.button`
  ${ButtonStyles}
`;

const StyledLink = styled(Link)<Partial<ButtonProps>>`
  ${ButtonStyles}
  text-decoration: none;

  ${({ appearance }) =>
    appearance === 'primary' &&
    css`
      &:active {
        color: ${theme.color.white};
      }
      &:vistited {
        color: ${theme.color.white};
      }
    `}

  ${({ appearance }) =>
    appearance === 'secondary' &&
    css`
      &:active {
        color: ${theme.color.text[100]};
      }
      &:vistited {
        color: ${theme.color.text[100]};
      }
    `}

  ${({ appearance }) =>
    appearance === 'tertiary' &&
    css`
      &:active {
        color: ${theme.color.white};
      }
      &:vistited {
        color: ${theme.color.white};
      }
    `}
`;

const Label = styled.span<Partial<ButtonProps>>`
  color: inherit;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
`;

const LoaderContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Button: React.FC<ButtonProps> = ({
  label,
  type = 'button',
  appearance = 'primary',
  size = 'large',
  disabled,
  isLoading,
  to,
  onClick,
  ref,
  className,
  style,
}) => {
  if (to)
    return (
      <StyledLink
        appearance={appearance}
        size={size}
        disabled={disabled}
        to={to}
        onClick={disabled ? undefined : (onClick as any)}
        className={className}
        style={style}>
        <Label isLoading={isLoading}>{label}</Label>
        {isLoading && (
          <LoaderContainer>
            <Loader
              size={size === 'large' ? 'large' : 'small'}
              color={
                appearance === 'primary'
                  ? theme.color.white
                  : theme.color.text[100]
              }
            />
          </LoaderContainer>
        )}
      </StyledLink>
    );
  else
    return (
      <Layout
        type={type}
        appearance={appearance}
        size={size}
        disabled={disabled}
        onClick={disabled ? undefined : (onClick as any)}
        className={className}
        ref={ref}
        style={style}>
        <Label isLoading={isLoading}>{label}</Label>
        {isLoading && (
          <LoaderContainer>
            <Loader
              size={size === 'large' ? 'large' : 'small'}
              color={
                appearance === 'primary'
                  ? theme.color.white
                  : theme.color.text[100]
              }
            />
          </LoaderContainer>
        )}
      </Layout>
    );
};

export default Button;
