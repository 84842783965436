import React, { useState, useEffect } from 'react';
import { TbArrowDownTail } from 'react-icons/tb';
import styled from 'styled-components';
import { updateChannelUnit } from '@awareness/channel';
import { formatChannelName, theme, toRem } from '@awareness-ui/design';
import {
  barometricPressureValueConversion,
  windSpeedValueConversion,
  rainDiffValueConversion,
  airTemperatureValueConversion,
  MEASUREMENT_ICON_NAMES,
  CHANNEL_AIR_TEMPRATURE,
  CHANNEL_WIND,
  CHANNEL_RAIN_DIFF,
  CHANNEL_DELTA_RAIN,
  CHANNEL_WIND_SPEED,
  CHANNEL_BAROMETRIC_PRESSURE,
  CHANNEL_TOTAL_RAIN,
  CHANNEL_DEW_POINT,
  TEMPERATURE_UNIT_OPTIONS,
  RAIN_UNIT_OPTIONS,
  BAROMETRIC_PRESSURE_UNIT_OPTIONS,
  WIND_SPEED_UNIT_OPTIONS,
} from '@awareness/util';
import Select from '@awareness-ui/components/Select';
import { ChannelMeasurement, SensorMeasurement } from '@awareness/types';
import { H1, H3, Text } from '../Typography';
import { Icon } from '../Icon';
import { useDispatch, useSelector } from 'react-redux';

interface ChannelProps extends ChannelMeasurement {
  onClick: () => void;
  timestamp: string;
  severity?: string;
}

const Layout = styled.div`
  display: flex;
  border: 1px solid ${theme.color.border.light};
  border-radius: ${toRem(8)};
  flex: 1;
  width: 100%;
  height: ${toRem(130)};
  flex-direction: column;
  justify-content: space-between;
  padding: ${toRem(12)};
  transition: background-color ${theme.transition.button.slow};
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: ${theme.color.background.light};
  }
`;

const ChannelHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Title = styled(H3)`
  font-size: 12px;
  margin-left: ${toRem(6)};
`;

const Value = styled(H1)`
  font-size: ${toRem(28)};
  margin-bottom: 0;
  display: flex;
  align-items: flex-end;
`;

const Ticker = styled(Text)`
  font-size: ${toRem(11)};
`;
const ChannelCard: React.FC<ChannelProps> = ({
  name,
  value,
  unit,
  onClick,
  timestamp,
  severity,
}) => {
  const [channelValue, setChannelValue] =
    useState<SensorMeasurement['value']>();
  const [channelUnit, setChannelUnit] = useState(unit);

  useEffect(() => {
    setChannelValue((prevValue: any) => value);
  }, [value]);

  useEffect(() => {
    if (name === CHANNEL_WIND) setChannelUnit('mph');
  }, []);

  const dispatch = useDispatch();

  const calculateDaysDifference = () => {
    const d1 = new Date(); //current date

    const d2 = new Date(timestamp);

    var dateDiff = {
      inDays: function () {
        var t2 = d2.getTime();
        var t1 = d1.getTime();

        var res = Math.floor((t1 - t2) / (24 * 3600 * 1000));

        return res;
      },
      inMin: function () {
        var t2 = d2.getTime();

        var t1 = d1.getTime();

        var days = Math.floor(t1 - t2) / (24 * 3600 * 1000);
        var min = Math.floor(days * 24 * 60);

        return min;
      },
      inHour: function () {
        var t2 = d2.getTime();

        var t1 = d1.getTime();

        var days = Math.floor(t1 - t2) / (24 * 3600 * 1000);
        var hour = Math.floor(days * 24);

        return hour;
      },
      // inWeeks: function () {
      //   var t2 = d2.getTime();
      //   var t1 = d1.getTime();

      //   return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
      // },

      inMonths: function () {
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        return d2M + 12 * d2Y - (d1M + 12 * d1Y);
      },

      inYears: function () {
        return d2.getFullYear() - d1.getFullYear();
      },
    };
    return dateDiff;
  };

  function getWindDirection(degrees: number): string {
    if (degrees <= 11.25 || degrees > 348.75) {
      return 'N';
    } else if (degrees > 11.25 && degrees <= 33.75) {
      return 'NNE';
    } else if (degrees > 33.75 && degrees <= 56.25) {
      return 'NE';
    } else if (degrees > 56.25 && degrees <= 78.75) {
      return 'ENE';
    } else if (degrees > 78.75 && degrees <= 101.25) {
      return 'E';
    } else if (degrees > 101.25 && degrees <= 123.75) {
      return 'ESE';
    } else if (degrees > 123.75 && degrees <= 146.25) {
      return 'SE';
    } else if (degrees > 146.25 && degrees <= 168.75) {
      return 'SSE';
    } else if (degrees > 168.75 && degrees <= 191.25) {
      return 'S';
    } else if (degrees > 191.25 && degrees <= 213.75) {
      return 'SSW';
    } else if (degrees > 213.75 && degrees <= 236.25) {
      return 'SW';
    } else if (degrees > 236.25 && degrees <= 258.75) {
      return 'WSW';
    } else if (degrees > 258.75 && degrees <= 281.25) {
      return 'W';
    } else if (degrees > 281.25 && degrees <= 303.75) {
      return 'WNW';
    } else if (degrees > 303.75 && degrees <= 326.25) {
      return 'NW';
    } else if (degrees > 326.25 && degrees <= 348.75) {
      return 'NNW';
    } else {
      return '';
    }
  }

  const timeDifference = calculateDaysDifference();

  const handleSelectChange = (value: string, name: string) => {
    //Air Temperature unit conversions
    if (name === CHANNEL_AIR_TEMPRATURE || name === CHANNEL_DEW_POINT) {
      const convertedAirTemperatureValue = airTemperatureValueConversion(
        Number(channelValue),
        channelUnit,
        value
      );
      setChannelValue(convertedAirTemperatureValue.toString());
    }

    // Barometric Pressure
    if (name === CHANNEL_BAROMETRIC_PRESSURE) {
      const convertedPressureValue: number = barometricPressureValueConversion(
        Number(channelValue),
        channelUnit,
        value
      );
      setChannelValue(convertedPressureValue.toString());
    }

    // Rain Diff && Delta Rain
    if (
      name === CHANNEL_RAIN_DIFF ||
      name === CHANNEL_DELTA_RAIN ||
      name === CHANNEL_TOTAL_RAIN
    ) {
      const convertedRainDiffValue: number = rainDiffValueConversion(
        Number(channelValue),
        channelUnit,
        value
      );
      setChannelValue(convertedRainDiffValue.toString());
    }

    // WInd Speed
    if (name === CHANNEL_WIND_SPEED) {
      const convertedRainDiffValue: number = windSpeedValueConversion(
        Number(channelValue),
        channelUnit,
        value
      );
      setChannelValue(convertedRainDiffValue.toString());
    }
    dispatch(updateChannelUnit({ unit: value, name }));
    setChannelUnit(value);
  };

  let unitOptions: { label: string; value: string }[] = [];
  if (name === CHANNEL_AIR_TEMPRATURE || name === CHANNEL_DEW_POINT)
    unitOptions = TEMPERATURE_UNIT_OPTIONS;
  else if (
    name === CHANNEL_RAIN_DIFF ||
    name === CHANNEL_DELTA_RAIN ||
    name === CHANNEL_TOTAL_RAIN
  )
    unitOptions = RAIN_UNIT_OPTIONS;
  else if (name === CHANNEL_BAROMETRIC_PRESSURE)
    unitOptions = BAROMETRIC_PRESSURE_UNIT_OPTIONS;
  else if (name === CHANNEL_WIND_SPEED) unitOptions = WIND_SPEED_UNIT_OPTIONS;

  const splittedChannelValue: any =
    typeof channelValue === 'number'
      ? channelValue
      : channelValue
      ? JSON.parse(channelValue)
      : [];

  // const getCustomStyles = (severity: any) => {
  //   if (!severity) return {};
  //   else {
  //     const border = `4px solid ${(theme.color.status as any)[status]}`;
  //     return {
  //       border: `4px solid ${(theme.color.status as any)[severity]}`,
  //     };
  //   }
  // };
  return (
    <Layout
      onClick={onClick}
      //  style={getCustomStyles(severity)}
    >
      <ChannelHeader>
        <Icon icon={MEASUREMENT_ICON_NAMES[name]} height={16} width={16} />
        <Title>{formatChannelName(name)}</Title>
        {unitOptions.length > 0 && (
          <Select
            onChange={(option) => handleSelectChange(option, name)}
            options={unitOptions}
          />
        )}
      </ChannelHeader>
      {typeof channelValue !== 'number' && splittedChannelValue.length > 1 ? (
        <Value>
          {Number(splittedChannelValue[0]).toFixed(2)}
          <Text style={{ marginLeft: 3, paddingBottom: unit === 'º' ? 7 : 0 }}>
            {channelUnit} &nbsp;
          </Text>
          {name === CHANNEL_WIND ? (
            <>
              <Text>
                {getWindDirection(Number(splittedChannelValue[1]))}&nbsp;&nbsp;
              </Text>
              <div
                style={{
                  transform: `rotate(${Number(splittedChannelValue[1])}deg)`,
                }}>
                <TbArrowDownTail height={16} width={16} />
              </div>
            </>
          ) : (
            ''
          )}
        </Value>
      ) : (
        <Value>
          {Number(channelValue).toFixed(2)}
          <Text style={{ marginLeft: 3, paddingBottom: unit === 'º' ? 7 : 0 }}>
            {channelUnit} &nbsp;
          </Text>
          {name.toLowerCase() === 'wind direction' ? (
            <>
              <Text>{getWindDirection(Number(channelValue))}&nbsp;&nbsp;</Text>
              <div style={{ transform: `rotate(${Number(channelValue)}deg)` }}>
                <TbArrowDownTail height={16} width={16} />
              </div>
            </>
          ) : (
            ''
          )}
        </Value>
      )}
      {timeDifference.inDays() > 0 ? (
        <Ticker>{`Last ${timeDifference.inDays()} Days.`}</Ticker>
      ) : timeDifference.inHour() > 0 ? (
        <Ticker>{`Last ${timeDifference.inHour()} Hours`}</Ticker>
      ) : (
        <Ticker>{`Last ${timeDifference.inMin()} Minutes`}</Ticker>
      )}
    </Layout>
  );
};

export default ChannelCard;
