import { capitalize } from '@awareness/util';
import { createSelector } from 'reselect';
import { createSlice } from 'robodux';

import { ReduxState, User } from '../types';

const initialState: User = {
  email: '',
  name: '',
  cell_number: '',
  id: -1,
  client: null,
};

const { actions, reducer, name } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, payload: User) => payload,
    updateUser: (state, payload: Partial<User>) => ({ ...state, ...payload }),
  },
});
export const { setUser, updateUser } = actions;

export const getUser = (state: ReduxState) => state.user;

export const getSelectedClientId = createSelector(getUser, (u) => u.client?.id);

export const getSelectedClientName = createSelector(
  getUser,
  (u) => u.client?.name || ''
);

export const getSelectedClientLogo = createSelector(
  getUser,
  (u) => u.client?.logo
);

export const getSelectedClientCustomIdLabel = createSelector(
  getUser,
  (u) => u.client?.custom_id_label
);

export const getSelectedClientUsesMetric = createSelector(
  getUser,
  (u) => u.client?.metric_units
);

export const getSelectedClientConductorTypes = createSelector(getUser, (u) => [
  '',
  ...(u.client?.conductor_types || []),
]);

export const getUserName = createSelector(getUser, (u) => u.name);

export const getUserRoles = createSelector(getUser, (u) => u.roles);

export const formatUserRole = (roles: undefined | string[]) => {
  if (!roles || !roles[0]) {
    return '';
  }
  if (roles.includes('super')) {
    return 'Super Admin';
  }
  return capitalize(roles[0]);
};

export function getIsSuperAdmin(roles: string[] = []): boolean {
  return roles.includes('super');
}

export function getIsAdmin(roles: undefined | string[]) {
  return (roles && roles.includes('admin')) || getIsSuperAdmin(roles);
}

export function getIsAdminOnly(roles: undefined | string[]) {
  return roles && roles.includes('admin') && !roles.includes('super');
}

const reducers = { [name]: reducer };

export { reducers };
