import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import EXIF from 'exif-js';

import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { AssetImage } from '@awareness/types';
import {
  addAssetImage,
  deleteAssetImage,
  patchAssetImage,
} from '@awareness/assets';
import { apiFetchAsync } from '@awareness/api-fetch';
import { FormError, Label } from '../Typography';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { FieldWrapper, Input } from '../Inputs';

interface Props {
  id: number;
  onClose: () => void;
  editingImage?: AssetImage;
}

const Container = styled.div`
  position: relative;
`;

const Layout = styled.div`
  display: flex;
  min-height: 200px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.color.background.light};
  border-radius: ${toRem(10)};
  border: 1px dashed ${theme.color.primary[100]};
  padding-top: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Label)`
  color: ${theme.color.primary[100]};
  margin: ${toRem(8)} 0 ${toRem(16)};
`;

const CloseContainer = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: ${toRem(8)};
  cursor: pointer;
`;

const RotateContainer = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${theme.color.primary[100]};
`;

const Form = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
`;

const ONE_FILE_MESSAGE = 'Only one file can be uploaded at a time';

const PhotoUpload: React.FC<Props> = ({ id, onClose, editingImage }) => {
  const canvasRef: any = useRef<HTMLCanvasElement>();
  const imageRef: any = useRef<HTMLImageElement>();

  const [orientation, setOrientation] = useState(1);

  useEffect(() => {
    if (editingImage) {
      getFileFromUrl(editingImage.url).then((f) => loadFiles([f]));
      setDescription(editingImage.description || '');
    }
  }, [editingImage]);

  async function getFileFromUrl(url: string) {
    const blob = await (
      await fetch(url, { headers: { 'Cache-Control': 'no-cache' } })
    ).blob();
    return new File([blob], 'edit.jpg');
  }

  const loadFiles = useCallback((files: File[]) => {
    setErrorMessage('');

    if (files.length > 1) {
      toast.error(ONE_FILE_MESSAGE);
    } else {
      const newFile = files[0];
      setFile(newFile);

      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = (ev) => {
        if (ev.target) {
          const buf = ev.target.result as ArrayBuffer;
          if (!editingImage) {
            const exif = EXIF.readFromBinaryFile(buf);
            const orientation = exif.Orientation || 1;
            setOrientation(orientation);
          }
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');
          const thisImage = imageRef.current;
          thisImage.onload = async () => {
            if (!context) {
              return;
            }
            canvas.width = thisImage.width;
            canvas.height = thisImage.height;
            context.save();
            const width = canvas.width;
            const styleWidth = canvas.style.width;
            const height = canvas.height;
            const styleHeight = canvas.style.height;

            if (orientation) {
              if (orientation > 4) {
                canvas.width = height;
                canvas.style.width = styleHeight;
                canvas.height = width;
                canvas.style.height = styleWidth;
              }
              switch (orientation) {
                case 2:
                  // horizontal flip
                  context.translate(canvas.width, 0);
                  context.scale(-1, 1);
                  break;
                case 3:
                  // 180° rotate left
                  context.translate(canvas.width, canvas.height);
                  context.rotate(Math.PI);
                  break;
                case 4:
                  // vertical flip
                  context.translate(0, canvas.height);
                  context.scale(1, -1);
                  break;
                case 5:
                  // vertical flip + 90 rotate right
                  context.rotate(0.5 * Math.PI);
                  context.scale(1, -1);
                  break;
                case 6:
                  // 90° rotate right
                  context.rotate(0.5 * Math.PI);
                  context.translate(0, -canvas.height);
                  break;
                case 7:
                  // horizontal flip + 90 rotate right
                  context.rotate(0.5 * Math.PI);
                  context.translate(canvas.width, -canvas.height);
                  context.scale(-1, 1);
                  break;
                case 8:
                  // 90° rotate left
                  context.rotate(-0.5 * Math.PI);
                  context.translate(-canvas.width, 0);
                  break;
              }
            }

            context.drawImage(thisImage, 0, 0);
            context.restore();
            const dataURL = canvas.toDataURL();
            const blob = await (await fetch(dataURL)).blob();
            const rotatedFile = new File([blob], 'file.jpg');
            setFile(rotatedFile);
          };
          setImgSrc(URL.createObjectURL(newFile));
        }
      };
      reader.readAsArrayBuffer(newFile);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop: loadFiles,
    multiple: false,
    accept: 'image/*, image/heif, image/heic',
  });

  const [loading, setLoading] = useState(false);
  const [rotated, setRotated] = useState(false);
  const [file, setFile] = useState<File>();
  const [imgSrc, setImgSrc] = useState<string>('');
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const dispatch = useDispatch();

  async function submit(event: React.MouseEvent) {
    event.preventDefault();
    if (editingImage && rotated) {
      const success = await submitUpload();
      if (success) {
        deleteOldImage(editingImage.id);
      }
      onClose();
    } else if (editingImage) {
      updatePhoto();
      onClose();
    } else {
      submitUpload();
    }
  }

  async function submitUpload() {
    if (!file) {
      return;
    }

    setLoading(true);

    const url = `${apiEndpoint}/assets/${id}/images`;
    const body = new FormData();
    body.append('image', file);
    body.append('description', description);
    const method = 'POST';

    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: AssetImage = await response.json();

      if (!json.url) {
        throw new Error(
          (json as any).message || 'Upload failed. Please try again.'
        );
      }

      dispatch(addAssetImage({ id, image: json }));
      toast.success('Image uploaded!');
    } catch (err) {
      setErrorMessage(`${err}`);
      return false;
    }

    setLoading(false);
    setFile(undefined);
    return true;
  }

  async function deleteOldImage(imageId: number) {
    const url = `${apiEndpoint}/assets/${id}/images/${imageId}`;
    const method = 'DELETE';
    try {
      const response = await apiFetchAsync({ url, token, method });

      if (response.status !== 204) {
        const json = await response.json();
        throw new Error(
          (json || {}).message || 'Delete failed. Please try again.'
        );
      }

      dispatch(deleteAssetImage({ id, imageId }));
    } catch (err) {
      toast.error(`${err}`);
    }
  }

  const openFileBrowser = () => inputRef.current?.click();

  const close = () => {
    setFile(undefined);
    onClose();
  };

  const onRotate = async () => {
    setRotated(true);
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const { width, height } = canvas;
    canvas.width = height;
    canvas.height = width;

    if (orientation === 1) {
      setOrientation(6);
      // 90° rotate right
      context.rotate(0.5 * Math.PI);
      context.translate(0, -height);
    } else if (orientation === 6) {
      setOrientation(3);
      // 180° rotate left
      context.translate(height, width);
      context.rotate(Math.PI);
    } else if (orientation === 3) {
      setOrientation(8);
      // 90° rotate left
      context.rotate(-0.5 * Math.PI);
      context.translate(-width, 0);
    } else {
      setOrientation(1);
      context.rotate(0);
      context.translate(0, 0);
    }

    context.drawImage(imageRef.current, 0, 0);
    context.restore();
    const dataURL = canvas.toDataURL();
    const blob = await (await fetch(dataURL)).blob();
    const rotatedFile = new File([blob], 'file.jpg');
    setFile(rotatedFile);
  };

  async function updatePhoto() {
    if (!editingImage) {
      return;
    }

    setLoading(true);
    const url = `${apiEndpoint}/assets/${id}/images/${editingImage.id}`;
    const body = new FormData();
    body.append('description', description);
    const method = 'PUT';
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: AssetImage = await response.json();

      if (!json.url) {
        throw new Error(
          (json as any).message || 'Edit failed. Please try again.'
        );
      }

      dispatch(patchAssetImage({ id, image: json }));
      toast.success('Image updated!');
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
    setFile(undefined);
  }

  return (
    <Container>
      <CloseContainer onClick={close}>
        <Icon
          icon="Close"
          width={16}
          height={16}
          fill={theme.color.primary[100]}
        />
      </CloseContainer>
      <Layout {...getRootProps()} onClick={undefined}>
        {loading ? (
          'Loading...'
        ) : file || editingImage ? (
          <>
            <canvas ref={canvasRef} style={{ maxHeight: 180 }} />
            <img
              ref={imageRef}
              src={imgSrc as any}
              style={{ display: 'none' }}
            />
            <RotateContainer onClick={onRotate}>
              rotate
              <Icon
                icon="Retry"
                width={18}
                height={18}
                fill={theme.color.primary[100]}
                style={{ paddingLeft: 3 }}
              />
            </RotateContainer>
            <Form onSubmit={submit as any}>
              <Fieldset>
                <FieldWrapper label="Description">
                  <Input
                    defaultValue={description}
                    type="text"
                    onChange={(e) => setDescription(e.currentTarget.value)}
                  />
                </FieldWrapper>
              </Fieldset>
              <Button
                onClick={submit}
                label={editingImage ? 'Save' : 'Upload'}
              />
            </Form>
          </>
        ) : isDragActive ? (
          <Content>
            <Icon
              icon="Image"
              height={38}
              width={44}
              fill={theme.color.primary[100]}
            />
            <Title>Drag image here</Title>
          </Content>
        ) : (
          <Content>
            <Icon
              icon="Image"
              height={38}
              width={44}
              fill={theme.color.primary[100]}
            />
            <Title>Drag image here or</Title>
            <input {...getInputProps()} />
            <Button
              label="Choose file"
              size="small"
              onClick={openFileBrowser}
            />
          </Content>
        )}
        {errorMessage && <FormError>{errorMessage}</FormError>}
      </Layout>
    </Container>
  );
};

export default PhotoUpload;
