import React, { useState } from 'react';

import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';
import Input from './Input';

const Layout = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: ${toRem(theme.input.height)};
`;

const TogglePassword = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${toRem(38.5)};
  height: 100%;
  cursor: pointer;

  svg {
    fill: ${theme.color.text[44]};
    transition: fill ${theme.transition.icon};
  }

  &:hover {
    svg {
      fill: ${theme.color.text[88]};
    }
  }
`;

const PasswordInput = React.forwardRef((props, ref) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <Layout>
      <TogglePassword
        aria-label="Toggle password visibility"
        onClick={() => setPasswordVisible(!passwordVisible)}>
        <Icon icon="Eye" width={16.5} height={16.5} aria-hidden="true" />
      </TogglePassword>

      <Input
        ref={ref as any}
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="off"
        placeholder="Password"
        {...props}
      />
    </Layout>
  );
});

export default PasswordInput as React.FC<React.HTMLProps<HTMLInputElement>>;
