import React, { useCallback, useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { Group } from '@awareness/types';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { useWindowSize } from '@awareness-ui/hooks';
import {
  GroupListItem,
  GroupMobileListItem,
  H1,
  MobileList,
  Pagination,
  SortLabel,
  TablePageLeft,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableHeaderRow,
  TableLayout,
  TableContainer,
  TableMainView,
  TablePageHeader,
  Button,
} from '@awareness-ui/components';
import { theme } from '@awareness-ui/design';

interface Props extends RouteChildrenProps {}

export const Groups: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const [groups, setGroups] = useState<Group[]>([]);

  const count = groups.length;
  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;

  useEffect(() => {
    fetchGroups();
  }, [pageSize, pageNumber]);

  const fetchGroups = async () => {
    setLoading(true);

    const url = `${apiEndpoint}/client/assets/groups`;

    try {
      const response = await apiFetchAsync({ url, token });
      const json: Group[] = await response.json();

      if (response.status !== 200 || !Array.isArray(json)) {
        throw new Error('Uh-oh! There was an error loading groups.');
      }

      setGroups(json);
    } catch (err) {
      toast.error(`${err}`, {
        actionLabel: 'Try Again',
        onAction: fetchGroups,
      } as any);
    }

    setLoading(false);
  };

  const pageCount =
    (groups.length >= pageSize ? pageNumber + 1 : pageNumber) + 1;

  const noGroupsMessage = loading ? 'Loading...' : 'No Groups';

  const goToGroup = useCallback(
    (id: number) => history.push(`/group/${id}`),
    []
  );

  return (
    <>
      <TableMainView>
        <TableContainer style={isMobile ? undefined : { padding: 16 }}>
          {isMobile ? null : (
            <TablePageHeader>
              <TablePageLeft>
                <H1>
                  Groups{' '}
                  <span style={{ fontWeight: 'normal', fontSize: 22 }}>
                    ({count})
                  </span>
                </H1>
              </TablePageLeft>
              <Button
                label="Create Group"
                to="/group/new"
                style={{ margin: '10px 0', marginLeft: 'auto' }}
              />
            </TablePageHeader>
          )}
          <>
            {groups.length === 0 ? (
              <div style={{ marginTop: 20 }}>{noGroupsMessage}</div>
            ) : isMobile ? (
              <MobileList disabled={loading}>
                {groups.map((g) => {
                  return (
                    <GroupMobileListItem
                      key={g.id}
                      {...g}
                      onClick={() => goToGroup(g.id)}
                    />
                  );
                })}
              </MobileList>
            ) : (
              <TableLayout disabled={loading}>
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <SortLabel label="Name" />
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {groups.map((g) => (
                    <GroupListItem
                      key={g.id}
                      {...g}
                      onClick={() => goToGroup(g.id)}
                    />
                  ))}
                </TableBody>
              </TableLayout>
            )}
            <Pagination
              pageCount={pageCount}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </>
        </TableContainer>
      </TableMainView>
    </>
  );
};
