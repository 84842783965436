import { takeEvery, call, put, select, all } from 'redux-saga/effects';
import { ActionWithPayload, createAction } from 'robodux';
import toast from 'react-hot-toast';

import {
  APIResponse,
  Asset,
  AssetImage,
  ChannelList,
  ChannelMeasurement,
  APIThreshold,
  User,
  Threshold,
} from '@awareness/types';
import { apiFetch, DEFAULT_ERROR_MESSAGE } from '@awareness/api-fetch';
import { getIsSuperAdmin, getUser } from '@awareness/user';
import { addAssets, getAssetById, patchAssets } from '../slice';
import {
  transformAssets,
  formatUnitString,
  metricToImperialMeasurement,
  getMeasurementUnits,
  metricToImperial,
} from '../utils';

function* onFetchAssetById({
  payload: { id, onError, onSuccess },
}: ActionWithPayload<FetchAssetByIdPayload>): any {
  let asset = yield select(getAssetById, id);
  const user: User = yield select(getUser);
  const superUrl = getIsSuperAdmin(user.roles) ? '/admin' : '';

  if (!asset) {
    asset = yield call(apiFetch, { endpoint: `${superUrl}/assets/${id}` });

    if (!asset || asset.error) {
      yield call(onError || toast.error, DEFAULT_ERROR_MESSAGE);
      return;
    }

    yield put(addAssets(transformAssets([asset])));
  }

  const [channelsResponse, imagesResponse, thresholdsResponse]: [
    APIResponse<ChannelList>,
    APIResponse<AssetImage[]>,
    APIResponse<APIThreshold[] | undefined>
  ] = yield all([
    call(apiFetch, {
      endpoint: `/assets/${id}/channels`,
      showErrorToast: false,
    }),
    call(apiFetch, {
      endpoint: `/assets/${id}/images`,
      showErrorToast: false,
    }),
    call(apiFetch, {
      endpoint: `/assets/${id}/thresholds`,
      showErrorToast: false,
    }),
  ]);

  if (!Array.isArray(channelsResponse)) {
    yield call(toast.error, DEFAULT_ERROR_MESSAGE);
  }

  const channels = Array.isArray(channelsResponse)
    ? channelsResponse.sort((a, b) => (a.name || '').localeCompare(b.name))
    : ([] as ChannelList);

  const images = Array.isArray(imagesResponse) ? imagesResponse : [];

  const measurementResponses = yield all(
    channels.map(({ name }) =>
      call(apiFetch, {
        endpoint: `/assets/${id}/channels/${name}/latest`,
        showErrorToast: false,
      })
    )
  );

  const useMetric = !!user.client?.metric_units;
  const measurements: {
    [key: string]: ChannelMeasurement;
  } = (channels as any[]).reduce((acc, { name, unit }, index) => {
    const { value, timestamp } = measurementResponses[index];
    const splittedValue = value.split(',');
    const measurement = {
      name,
      unit: formatUnitString(unit),
      value: splittedValue.length > 1 ? splittedValue : value,
      timestamp,
    };

    return {
      ...acc,
      [name]: useMetric
        ? measurement
        : metricToImperialMeasurement(measurement),
    };
  }, {});

  const measurementUnits = getMeasurementUnits(measurements);
  const thresholds: Threshold[] = (
    Array.isArray(thresholdsResponse) ? thresholdsResponse : []
  ).map((t: APIThreshold) => {
    const unit = measurementUnits[t.channel_name] || '';
    const threshold = useMetric
      ? t.threshold
      : metricToImperial(t.threshold, unit, true).value;
    return { ...t, threshold, unit };
  });

  yield put(patchAssets({ [id]: { measurements, images, thresholds } }));

  if (onSuccess) {
    yield call(onSuccess, asset);
  }
}

export interface FetchAssetByIdPayload {
  id: number;
  onError?: (e: string) => void;
  onSuccess?: (asset: Asset) => void;
}

export const fetchAssetById =
  createAction<FetchAssetByIdPayload>('FETCH_ASSET_BY_ID');
export function* fetchAssetByIdSaga() {
  yield takeEvery(`${fetchAssetById}`, onFetchAssetById);
}
