import createSlice, { createTable } from 'robodux';
import { createSelector } from 'reselect';
import { Alert, ReduxState } from '@awareness/types';

const alertsSlice = createTable<Alert>({
  name: 'alerts',
  initialState: {},
});

export const {
  set: setAlerts,
  add: addAlerts,
  remove: removeAlerts,
  patch: patchAlerts,
  reset: resetAlerts,
} = alertsSlice.actions;

export const getAlerts = (state: ReduxState) => state.alerts;

export const getAlertsCount = createSelector(
  getAlerts,
  (alerts) => Object.keys(alerts).length
);

export const getFirstFiveAlerts = createSelector(getAlerts, (alerts) =>
  Object.values(alerts)
    .sort((a, b) => {
      const aTime = new Date(a.timestamp);
      const bTime = new Date(b.timestamp);
      return bTime.getTime() - aTime.getTime();
    })
    .slice(0, 5)
);

export const getAlertById = createSelector(
  getAlerts,
  (_: ReduxState, id: number | undefined) => id,
  (alerts, id): Alert | undefined => (id !== undefined ? alerts[id] : undefined)
);

const eventsListenerEnabledSlice = createSlice({
  name: 'eventsListenerEnabled',
  initialState: false,
  reducers: {
    setEventsListenerEnabled: (_, payload: boolean) => payload,
  },
});

export const { setEventsListenerEnabled } = eventsListenerEnabledSlice.actions;

export const getEventsListenerEnabled = (state: ReduxState) =>
  state.eventsListenerEnabled;

const reducers = {
  [alertsSlice.name]: alertsSlice.reducer,
  [eventsListenerEnabledSlice.name]: eventsListenerEnabledSlice.reducer,
};

export { reducers };
