import React from 'react';
import styled, { css } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';

interface ActionButtonProps {
  onClick?: () => void;
  isActive?: boolean;
}

const IconButton = styled.div<ActionButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${toRem(24)};
  height: ${toRem(24)};
  border-radius: ${toRem(8)};
  border: ${toRem(1)} solid ${theme.color.primary[100]};
  transition: transform 300ms;
  cursor: pointer;

  &:hover {
    background: ${theme.color.primary};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(45deg);
    `}
`;

const ActionButton: React.FC<ActionButtonProps> = ({ onClick, isActive }) => {
  return (
    <IconButton isActive={isActive} onClick={onClick}>
      <Icon icon="Add" width={16} height={16} fill={theme.color.primary[100]} />
    </IconButton>
  );
};

export default ActionButton;
