import { takeEvery, take, select, call, put } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import { createAction, ActionWithPayload } from 'robodux';
import { batchActions } from 'redux-batched-actions';

import { getIsAuthenticated, setToken } from '@awareness/auth';
import { apiFetch } from '@awareness/api-fetch';
import { logout } from '@awareness/logout';
import { APIResponse, User } from '@awareness/types';
import { setUser } from '@awareness/user';
import { checkApiEndpoint } from '@awareness/api-endpoint';
import { eventsListener, getEventsListenerEnabled } from '@awareness/alerts';
// import { transformAlerts } from '@awareness/alerts/util';

function* onBootup({ payload, type }: ActionWithPayload<string | undefined>) {
  if (type === `${bootup}`) {
    const rehydrated: boolean = yield select(getIsRehydrated);
    if (!rehydrated) {
      yield take(REHYDRATE);
    }
    yield call(checkApiEndpoint);
  }

  const authenticated: boolean = yield select(getIsAuthenticated);
  if (!authenticated && type !== `${setToken}`) {
    return;
  }

  const userResponse: APIResponse<User> = yield call(apiFetch, {
    endpoint: '/me',
    withStatus: true,
  });

  if (!userResponse || !userResponse.roles) {
    if (userResponse?.status === 401) {
      yield put(logout());
    }
    return;
  }

  let actions: any = [setUser(userResponse)];

  // const alertsResponse: APIResponse<Alert[]> = yield call(apiFetch, {
  //   endpoint: '/alarms',
  // });

  // if (alertsResponse?.length) {
  //   actions.push(setAlerts(transformAlerts(alertsResponse)));
  // }

  yield put(batchActions(actions));

  const eventsListenerEnabled: boolean = yield select(getEventsListenerEnabled);
  if (eventsListenerEnabled) {
    yield call(eventsListener);
  }
}

export const getIsRehydrated = (state: any): boolean =>
  state._persist.rehydrated;

export const bootup = createAction('BOOTUP');
export function* bootupSaga() {
  yield takeEvery([`${bootup}`, `${setToken}`], onBootup);
}
