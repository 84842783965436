import React, { SelectHTMLAttributes } from 'react';

import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';
import Arrow from './image/arrow.svg';

interface ResultsPerPageProps extends SelectHTMLAttributes<any> {
  onChangeSize: (pageSize: number) => void;
  options?: number[];
  label?: string;
}

const Select = styled.select`
  appearance: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: ${toRem(27.5)};
  margin-right: ${toRem(20)};
  padding: 0 ${toRem(24.75)} 0 ${toRem(11)};
  border-radius: ${toRem(5.5)};
  border: ${toRem(1)} solid transparent;
  background-color: transparent;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: 95% 50%;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.25)};
  line-height: 120%;
  color: ${theme.color.text[88]};
  cursor: pointer;

  @media (min-width: ${theme.breakpoint.med}px) {
    margin-right: ${toRem(44)};
  }

  &:hover {
    border-color: ${theme.color.border.medium};
  }

  &:focus {
    border-color: ${theme.color.border.medium};
    outline: none;
  }
`;

const ResultsPerPage: React.FC<ResultsPerPageProps> = ({
  onChangeSize,
  options = [10, 25, 50, 80, 100],
  label = 'Per Page',
  ...props
}) => {
  return (
    <Select
      {...props}
      onChange={(event) =>
        onChangeSize && onChangeSize(parseInt(event.target.value))
      }>
      {' '}
      {options.map((option) => (
        <option key={option} value={option}>
          {option} {label}
        </option>
      ))}
    </Select>
  );
};

export default ResultsPerPage;
