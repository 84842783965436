import React from 'react';

import { theme } from '@awareness-ui/design';
import { Account } from '@awareness/types';
import { useWindowSize } from '@awareness-ui/hooks';
import {
  AccountListItem,
  AccountMobileListItem,
  MobileList,
  SortLabel,
  TableBody,
  TableContainer,
  TableHead,
  TableHeaderCell,
  TableHeaderRow,
  TableLayout,
} from '../Table';
import {
  getIsAdmin,
  getIsAdminOnly,
  getIsSuperAdmin,
  getUser,
} from '@awareness/user';
import { useSelector } from 'react-redux';

interface Props {
  accounts: Account[];
  setSelectedAccount: (a: Account) => void;
}

export const Accounts: React.FC<Props> = ({ accounts, setSelectedAccount }) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;

  const user = useSelector(getUser);
  const isSuper = getIsSuperAdmin(user.roles);
  const isAdminOnly = getIsAdminOnly(user.roles);

  const onAccountListItemClick = (account: Account) => {
    if (isSuper) setSelectedAccount(account);
    else if (isAdminOnly) {
      !getIsSuperAdmin(account.roles) && setSelectedAccount(account);
    } else {
      return;
    }
  };
  return (
    <TableContainer style={{ overflow: 'hidden' }}>
      {accounts.length ? (
        isMobile ? (
          <MobileList>
            {accounts.map((a) => {
              return (
                <AccountMobileListItem
                  key={a.id}
                  onClick={
                    getIsAdmin(a.roles) && !getIsAdmin(a.roles)
                      ? () => setSelectedAccount(a)
                      : undefined
                  }
                  {...a}
                />
              );
            })}
          </MobileList>
        ) : (
          <TableLayout>
            <TableHead>
              <TableHeaderRow>
                <TableHeaderCell>
                  <SortLabel label="Name" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <SortLabel label="Email" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <SortLabel label="Role" />
                </TableHeaderCell>
                <TableHeaderCell align="center">
                  <SortLabel label="Activity" />
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHead>
            <TableBody>
              {accounts.map((a) => (
                <AccountListItem
                  showActivity
                  key={a.id}
                  onClick={() => onAccountListItemClick(a)}
                  {...a}
                />
              ))}
            </TableBody>
          </TableLayout>
        )
      ) : (
        'No Accounts'
      )}
    </TableContainer>
  );
};
