import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';
import { STATUS_ICON_NAMES, STATUS_LABELS } from '@awareness/util';

import { Icon } from '../Icon';
import { H3, Text } from '../Typography';
import { Checkbox } from '../Inputs';
import FilterGroup from './FilterGroup';
import { AssetStatus } from '@awareness/types';

const Layout = styled.div<{ isOpen?: boolean; positionAbsolute: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${toRem(50)};
  width: ${toRem(100)};
  transition: all linear 200ms;
  border-radius: ${toRem(8)};
  background: white;
  box-shadow: ${theme.shadow.mapcontrols};
  margin-top: ${toRem(10)};
  margin-left: ${toRem(10)};

  ${({ positionAbsolute }) =>
    positionAbsolute &&
    css`
      position: absolute;
      z-index: 2;
      overflow: hidden;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      height: fit-content;
      width: ${toRem(240)};
      min-width: ${toRem(180)};
      overflow: scroll;
      padding: ${toRem(16)};
      padding-top: 0;
    `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Toggle = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${toRem(16)};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      padding-left: 0;
    `}
`;

const ClearButton = styled(Text)`
  color: ${theme.color.primary[100]};
  font-size: ${toRem(12)};
  text-decoration: none;
  font-weight: ${theme.font.weight.semiBold};
  cursor: pointer;

  &:active,
  &:visited {
    color: ${theme.color.primary[100]};
  }

  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  toggleFilter: (f: AssetStatus) => void;
  clearFilters: () => void;
  filters: AssetStatus[];
  positionAbsolute: boolean;
  useSuperEndpoint: boolean;
  setUseSuperEndpoint: false | ((v: boolean) => void);
}

const Filters: React.FC<Props> = ({
  toggleFilter,
  clearFilters,
  positionAbsolute,
  filters,
  useSuperEndpoint,
  setUseSuperEndpoint,
}) => {
  const [isOpen, setOpen] = useState(false);

  const FilterCheckbox = ({ id }: { id: AssetStatus }) => {
    return (
      <Checkbox
        name={id}
        label={STATUS_LABELS[id]}
        icon={STATUS_ICON_NAMES[id]}
        onChange={() => toggleFilter(id)}
        checked={filters.includes(id)}
      />
    );
  };

  return (
    <Layout isOpen={isOpen} positionAbsolute={positionAbsolute}>
      <Header>
        <Toggle onClick={() => setOpen(!isOpen)} isOpen={isOpen}>
          <Icon icon="Filter" width={16} height={16} />
          <H3 style={{ paddingLeft: 6 }}>Filters</H3>
        </Toggle>
        {isOpen && (
          <ClearButton
            style={isOpen ? undefined : { display: 'none' }}
            onClick={filters.length === 0 ? undefined : clearFilters}>
            clear
          </ClearButton>
        )}
      </Header>
      {isOpen && (
        <>
          {setUseSuperEndpoint && (
            <FilterGroup title="Super Admin">
              <Checkbox
                name="super"
                label="View All Assets"
                icon="AssetPole"
                onChange={(ev) => setUseSuperEndpoint(ev.target.checked)}
                checked={useSuperEndpoint}
              />
            </FilterGroup>
          )}
          <FilterGroup title="Status">
            {['healthy', 'predictive', 'potential', 'critical'].map((id) => (
              <FilterCheckbox id={id as AssetStatus} key={id} />
            ))}
          </FilterGroup>
          <FilterGroup title="Conditions">
            {[
              'tilting',
              'smoke',
              'gas',
              'vegetation',
              'pressure',
              'arcing',
              'temperature',
            ].map((id) => (
              <FilterCheckbox id={id as AssetStatus} key={id} />
            ))}
          </FilterGroup>
          <FilterGroup title="Connection">
            {['online', 'offline'].map((id) => (
              <FilterCheckbox id={id as AssetStatus} key={id} />
            ))}
          </FilterGroup>
          <FilterGroup title="Power">
            {['charging', 'discharging'].map((id) => (
              <FilterCheckbox id={id as AssetStatus} key={id} />
            ))}
          </FilterGroup>
        </>
      )}
    </Layout>
  );
};

export default Filters;
