import {
  CHANNEL_AIR_TEMPRATURE,
  CHANNEL_DEW_POINT,
  CHANNEL_RAIN_DIFF,
  CHANNEL_DELTA_RAIN,
  CHANNEL_TOTAL_RAIN,
  CHANNEL_BAROMETRIC_PRESSURE,
  CHANNEL_WIND_SPEED,
  CHANNEL_WIND,
} from '@awareness/util';

export interface ChannelUnit {
  [CHANNEL_AIR_TEMPRATURE]: '°F' | '°C' | 'K';
  [CHANNEL_DEW_POINT]: '°F' | '°C' | 'K';
  [CHANNEL_RAIN_DIFF]: 'in' | 'ml';
  [CHANNEL_DELTA_RAIN]: 'in' | 'ml';
  [CHANNEL_TOTAL_RAIN]: 'in' | 'ml';
  [CHANNEL_BAROMETRIC_PRESSURE]: 'hPa' | 'psi' | 'mbar' | 'inHg';
  [CHANNEL_WIND_SPEED]: 'mps' | 'fps' | 'mph';
  [CHANNEL_WIND]: 'mps' | 'fps' | 'mph';
}

export interface UnitT {
  unit: string;
  name: string;
}

export type ChannelState = { [key: string]: ChannelUnit };
