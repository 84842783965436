import { call, select } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { APIResponse } from '@awareness/types';

export const DEFAULT_ERROR_MESSAGE = 'Uh-oh! An unexpected error occurred.';
const DEFAULT_METHOD = 'GET';

interface APIFetchParams {
  endpoint: string;
  method?: string;
  body?: any;
  clientId?: number;
  Authorization?: string;
  showErrorToast?: boolean;
  withStatus?: boolean;
}

export function* apiFetch({
  endpoint,
  method = DEFAULT_METHOD,
  body,
  clientId,
  Authorization,
  showErrorToast = true,
  withStatus = false,
}: APIFetchParams): any {
  const apiEndpoint = yield select(getApiEndpoint);
  const url = `${apiEndpoint}${endpoint}`;

  const headers: any = { Authorization };
  if (method !== DEFAULT_METHOD) {
    headers['Content-Type'] = 'application/json';
  }
  if (clientId !== undefined) {
    headers['x-client-override'] = clientId;
  }

  if (Authorization === undefined) {
    const token = yield select(getToken);
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response: Response = yield call(fetch, url, {
      headers,
      method,
      body: body || undefined,
    });
    const { status } = response;
    let json: APIResponse = yield new Promise((resolve) =>
      response
        .json()
        .then(resolve)
        .catch(() => (resolve as any)())
    );
    if (status < 200 || status >= 300) {
      if (showErrorToast) {
        const message = `${response.status} ${response.statusText}`;
        toast.error(`Uh-oh! Unexpected error ${message}`);
      }
    } else if (!json) {
      json = {};
    }
    if (withStatus && json) {
      json.status = status;
    }

    return json;
  } catch (err) {
    toast.error(
      'Network request failed. Please check your internet connection.'
    );
    return;
  }
}

export const getBody = JSON.stringify;
