import React, { useRef, useState, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';

import { AssetDetail } from '@awareness-ui/components';
import { theme } from '@awareness-ui/design';
import { MapView, MapViewConn } from './Map';

import { getRequiredPassword } from '@awareness/auth';
import { useSelector } from 'react-redux';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: ${theme.breakpoint.small}px) {
    flex-direction: row;
  }
`;

const DetailView = styled.div<{ isOpen: boolean }>`
  display: flex;
  width: 0;
  height: 0;
  flex-direction: column;
  background-color: ${theme.color.white};
  border-left: 1px solid ${theme.color.border.medium};
  transition: ${theme.transition.nav};
  position: relative;
  z-index: ${theme.zIndex.sideview};
  overflow: visible;
  margin-bottom: ${theme.header.height}px;

  @media (max-width: ${theme.breakpoint.small}px) {
    min-width: 100%;
    min-height: ${({ isOpen }) => (isOpen ? '65%' : 0)};
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    margin-top: -10px;
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoint.small}px) {
    min-width: ${({ isOpen }) => (isOpen ? theme.map.detailView.medium : 0)}px;
    height: auto;
  }

  @media (min-width: ${theme.breakpoint.large}px) {
    min-width: ${({ isOpen }) => (isOpen ? theme.map.detailView.large : 0)}px;
    height: auto;
  }
`;

interface Props extends RouteChildrenProps {}

export const Home: React.FC<Props> = ({ history, location }) => {
  const [selectedId, setSelectedId] = useState<null | number>(null);
  const requirePassword = useSelector(getRequiredPassword);
  useEffect(() => {
    const queryParamSelectedId = parseInt(
      (location.search.match(/(?<=a=)\d*/) || [])[0]
    );
    if (queryParamSelectedId >= 0) {
      setSelectedId(queryParamSelectedId);
    }
  }, []);

  useEffect(() => {
    if (requirePassword) {
      history.push('/me/change-password');
    }
  }, [requirePassword]);

  const mapRef = useRef<MapView>();

  const onSetSelectedId = (id: null | number) => {
    setSelectedId(id);
    const deselect = id === null;
    history.push({ search: deselect ? '' : `?a=${id}` });
    if (deselect && mapRef.current) {
      mapRef.current.clearSelectedPin();
    }
  };

  return (
    <MainView>
      <MapViewConn
        selectedId={selectedId}
        setSelectedId={onSetSelectedId}
        ref={mapRef as any}
      />
      <DetailView isOpen={selectedId !== null}>
        <AssetDetail
          id={selectedId as any}
          hasLink={true}
          setSelectedId={onSetSelectedId}
        />
      </DetailView>
    </MainView>
  );
};
