import styled, { css } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

interface PaginationButtonProps {
  isActive?: boolean;
  disabled?: boolean;
}

const PaginationButton = styled.button<PaginationButtonProps>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: ${toRem(27.5)};
  height: ${toRem(27.5)};
  margin-right: ${toRem(5.5)};
  border-radius: ${toRem(5.5)};
  background-color: transparent;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  color: ${theme.color.text[88]};
  transition: background-color ${theme.transition.button.fast};
  cursor: pointer;

  svg {
    fill: ${theme.color.text[44]};
  }

  &:hover {
    background-color: ${theme.color.selected};
    color: ${theme.color.text[100]};

    svg {
      fill: ${theme.color.text[100]};
    }
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.color.primary[100]};
      font-weight: ${theme.font.weight.semiBold};
      color: ${theme.color.white};

      &:hover {
        background-color: ${theme.color.primary[100]};
        color: ${theme.color.white};

        svg {
          fill: ${theme.color.white};
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: transparent;
      color: ${theme.color.text[33]};
      cursor: auto;

      svg {
        fill: ${theme.color.text[33]};
      }

      &:hover {
        background-color: transparent;
        color: ${theme.color.text[33]};

        svg {
          fill: ${theme.color.text[33]};
        }
      }
    `}
`;

export default PaginationButton;
