const spacing = {
  xxsmall: 5.5,
  xsmall: 11,
  small: 27,
  medium: 44,
  large: 55,
  xlarge: 66,
};

export default spacing;
