import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { theme } from '@awareness-ui/design';
import {
  Accounts,
  Button,
  H1,
  H3,
  Subtext,
  EditAccount,
  EditCompany,
  Column,
  Modal,
  ModalContent,
} from '@awareness-ui/components';
import { Account } from '@awareness/types';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getUser, getIsAdmin, getIsSuperAdmin } from '@awareness/user';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const Header = styled.div`
  width: 100%;
  height: 150px;
  background: ${theme.color.background.light};
  padding: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${theme.color.border.medium};
`;

const Content = styled.div`
  padding: 55px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props extends RouteChildrenProps {}

export const Users: React.FC<Props> = ({ location, history }) => {
  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);
  const user = useSelector(getUser);
  const isSuper = getIsSuperAdmin(user.roles);
  const isAdmin = getIsAdmin(user.roles);

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const [pageSize] = useState(100);
  const [editingCompany, setEditingCompany] = useState(false);

  const { client } = user;

  useEffect(() => {
    if (!isSuper && !isAdmin) {
      return;
    }

    if (client) {
      fetchAccounts(client.id);
    }
  }, []);

  const fetchAccounts = async (clientId: number) => {
    let url = '';
    if (isAdmin && !isSuper) {
      url = `${apiEndpoint}/client/accounts?client_id=${clientId}&page_size=${pageSize}`;
    } else {
      url = `${apiEndpoint}/admin/accounts?client_id=${clientId}&page_size=${pageSize}`;
    }

    try {
      const response = await apiFetchAsync({ url, token });
      const json: Account[] = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching accounts.');
      }

      setAccounts(json.filter((a) => a.id !== user.id));
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const onEditAccount = (a: Account) => {
    setSelectedAccount(a);
    setAccounts([...accounts.filter((i) => i.id !== a.id), a]);
    setSelectedAccount(undefined);
  };

  const onCreateAccount = (a: Account) => {
    setSelectedAccount(a);
    setAccounts([...accounts, a]);
    setSelectedAccount(undefined);
  };

  const onDeleteAccount = (accountId: number) => {
    setAccounts(accounts.filter((i) => i.id !== accountId));
    setSelectedAccount(undefined);
  };

  const goToClientsList = () => {
    history.push('/clients');
  };

  if (loading) {
    return (
      <MainView>
        <Header />
        <Content>Loading...</Content>
      </MainView>
    );
  }

  // TODO: add route like /users/:id instead of conditional rendering
  if (client && selectedAccount) {
    const isNewAccount = selectedAccount.id === undefined;
    const email = isNewAccount ? 'New Account' : selectedAccount.email;
    return (
      <MainView>
        <Header>
          <Subtext
            style={{
              marginBottom: 10,
            }}>{`Company > ${client?.name} > Account`}</Subtext>

          <H1>{email}</H1>
          <Button
            size="small"
            appearance="secondary"
            style={{ width: 80 }}
            label="< Back"
            onClick={() => setSelectedAccount(undefined)}
          />
        </Header>
        <EditAccount
          client_id={client.id}
          account_id={selectedAccount.id}
          onEditSuccess={isNewAccount ? onCreateAccount : onEditAccount}
          onDeleteSuccess={onDeleteAccount}
        />
      </MainView>
    );
  }

  if (!client && !isSuper && !isAdmin) {
    return (
      <MainView>
        <Header>
          <H1>No Client Found</H1>
        </Header>
      </MainView>
    );
  }

  const isNewClient = location.search.includes('new_client=true');
  const companyName = client?.name || '';
  const onCloseModal = isNewClient
    ? goToClientsList
    : () => setEditingCompany(false);
  return (
    <MainView>
      <Modal
        style={{ width: 'auto' }}
        maxWidth={900}
        isActive={editingCompany || isNewClient}
        onClose={onCloseModal}>
        <ModalContent>
          <EditCompany
            client={isNewClient ? undefined : client || undefined}
            user={user}
            isNewClient={isNewClient}
            onClose={onCloseModal}
          />
        </ModalContent>
      </Modal>
      <Header style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Column>
          <Subtext
            style={{
              marginBottom: 10,
            }}>
            Company
          </Subtext>
          <H1>{companyName}</H1>
          {isSuper && (
            <Button
              size="small"
              appearance="secondary"
              style={{ width: 165 }}
              label={isNewClient ? '< Back' : 'Switch Selected Client'}
              to="/clients"
            />
          )}
        </Column>
        <Button
          label="Edit"
          onClick={() => setEditingCompany(true)}
          size="small"
        />
      </Header>
      <Content>
        {!isNewClient && (
          <>
            <Row>
              <Row>
                <H3>
                  Accounts {isSuper || isAdmin ? `(${accounts.length})` : ''}
                </H3>
                {client?.id === (user.client || {}).id && (
                  <Button
                    style={{ marginLeft: 20 }}
                    to="/users/activity"
                    label="View User Activity"
                    size="small"
                  />
                )}
              </Row>
              <Button
                size="small"
                label="+ Add Account"
                onClick={() => setSelectedAccount({} as Account)}
              />
            </Row>
            {(isSuper || isAdmin) && (
              <Accounts
                accounts={accounts}
                setSelectedAccount={setSelectedAccount}
              />
            )}
          </>
        )}
      </Content>
    </MainView>
  );
};
