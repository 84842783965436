import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import {
  capitalize,
  ChannelName,
  CHANNEL_NAMES,
  MEASUREMENT_CHART_PROPS,
  STATUS_IDS,
} from '@awareness/util';
import { APIThreshold, Threshold } from '@awareness/types';
import { theme, toRem } from '@awareness-ui/design';

import { H2, Text } from '../Typography';
import { FieldWrapper, Input } from '../Inputs';
import { Button } from '../Button';
import toast from 'react-hot-toast';
import {
  addAssetThreshold,
  deleteAssetThreshold,
  metricToImperial,
  patchAssetThreshold,
} from '@awareness/assets';
import { getSelectedClientUsesMetric } from '@awareness/user';

interface Props {
  threshold?: Threshold;
  channelUnits?: { [key: string]: string };
  onClose: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.large}px;
  min-height: 50%;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteButton = styled(Text)`
  color: ${theme.color.error};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${toRem(12)};
  margin-bottom: 10px;
`;

const UNCHANGEABLE_CHANNELS = ['Offline'];

export const ThresholdView: React.FC<Props> = ({
  threshold = {} as Threshold,
  channelUnits = {},
  onClose,
}) => {
  const channelNames =
    (Object.keys(channelUnits) as ChannelName[]) || CHANNEL_NAMES;
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [channel_name, setChannelName] = useState<ChannelName>(
    threshold.channel_name || channelNames[0]
  );
  const [description, setDescription] = useState(threshold.description || '');
  const [type, setType] = useState(threshold.type || 'minimum');
  const [thresholdValue, setThresholdValue] = useState(
    `${threshold.threshold}` || '0'
  );
  const [severity, setSeverity] = useState(threshold.severity || 'critical');

  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);
  const useMetric = useSelector(getSelectedClientUsesMetric);

  const dispatch = useDispatch();

  const { id, asset_id, unit: threshold_unit } = threshold;
  const isNewThreshold = id === undefined;
  let { min, max } = MEASUREMENT_CHART_PROPS[channel_name] || {
    min: 0,
    max: 100000,
  };
  const unit = threshold_unit || channelUnits[channel_name];
  if (!useMetric && unit) {
    min = metricToImperial(min, unit, true).value;
    max = metricToImperial(max, unit, true).value;
  }
  const unchangeableChannel = UNCHANGEABLE_CHANNELS.includes(channel_name);

  const updateThreshold = async (event: React.MouseEvent) => {
    event.preventDefault();
    const thresholdNum = parseFloat(thresholdValue);
    if (!thresholdNum) {
      toast.error('Threshold number is required');
      return;
    }
    if (thresholdNum < min || thresholdNum > max) {
      toast.error('Invalid threshold number');
      return;
    }
    setLoading(true);

    let method = 'POST';
    let url = `${apiEndpoint}/assets/${asset_id}/thresholds`;
    if (!isNewThreshold) {
      url += `/${id}`;
      method = 'PUT';
    }

    const body = new FormData();
    body.append('channel_name', channel_name);
    body.append('description', description);
    body.append('type', type);
    body.append('threshold', `${thresholdValue}`);
    body.append('severity', severity);
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: APIThreshold = await response.json();

      if (response.status !== (isNewThreshold ? 201 : 200)) {
        throw new Error('Uh-oh! There was an error saving the threshold.');
      }

      const action = isNewThreshold ? addAssetThreshold : patchAssetThreshold;
      dispatch(action({ id: asset_id, threshold: { ...json, unit } }));
      toast.success('Threshold saved!');
    } catch (err) {
      toast.error(`${err}`);
    }

    onClose();
    setLoading(false);
  };

  const deleteThreshold = async () => {
    setLoading(true);

    const method = 'DELETE';
    const url = `${apiEndpoint}/assets/${asset_id}/thresholds/${id}`;
    try {
      const response = await apiFetchAsync({ url, token, method });
      if (response.status !== 204) {
        throw new Error('Uh-oh! There was an error deleting the threshold.');
      }

      dispatch(deleteAssetThreshold({ id: asset_id, thresholdId: id }));
      toast.success('Threshold deleted');
    } catch (err) {
      toast.error(`${err}`);
    }

    onClose();
    setLoading(false);
  };

  const onSelectChannelName = (e: any) => {
    const newName = e.target.value;
    setChannelName(newName);
    if (UNCHANGEABLE_CHANNELS.includes(newName)) {
      setThresholdValue('0');
    }
  };

  if (asset_id === undefined) {
    return <Text>'Uh-oh! An unexpected error occurred'</Text>;
  }

  return (
    <Container>
      <H2>{isNewThreshold ? 'Create' : 'Edit'} Threshold</H2>
      <Form onSubmit={updateThreshold as any}>
        <Fieldset disabled={loading}>
          <FieldWrapper label="Channel Name">
            <select
              disabled={!isNewThreshold}
              onChange={onSelectChannelName}
              value={channel_name}>
              {channelNames.map((n) => (
                <option value={n} key={n}>
                  {n}
                </option>
              ))}
            </select>
          </FieldWrapper>
          <FieldWrapper label="Severity">
            <select
              onChange={(e) => setSeverity(e.target.value as any)}
              value={severity}>
              {STATUS_IDS.map((s) => (
                <option value={s} key={s}>
                  {capitalize(s)}
                </option>
              ))}
            </select>
          </FieldWrapper>
          {!unchangeableChannel && [
            <FieldWrapper label="Type">
              <select
                onChange={(e) => setType(e.target.value as any)}
                value={type}>
                <option value="minimum">Minimum</option>
                <option value="maximum">Maximum</option>
              </select>
            </FieldWrapper>,
            <FieldWrapper
              label="Threshold"
              message={`Range: ${min} to ${max} ${unit}`}>
              <Input
                min={min}
                max={max}
                step={max < 10 ? 0.1 : 1}
                type="number"
                onChange={(e) => setThresholdValue(e.target.value)}
                value={thresholdValue}
              />
            </FieldWrapper>,
          ]}
          <FieldWrapper label="Description">
            <Input
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </FieldWrapper>
          {deleting ? (
            <Row>
              Are you sure?
              <Button
                label="Yes, delete"
                appearance="secondary"
                size="small"
                onClick={deleteThreshold}
                style={{ margin: '0 10px' }}
              />
              <Button
                label="Cancel"
                appearance="secondary"
                size="small"
                onClick={() => setDeleting(false)}
              />
            </Row>
          ) : (
            <>
              {!isNewThreshold && (
                <DeleteButton onClick={() => setDeleting(true)}>
                  Delete Threshold
                </DeleteButton>
              )}
              <Button
                disabled={loading}
                onClick={updateThreshold}
                label={isNewThreshold ? 'Create' : 'Update'}
              />
            </>
          )}
        </Fieldset>
      </Form>
    </Container>
  );
};
