import { IconTypes } from '@awareness-ui/components';
import {
  Asset,
  AssetState,
  AssetStatus,
  ChannelMeasurement,
  Classification,
} from '@awareness/types';
import { STATUS_IDS } from '@awareness/util';

export function transformAssets(assets: Asset[]): AssetState {
  return assets.reduce((acc, a) => ({ ...acc, [a.id]: a }), {});
}

export function getFiltersQueryParams(
  filters: AssetStatus[],
  pageSize?: number,
  pageNumber?: number,
  map?: boolean
): string {
  let statusQuery = '';
  const statuses = filters.filter((f) => STATUS_IDS.includes(f));
  if (statuses.length) {
    statusQuery = `status[]=${statuses.join('&status[]=')}`;
  }

  let online = filters.includes('online');
  let offline = filters.includes('offline');
  let connectedQuery;
  if (!(online && offline) && (online || offline)) {
    connectedQuery = online ? 'connected=true' : 'connected=false';
  }

  let charging = filters.includes('charging');
  let discharging = filters.includes('discharging');
  let chargeQuery;
  if (!(charging && discharging) && (charging || discharging)) {
    chargeQuery = charging ? 'charging=true' : 'charging=false';
  }

  const pageSizeQuery = pageSize !== undefined && `page_size=${pageSize}`;

  const pageNumberQuery =
    pageNumber !== undefined && `page_number=${pageNumber + 1}`;

  const mapQuery = map && 'map=true';

  const queryParams = [
    pageSizeQuery,
    pageNumberQuery,
    statusQuery,
    connectedQuery,
    chargeQuery,
    mapQuery,
  ]
    .filter(Boolean)
    .join('&');

  return queryParams ? `?${queryParams}` : '';
}

export function formatUnitString(unit?: string): string {
  return unit ? unit.replace('&deg;', '°') : '';
}

const METRIC_TO_IMPERIAL = {
  '°C': '°F',
  hPa: 'psi',
  ml: 'in^3',
  mps: 'fps',
};
type METRIC_UNITS = keyof typeof METRIC_TO_IMPERIAL;
const IMPERIAL_TO_METRIC: any = Object.keys(METRIC_TO_IMPERIAL).reduce(
  (acc, m) => ({ ...acc, [METRIC_TO_IMPERIAL[m as METRIC_UNITS]]: m }),
  {}
);

export function metricToImperialMeasurement(
  measurement: ChannelMeasurement
): ChannelMeasurement {
  const metricValue = parseFloat(measurement.value);
  const metricUnit = measurement.unit;
  const { value, unit } = Array.isArray(measurement.value)
    ? { value: measurement.value, unit: metricUnit }
    : metricToImperial(metricValue, metricUnit);
  return { ...measurement, value: `${value}`, unit: formatUnitString(unit) };
}

export function metricToImperial(
  metricValue: number,
  metricUnit: string,
  alreadyConvertedUnit = false
): { value: number; unit: string } {
  if (alreadyConvertedUnit) {
    metricUnit = IMPERIAL_TO_METRIC[metricUnit] || metricUnit;
  }
  let value = metricValue;
  if (metricUnit === '°C') {
    value = 32 + metricValue * 1.8;
  } else if (metricUnit === 'hPa') {
    value = metricValue * 0.014503773772954;
  } else if (metricUnit === 'ml') {
    value = metricValue / 16.3872;
  } else if (metricUnit === 'mps') {
    value = metricValue * 3.281;
  }
  const floored = Math.floor(value);
  if (`${value}`.length - `${floored}`.length > 2) {
    value = parseFloat(value.toFixed(2));
  }
  const unit = METRIC_TO_IMPERIAL[metricUnit as METRIC_UNITS] || metricUnit;
  return { value, unit };
}

const ClassificationsToIcons = {
  gas: 'OilGas',
  power: 'AssetPole',
  light: 'Light',
  bridge: 'Bridge',
  pipeline: 'Pipeline',
  wind: 'Wind',
  other: 'Pin',
};

export function getAssetIcon(classification: Classification = 'power') {
  return ClassificationsToIcons[classification] as IconTypes;
}

export function getMeasurementUnits(
  measurements: {
    [key: string]: ChannelMeasurement;
  } = {}
) {
  return Object.values(measurements).reduce(
    (acc, m) => ({ ...acc, [m.name]: m.unit }),
    {} as { [key: string]: string }
  );
}
