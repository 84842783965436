import React from 'react';
import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';
import ResultsPerPage from './ResultsPerPage';
import PaginationItem from './PaginationItem';

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${toRem(27.5)};
  padding: ${toRem(33)};

  @media (min-width: ${theme.breakpoint.med}px) {
    justify-content: flex-end;
  }
`;

interface Props {
  pageCount: number;
  pageSize: number;
  setPageSize: (i: number) => void;
  pageNumber: number;
  setPageNumber: (i: number) => void;
}

const Pagination: React.FC<Props> = ({
  pageCount,
  pageSize,
  pageNumber,
  setPageSize,
  setPageNumber,
}) => {
  return (
    <Container>
      <ResultsPerPage value={pageSize} onChangeSize={setPageSize} />
      {pageCount > 1 && (
        <>
          <PaginationItem
            previous
            onClick={() => setPageNumber(pageNumber - 1)}
          />
          {pageCount > 5 ? (
            <>
              <PaginationItem
                label="1"
                onClick={() => setPageNumber(1)}
                isActive={pageNumber === 1}
              />
              <PaginationItem label="..." disabled />
              {Array.from(Array(pageCount).keys())
                .slice(pageCount - 3)
                .map((i) => (
                  <PaginationItem
                    key={i}
                    label={i + 1}
                    isActive={i === pageNumber}
                    onClick={() => setPageNumber(i)}
                  />
                ))}
            </>
          ) : (
            Array.from(Array(pageCount).keys()).map((i) => (
              <PaginationItem
                key={i}
                label={i + 1}
                isActive={i === pageNumber}
                onClick={() => setPageNumber(i)}
              />
            ))
          )}
          <PaginationItem
            next
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber + 1 >= pageCount}
          />
        </>
      )}
    </Container>
  );
};

export default Pagination;
