import { IconTypes } from '@awareness-ui/components';

export const STATUS_IDS = ['healthy', 'predictive', 'potential', 'critical'];
export const CONDITION_IDS = [
  'tilting',
  'smoke',
  'gas',
  'vegetation',
  'pressure',
  'arcing',
  'temperature',
];
export const CHARGING_IDS = ['charging', 'discharging'];
export const CONNECTED_IDS = ['online', 'offline'];

export const STATUS_ICON_NAMES: { [key: string]: IconTypes } = {
  healthy: 'StatusAlertHealthy',
  predictive: 'StatusAlertPredictive',
  potential: 'StatusAlertPotential',
  critical: 'StatusAlertCritical',
  tilting: 'ConditionTilting',
  smoke: 'ConditionSmoke',
  gas: 'ConditionGas',
  vegetation: 'ConditionVegetation',
  pressure: 'ConditionPressure',
  arcing: 'ConditionArcing',
  temperature: 'ConditionTemp',
  online: 'StatusOnline',
  offline: 'StatusOffline',
  charging: 'StatusCharging',
  discharging: 'StatusOffline',
};

export const STATUS_LABELS = {
  healthy: 'Healthy',
  predictive: 'Predictive',
  potential: 'Potential Harm',
  critical: 'Critical',
  tilting: 'Tilting',
  smoke: 'Smoke/Fire',
  gas: 'Gas',
  vegetation: 'Vegetation',
  pressure: 'Pressure',
  arcing: 'Arcing',
  temperature: 'Temperature',
  online: 'Online',
  offline: 'Offline',
  charging: 'Charging',
  discharging: 'Discharging',
};
