import React from 'react';
import styled from 'styled-components';

import { toRem } from '@awareness-ui/design';
import { Icon } from '../Icon';
import { Classification } from '@awareness/types';
import { getAssetIcon } from '@awareness/assets';

interface StatusProps {
  fill: string;
  classification: Classification;
}

const Container = styled.div<Partial<StatusProps>>`
  display: flex;
  height: ${toRem(30)};
  width: ${toRem(30)};
  border-radius: ${toRem(15)};
  justify-content: center;
  align-items: center;
  background-color: ${({ fill }) => fill};
`;

export const StatusCircle: React.FC<StatusProps> = ({
  fill,
  classification,
}) => {
  return (
    <Container fill={fill}>
      <Icon icon={getAssetIcon(classification)} fill="white" />
    </Container>
  );
};
