import theme from './theme';
import { ScaleTypes } from './types/ScaleTypes';

export const getScale: (multiplyer: ScaleTypes) => number = (
  multiplier: ScaleTypes
) => {
  const BASESIZE = 11;
  return BASESIZE * multiplier;
};

export const toRem: (n: number) => string = (n: number) =>
  `${n / theme.rem}rem`;

export const formatChannelName = (name = '') =>
  name.replace('Gas Resistance', 'Methane Emissions');
