import {
  call,
  cancelled,
  put,
  select,
  take,
  takeEvery,
} from '@redux-saga/core/effects';
import { EventChannel, eventChannel } from '@redux-saga/core';
import { Channel, Socket } from 'phoenix';
import toast from 'react-hot-toast';
import { ActionWithPayload } from 'robodux';

import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { EventResponse } from '@awareness/types';
import { setEventsListenerEnabled } from '../slice';

var socket: Socket | undefined;

type ChannelEventType = 'connected' | 'update' | 'error' | 'timeout';

interface ChannelEvent {
  type: ChannelEventType;
  data: EventResponse;
}

function* onEventUpdate({ event, asset, channel, thresholds }: EventResponse) {
  if (event !== 'alert') {
    return;
  }

  const title = `${asset.name} ${channel}`;
  const actionLabel = 'View Asset';
  const onAction = () => {
    window.location.pathname = `/asset/${asset.id}`;
  };
  thresholds.forEach(({ description, severity }) => {
    if (severity === 'healthy') {
      toast.success(description, {
        title,
        actionLabel,
        onAction,
        duration: 4000,
      } as any);
    } else {
      toast.error(description, {
        title,
        actionLabel,
        onAction,
        duration: 10000,
      } as any);
    }
  });
}

function* onChannelError() {
  yield put(setEventsListenerEnabled(false));
  toast.error('Failed to join events socket', {
    title: 'Event Notifications Disconnected',
  } as any);
}

export function* eventsListener() {
  if (process.env.NODE_ENV === 'development') {
    console.log('Event listener not activated in development mode.');
    return;
  }
  const userToken: string = yield select(getToken);
  const apiEndpoint: string = yield select(getApiEndpoint);

  socket = new Socket(
    `wss://${apiEndpoint.replace(/http.*:\/\//, '')}/events`,
    {
      params: { userToken },
    }
  );
  socket.connect();

  // connect to a Phoenix Channel for a given name (and no parameters)
  const channel = socket.channel('events:*', {});

  const sagaChannel = startSocketMessageListener(channel);
  try {
    while (true) {
      const { type, data }: ChannelEvent = yield take(sagaChannel);
      console.log('channel event', type);

      if (type === 'connected') {
        yield put(setEventsListenerEnabled(true));
      } else if (type === 'update') {
        yield call(onEventUpdate, data);
      } else {
        yield call(onChannelError);
      }
    }
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      channel.leave();
    }
  }
}

function startSocketMessageListener(channel: Channel): EventChannel<any> {
  return eventChannel((emitter) => {
    channel.on('update', (data: EventResponse) =>
      emitter({ type: 'update', data })
    );
    channel.onError(() => emitter({ type: 'error' }));
    channel
      .join()
      .receive('ok', () => emitter({ type: 'connected' }))
      .receive('timeout', () => emitter({ type: 'timeout' }));
    return () => channel.leave();
  });
}

function* onSetEventsListenerEnabled({ payload }: ActionWithPayload<boolean>) {
  if (payload) {
    yield call(eventsListener);
  } else {
    socket && socket.disconnect();
    socket = undefined;
  }
}

export function* disconnectEventListenerSaga() {
  yield takeEvery([`${setEventsListenerEnabled}`], onSetEventsListenerEnabled);
}
