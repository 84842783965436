import { breakpoints, colors, font, shadow, spacing } from './tokens';

const theme = {
  borderRadius: {
    small: 11,
    medium: 16.5,
    large: 22,
  },
  borderWidth: 1,
  breakpoint: {
    ...breakpoints,
  },
  button: {
    height: {
      large: 44,
      small: 33,
    },
  },
  color: {
    ...colors,
  },
  map: {
    detailView: {
      medium: 320,
      large: 420,
    },
  },
  dropdown: {
    item: {
      height: {
        desktop: 33,
        mobile: 44,
      },
    },
  },
  font: {
    ...font,
  },
  header: {
    height: 66,
  },
  input: {
    height: 44,
  },
  layout: {
    gutter: {
      large: 55,
      small: 22,
    },
  },
  modal: {
    footer: {
      height: 88,
    },
  },
  nav: {
    width: {
      closed: 66,
      open: 220,
    },
  },
  rem: 16,
  shadow: { ...shadow },
  sidebar: {
    padding: 33,
    width: 462,
  },
  spacing: {
    ...spacing,
  },
  table: {
    label: {
      offset: 19,
    },
  },
  transition: {
    button: {
      default: 'ease-out 100ms',
      slow: 'ease-out 200ms',
      fast: 'ease-out 75ms',
    },
    icon: '100ms ease-in',
    nav: '225ms ease-out',
  },
  zIndex: {
    content: 0,
    dialog: 400,
    header: 300,
    modal: 500,
    nav: 100,
    sideview: 200,
    toast: 600,
  },
};

export default theme;
