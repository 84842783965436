import { createSlice } from 'robodux';

import { ReduxState } from '../types';

const { actions, reducer, name } = createSlice({
  name: 'token',
  initialState: {
    token: '',
    require_password_reset: false,
  },
  reducers: {
    setToken: (state, payload: object) => ({ ...state, ...payload }),
    setRequiredPassword: (state: any, require_password_reset: any) => ({
      ...state,
      require_password_reset,
    }),
  },
});

export const { setToken, setRequiredPassword } = actions;
export const getToken = (state: ReduxState) => `${state.token?.token}`;
export const getIsAuthenticated = (state: ReduxState) =>
  Boolean(state.token?.token);
export const getRequiredPassword = (state: ReduxState) =>
  Boolean(state.token?.require_password_reset);
const reducers = { [name]: reducer };

export { reducers };
