import { createStore, applyMiddleware, Action, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { enableBatching } from 'redux-batched-actions';
import { persistStore, persistReducer, PURGE } from 'redux-persist';
import sagaCreator from 'redux-saga-creator';
import pick from 'lodash.pick';
import { composeWithDevTools } from 'redux-devtools-extension';

import { ReduxState } from '@awareness/types';
import packages from './package-loader';

const DO_NOT_RESET_REDUCER_KEYS: (keyof ReduxState)[] = ['apiEndpoint'];

const appReducer = combineReducers<ReduxState>(packages.reducers as any);
const rootReducer = (state: ReduxState | undefined, action: Action) => {
  if (action.type === PURGE) {
    const unresetState = pick(state, DO_NOT_RESET_REDUCER_KEYS);
    const resetState = appReducer(undefined, action);
    return { ...resetState, ...unresetState };
  }
  return appReducer(state, action);
};

const debug = process.env.NODE_ENV === 'development';
const whitelist: (keyof ReduxState)[] = [
  'apiEndpoint',
  'developerSettings',
  'eventsListenerEnabled',
  'token',
];
const persistConfig = {
  key: 'root',
  storage,
  whitelist,
  debug,
  debounce: 1000,
  timeout: null as any,
};

const sagaMiddleware = createSagaMiddleware();
const rootSaga = sagaCreator(packages.sagas);

const batchedReducer = enableBatching(rootReducer);
const persistedReducer = persistReducer(persistConfig, batchedReducer);
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
// if (debug) {
(window as any).store = store;
// }
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
