import styled from 'styled-components';

import { theme } from '@awareness-ui/design';

const ModalOverlay = styled.div.attrs({
  'aria-hidden': 'true',
})`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${theme.zIndex.modal};
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(83, 92, 112, 0.88);
`;

export default ModalOverlay;
