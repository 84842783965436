import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import { Group } from '@awareness/types';
import { theme, toRem } from '@awareness-ui/design';
import {
  H1,
  Button,
  FieldWrapper,
  Input,
  Text,
} from '@awareness-ui/components';

const Form = styled.form`
  margin-top: 50px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteButton = styled(Text)`
  color: ${theme.color.error};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${toRem(12)};
  margin: 10px;
`;

interface Props {
  id: string;
  name: string;
  onUpdate: (n: string) => void;
  onDelete: () => void;
}

export const EditGroup: React.FC<Props> = ({
  id,
  name,
  onUpdate,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [editName, setName] = useState(name);
  const [isDeleting, setIsDeleting] = useState(false);

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const saveGroup = async (event: React.MouseEvent) => {
    event?.preventDefault();
    if (!editName) {
      toast.error('Missing required field(s)');
      return;
    } else if (editName.length < 3) {
      toast.error('Invalid name');
      return;
    }

    setLoading(true);

    let method = 'PUT';
    const url = `${apiEndpoint}/client/assets/groups/${id}`;

    const body = new FormData();
    body.append('name', editName);
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: Group = await response.json();

      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error updating the group.');
      }

      toast.success('Group updated!');
      onUpdate(json.name);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const deleteGroup = async () => {
    setLoading(true);

    let method = 'DELETE';
    const url = `${apiEndpoint}/client/assets/groups/${id}`;
    try {
      const response = await apiFetchAsync({ url, token, method });
      if (response.status !== 204) {
        throw new Error('Uh-oh! There was an error deleting the group.');
      }
      toast.success('Group deleted');
      onDelete();
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  return (
    <>
      <Form onSubmit={saveGroup as any}>
        <H1>Edit Group</H1>
        <Fieldset>
          <FieldWrapper label="Name">
            <Input
              style={{ width: 440 }}
              type="text"
              value={editName}
              onChange={(e: any) => setName(e.currentTarget.value)}
            />
          </FieldWrapper>
        </Fieldset>
        {isDeleting ? (
          <>
            Are you sure?
            <Button
              label="Yes, delete"
              appearance="secondary"
              size="small"
              onClick={deleteGroup}
              style={{ margin: '10px' }}
            />
            <Button
              label="Cancel"
              appearance="secondary"
              size="small"
              onClick={() => setIsDeleting(false)}
            />
          </>
        ) : (
          <>
            <Button
              style={{ marginRight: 10 }}
              disabled={loading}
              onClick={saveGroup}
              label="Save"
            />
            <DeleteButton onClick={() => setIsDeleting(true)}>
              Delete Group
            </DeleteButton>
          </>
        )}
      </Form>
    </>
  );
};
