import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import styled from 'styled-components';
import { useToaster } from 'react-hot-toast';

import { getIsAuthenticated, getRequiredPassword } from '@awareness/auth';
import { bootup } from '@awareness/bootup';
import {
  Clients,
  Home,
  Login,
  AlertList,
  Users,
  ResetPassword,
  User,
  ChangePassword,
  NotificationPreferences,
  UserActivityList,
  Reports,
  ReportAssetSelection,
  ReportSecondaryAssetSelection,
  ReportDateRange,
  ReportView,
  Groups,
  NewGroup,
  Group,
  GroupAssetSelection,
  GroupUserSelection,
} from '@awareness-ui/pages';
import {
  GlobalStyles,
  DeveloperSettingsModal,
  AssetDetail,
  Nav,
  Header,
  ToastContainer,
} from '@awareness-ui/components';
import { AssetList } from '@awareness-ui/pages';
import { theme } from '@awareness-ui/design';
import { store, persistor } from './store';
import { getUserRoles, getIsAdmin, getIsSuperAdmin } from '@awareness/user';

const HomeContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow: hidden;
`;

const MainView = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Toasts = styled.div`
  position: absolute;
  top: 20px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: ${theme.zIndex.toast};
  pointer-events: none;
`;

function App() {
  useEffect(() => {
    store.dispatch(bootup());
  }, []);

  const { visibleToasts, handlers } = useToaster();

  return (
    <Provider store={store}>
      <GlobalStyles />
      <PersistGate persistor={persistor} loading={null}>
        <DeveloperSettingsModal />
        <Router />
        <Toasts>
          {visibleToasts.map((toast) => (
            <ToastContainer key={toast.id} {...handlers} {...toast} />
          ))}
        </Toasts>
      </PersistGate>
    </Provider>
  );
}

const Router = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [navOpen, setNavOpen] = useState(false);
  const roles = useSelector(getUserRoles);
  const isAdmin = getIsAdmin(roles);
  const isSuper = getIsSuperAdmin(roles);
  const isRequiredPassword = useSelector(getRequiredPassword);

  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Redirect to={{ pathname: '/login' }} />
        <Switch>
          <Route
            path="/reset-password"
            children={(props) => <ResetPassword {...props} />}
          />
          <Route path="/" children={(props) => <Login {...props} />} />
        </Switch>
      </BrowserRouter>
    );
  }

  const routes = [
    <Route
      key="asset"
      path="/asset/:id"
      children={(props) => (
        <AssetDetail
          id={props.match?.params.id ? (props.match?.params.id as any) : null}
        />
      )}
    />,
    <Route
      key="list"
      path="/list"
      children={(props) => <AssetList {...props} />}
    />,
    <Route
      key="alerts"
      path="/alerts"
      children={(props) => <AlertList {...props} />}
    />,
    <Route
      key="change-passwd"
      path="/me/change-password"
      children={(props) => <ChangePassword {...props} />}
    />,
    <Route
      key="notification-prefs"
      path="/me/notification-preferences"
      children={(props) => <NotificationPreferences {...props} />}
    />,
    <Route key="me" path="/me" children={(props) => <User {...props} />} />,
    <Route key="home" path="/" children={(props) => <Home {...props} />} />,
  ];

  if (isAdmin) {
    routes.unshift(
      <Route
        key="user-activity"
        path="/users/activity/:user_id"
        children={(props) => (
          <UserActivityList userId={props.match?.params.user_id} {...props} />
        )}
      />,
      <Route
        key="activities"
        path="/users/activity"
        children={(props) => <UserActivityList {...props} />}
      />,
      <Route
        key="users"
        path="/users"
        children={(props) => <Users {...props} />}
      />,
      <Route
        key="reports-channel"
        path="/reports/:channel/:primaries/none"
        exact
        children={(props) => <ReportSecondaryAssetSelection {...props} />}
      />,
      <Route
        key="reports-channel"
        path="/reports/:channel/:primaries/:secondary/:dates"
        children={(props) => <ReportView {...props} />}
      />,
      <Route
        key="reports-channel"
        path={[
          '/reports/:channel/:primaries/:secondary',
          '/reports/:channel/:primaries',
        ]}
        children={(props) => <ReportDateRange {...props} />}
      />,
      <Route
        key="reports-selection"
        path="/reports/:channel"
        children={(props) => <ReportAssetSelection {...props} />}
      />,
      <Route
        key="reports"
        path="/reports"
        children={(props) => <Reports {...props} />}
      />,
      <Route
        key="group"
        path="/group/:id/add-asset"
        children={(props) => <GroupAssetSelection {...props} />}
      />,
      <Route
        key="group"
        path="/group/:id/add-user"
        children={(props) => <GroupUserSelection {...props} />}
      />,
      <Route
        key="new-groups"
        path="/group/new"
        children={(props) => <NewGroup {...props} />}
      />,
      <Route
        key="group"
        path="/group/:id"
        children={(props) => <Group {...props} />}
      />,
      <Route
        key="groups"
        path="/group"
        children={(props) => <Groups {...props} />}
      />
    );
  }
  if (isSuper) {
    routes.unshift(
      <Route
        key="clients"
        path="/clients"
        children={(props) => <Clients {...props} />}
      />
    );
  }

  return (
    <BrowserRouter>
      <HomeContainer>
        {isRequiredPassword ? (
          <></>
        ) : (
          <Nav navOpen={navOpen} setNavOpen={setNavOpen} />
        )}
        <MainView>
          <Header navOpen={navOpen} setNavOpen={setNavOpen} />
          {roles === undefined ? null : <Switch>{routes}</Switch>}
        </MainView>
      </HomeContainer>
    </BrowserRouter>
  );
};

export default App;
