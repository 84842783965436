import React from 'react';
import Charts from 'react-apexcharts';
import { CHANNELS_MAP } from '@awareness/util';
import * as XLSX from 'xlsx';
import { Button } from '../Button';

interface INewLineChart {
  data: any;
  name: string;
  channelName: string;
  minMax: {
    startDate: Date;
    endDate: Date;
  };
  unit: string;
}

const LineChart: React.FC<INewLineChart> = ({
  data,
  name,
  minMax,
  channelName,
  unit,
}) => {
  const downloadExcel = () => {
    const formattedData = data.reduce(
      (memo: any[], d: any) => {
        const [timestamp, value] = d;
        const date = new Date(timestamp);
        const now = new Date();
        // Display time only if data points have the same year and month
        const formattedTime = date.toLocaleTimeString([], {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
        memo.push([formattedTime, value]);
        return memo;
      },
      [['Date', `Value (${unit})`]]
    );
    const ws = XLSX.utils.json_to_sheet(formattedData);
    ws['!cols'] = [
      { wch: 30 }, // Column A width: 15 characters
      { wch: 20 }, // Column B width: 20 characters
      { wch: 25 }, // Column C width: 25 characters
    ];
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, channelName);
    XLSX.writeFile(wb, `${channelName}.xlsx`);
  };

  const series = [
    {
      name: 'Value',
      data: data,
    },
  ];

  const options = {
    chart: {
      id: CHANNELS_MAP[channelName],
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        export: {
          csv: {
            headerCategory: 'Date',
            columnDelimiter: ';',
            dateFormatter: function (timestamp: any) {
              const date = new Date(timestamp);
              const now = new Date();
              // Display time only if data points have the same year and month
              const formattedTime = date.toLocaleTimeString([], {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              });
              return formattedTime;
            },
          },
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: '#999',
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    yaxis: [
      {
        title: {
          text: name,
        },
      },
    ],
    xaxis: {
      type: 'datetime',
      min: minMax.startDate.toLocaleString(),
      max: minMax.endDate.toLocaleString(),
      tickAmount: 6,
      labels: {
        formatter: function (value: number, timestamp: number, opts: any) {
          const date = new Date(timestamp);
          const now = new Date();

          if (
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth()
          ) {
            // Display time only if data points have the same year and month
            const formattedTime = date.toLocaleTimeString([], {
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            });
            return formattedTime;
          } else {
            const formattedDate = date.toLocaleString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            });
            return formattedDate;
          }
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy hh:ss',
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  return (
    <div id="chart">
      <div id="chart-timeline">
        <Charts
          options={options}
          series={series}
          type="area"
          height={350}
          width="800"
        />
      </div>

      <Button label="Download Excel" onClick={downloadExcel}>
        Download Excel
      </Button>
    </div>
  );
};

export default LineChart;
