import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import { theme } from '@awareness-ui/design';
import { Button, H1, Subtext, Steps } from '@awareness-ui/components';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { apiFetchAsync } from '@awareness/api-fetch';
import { ChannelListItem } from '@awareness/types';
import {
  CHANNEL_WIND_DIRECTION,
  CHANNEL_WIND_SPEED,
  ChannelName,
} from '@awareness/util';
import toast from 'react-hot-toast';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const Content = styled.div`
  padding: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChannelList = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 900px;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const PrimaryButton = styled.div<{
  isSelected: boolean;
  isPrimary: boolean;
  isSecondary: boolean;
}>`
  width: 250px;
  line-height: 90px;
  text-align: center;
  border: 1px solid ${theme.color.border.light};
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: ${theme.shadow.card};

  ${({ isSelected, isPrimary, isSecondary }) => {
    if (isSelected && isPrimary)
      return css`
        color: ${theme.color.white};
        background-color: ${theme.color.primary[100]};
        border-width: 0;
      `;
    if (isSelected && isSecondary)
      return css`
        color: ${theme.color.white};
        background-color: ${theme.color.secondary[88]};
        border-width: 0;
      `;
  }}
`;

interface Props extends RouteChildrenProps {}

export const Reports: React.FC<Props> = () => {
  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);

  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState<ChannelListItem[]>([]);
  const [selectedPrimary, setSelectedPrimary] =
    useState<ChannelName | undefined>();
  const [selectedSecondary, setSelectedSecondary] =
    useState<ChannelName | undefined>();

  useEffect(() => {
    fetchChannelList();
  }, []);

  const selectHandler = (name: ChannelName, isSelected: boolean) => {
    if (isSelected) {
      if (selectedPrimary === name) setSelectedPrimary(undefined);
      if (selectedSecondary === name) setSelectedSecondary(undefined);
    } else {
      if (selectedSecondary && selectedPrimary)
        return toast.error(
          'Sorry, you have already selected a primary and secondary'
        );
      if (selectedPrimary && !selectedSecondary) setSelectedSecondary(name);
      if (!selectedPrimary) setSelectedPrimary(name);
    }
  };

  const fetchChannelList = async () => {
    setLoading(true);

    const url = `${apiEndpoint}/channels`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json: ChannelListItem[] = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching channels.');
      }

      setChannels(json);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <MainView>
        <Steps count={3} index={0} type="report" />
        <Content style={{ margin: 'auto' }}>Loading...</Content>
      </MainView>
    );
  }

  return (
    <MainView>
      <Steps count={3} index={0} type="report" />
      <Content>
        <H1>New Report</H1>
        <Subtext style={{ marginBottom: 10 }}>
          Select a primary channel (up to seven assets) and an optional
          secondary channel (one asset) you would like to see on the report.
        </Subtext>
        <ChannelList>
          {channels
            .filter((channel) => {
              return (
                channel.name !== CHANNEL_WIND_DIRECTION &&
                channel.name !== CHANNEL_WIND_SPEED
              );
            })
            .map((c) => {
              const name: ChannelName = c.name;
              const isPrimary = selectedPrimary === name;
              const isSecondary = selectedSecondary === name;
              const isSelected = isPrimary || isSecondary;
              return (
                <PrimaryButton
                  key={name}
                  onClick={() => selectHandler(name, isSelected)}
                  isSelected={isSelected}
                  isPrimary={isPrimary}
                  isSecondary={isSecondary}>
                  {name}
                </PrimaryButton>
              );
            })}
        </ChannelList>
        {selectedPrimary ? (
          // TODO: modify URL format to allow for multiple channels
          <Button
            label="Next"
            to={`/reports/${selectedPrimary}${
              selectedSecondary ? ',' + selectedSecondary : ''
            }`}
            disabled={!selectedPrimary}
          />
        ) : (
          <Button label="Next" disabled={true} />
        )}
      </Content>
    </MainView>
  );
};
