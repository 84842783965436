import styled, { css } from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';

interface ModalContentProps {
  sticky?: boolean;
}

export default styled.section<Partial<ModalContentProps>>`
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: ${toRem(16)};

  ${({ sticky }) =>
    sticky &&
    css`
      padding-bottom: ${toRem(theme.modal.footer.height)};
      overflow: scroll;

      &:last-child {
        padding-bottom: ${toRem(33)};
      }
    `}
`;
