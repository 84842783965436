import React from 'react';

import styled from 'styled-components';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

import { AssetImage } from '@awareness/types';
import { toRem } from '@awareness-ui/design';

const ImageGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
`;

const Image = styled.img`
  max-height: 200px;
  border-radius: ${toRem(10)};
  padding: 5px 5px 0;
  transition: opacity linear 300ms;

  ${({ hidden }) => (hidden ? 'display: none;' : '')}

  &:hover {
    opacity: 0.9;
  }
`;

interface Props {
  images?: AssetImage[];
}

const ThumbnailGroup: React.FC<Props> = ({ images }) => {
  if (!images) {
    return <div>Loading...</div>;
  }

  if (images.length === 0) {
    return <div>No Images</div>;
  }

  return (
    <SRLWrapper
      options={{
        buttons: {
          showDownloadButton: false,
          showAutoplayButton: false,
          showFullscreenButton: false,
        },
      }}>
      <ImageGroup onClick={useLightbox}>
        {images.map(({ id, url, description }, i) => (
          <Image
            src={url}
            alt={description || `Asset image ${i + 1} (id: ${id})`}
            key={id}
          />
        ))}
      </ImageGroup>
    </SRLWrapper>
  );
};

export default ThumbnailGroup;
