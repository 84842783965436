interface API_FETCH_ASYNC {
  url: string;
  token: string;
  method?: string;
  body?: any;
}
export function apiFetchAsync({
  url,
  token,
  method = 'GET',
  body,
}: API_FETCH_ASYNC) {
  return fetch(url, {
    headers: { Authorization: `Bearer: ${token}` },
    method,
    body,
  });
}
