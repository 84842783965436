import React from 'react';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';

import { Button, EditAccount, H1 } from '@awareness-ui/components';
import { toRem } from '@awareness-ui/design';
import { useSelector } from 'react-redux';
import { getUser } from '@awareness/user';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 20px;
`;

const Content = styled.div`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
  width: 100%;
`;
const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const ListItem = styled(Button).attrs({ appearance: 'secondary' })`
  border-radius: 0;
  border-top-width: 0;
  width: 100%;
`;

interface Props extends RouteChildrenProps {}

export const User: React.FC<Props> = ({ history }) => {
  const user = useSelector(getUser);
  const { client } = user;

  return (
    <Container>
      <Button
        style={{ alignSelf: 'flex-start' }}
        label="< Back"
        appearance="secondary"
        onClick={() => history.goBack()}
      />
      <Content>
        <H1 style={{ textAlign: 'center', marginBottom: 20 }}>Edit Account</H1>
        <ItemWrapper>
          {client?.id && (
            <EditAccount hideTitle client_id={client.id} account_id={user.id} />
          )}
          <div>
            <ListItem
              style={{ borderTopWidth: 1 }}
              to="/me/change-password"
              label="Change Password"
            />
            <ListItem
              to="/me/notification-preferences"
              label="Notification Preferences"
            />
          </div>
        </ItemWrapper>
      </Content>
    </Container>
  );
};
