import React from 'react';

import styled from 'styled-components';

import { AssetStatus } from '@awareness/types';
import { capitalize } from '@awareness/util';
import { theme, toRem } from '@awareness-ui/design';
import { Label } from '../Typography';

interface StatusProps {
  status: AssetStatus;
}

const Container = styled.div<StatusProps>`
  display: flex;
  height: ${toRem(25)};
  width: ${toRem(70)};
  border-radius: ${toRem(14)};
  justify-content: center;
  align-items: center;
  background-color: ${({ status }) => (theme.color.status as any)[status]};
`;

const StyledLabel = styled(Label)`
  color: white;
  font-size: ${toRem(11)};
  font-weight: ${theme.font.weight.semiBold};
`;

export const StatusDot = styled.div<{ status: AssetStatus }>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ status }) => (theme.color.status as any)[status]};
  margin-right: 11px;
`;

export const ColorDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 6px solid ${({ color }) => color};
  margin-right: 11px;
`;

export const Status: React.FC<StatusProps> = ({ status }) => {
  return (
    <Container status={status}>
      <StyledLabel>{capitalize(status)}</StyledLabel>
    </Container>
  );
};
