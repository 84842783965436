import React from 'react';

import IconList, {
  SVGProps,
  PathProps,
  PolygonProps,
  CircleProps,
} from './IconList';
import { IconTypes } from './IconTypes';

interface IconProps extends React.SVGProps<any> {
  icon: IconTypes;
  fill?: any;
  overrideBorder?: boolean;
}

const Icon: React.FC<IconProps> = ({ icon, fill, overrideBorder, ...rest }) => {
  const haveSVG = Object.prototype.hasOwnProperty.call(IconList, icon);
  const svg: SVGProps | null = haveSVG ? IconList[icon] : null;

  if (!svg) {
    return null;
  }

  const extraProperty = overrideBorder
    ? {
        stroke: 'red',
        strokeWidth: '2px',
        strokeLinejoin: 'round',
      }
    : {};
  return (
    <svg viewBox={svg.viewbox} {...rest}>
      <g fill={fill && fill}>
        {svg.circles
          ? svg.circles.map(({ cx, cy, r, fill }: CircleProps) => (
              <circle
                key={`${cx}-${cy}-${r}`}
                cx={cx}
                cy={cy}
                r={r}
                fill={fill}
              />
            ))
          : null}

        {svg.paths
          ? svg.paths.map(({ path, fill, fillRule, clipRule }: PathProps) => (
              <path
                key={path}
                d={path}
                fill={fill}
                fillRule={fillRule}
                clipRule={clipRule}
                stroke={extraProperty?.stroke}
                strokeWidth={extraProperty?.strokeWidth}
              />
            ))
          : null}

        {svg.polygons
          ? svg.polygons.map(({ points, fill }: PolygonProps, i) => (
              <polygon key={i} points={points} fill={fill} />
            ))
          : null}
      </g>
    </svg>
  );
};

Icon.defaultProps = { width: 16.5, height: 16.5 };

export default Icon;
