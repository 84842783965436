import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import {
  Button,
  Checkbox,
  FieldWrapper,
  H1,
  Modal,
  ModalContent,
  Text,
} from '@awareness-ui/components';
import { toRem } from '@awareness-ui/design';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 20px;
`;

const Form = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
  max-width: 400px;
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 400px;
`;

const Link = styled.a`
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
`;
interface Props extends RouteChildrenProps {}

export const NotificationPreferences: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [receiveSms, setReceiveSms] = useState<boolean>();
  const [newReceiveSms, setNewReceiveSms] = useState<boolean>();
  const [receiveEmail, setReceiveEmail] = useState<boolean>();
  const [newReceiveEmail, setNewReceiveEmail] = useState<boolean>();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState<boolean>();

  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);
  const initializedemail = receiveEmail !== undefined;
  const initialized = receiveSms !== undefined;

  useEffect(() => {
    fetchPrefs();
  }, []);

  const fetchPrefs = async () => {
    setLoading(true);
    const url = `${apiEndpoint}/me/preferences`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching your preferences.');
      }
      setReceiveSms(json.receive_sms);
      setNewReceiveSms(json.receive_sms);
      setReceiveEmail(json.receive_email);
      setNewReceiveEmail(json.receive_email);
    } catch (err) {
      toast.error(`${err}`);
    }
    setLoading(false);
  };

  const onUpdatePreferences = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!initialized || !initializedemail) {
      return;
    }
    setLoading(true);
    const url = `${apiEndpoint}/me/preferences`;
    const body = new FormData();
    body.append('receive_sms', `${newReceiveSms}`);
    body.append('receive_email', `${newReceiveEmail}`);
    const method = 'PUT';

    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! Changing your preferences failed.');
      }

      toast.success('Preferences updated!');
      setReceiveSms(json.receive_sms);
      setReceiveEmail(json.receive_email);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  return (
    <Container>
      <Button
        style={{ alignSelf: 'flex-start' }}
        label="< Back"
        appearance="secondary"
        onClick={() => history.goBack()}
      />
      <Form onSubmit={onUpdatePreferences as any}>
        <H1>Notification Preferences</H1>

        {initialized ? (
          <Fieldset disabled={loading}>
            <FieldWrapper>
              <Checkbox
                name="receive-sms"
                label="Receive SMS"
                icon="Phone"
                onChange={(ev) => setNewReceiveSms(ev.target.checked)}
                checked={newReceiveSms}
              />
              <Checkbox
                name="receive-email"
                label="Receive EMAIL"
                icon="Email"
                onChange={(ev) => setNewReceiveEmail(ev.target.checked)}
                checked={newReceiveEmail}
              />
            </FieldWrapper>
            <Link onClick={() => setShowTermsAndConditionsModal(true)}>
              Terms and Conditions
            </Link>
          </Fieldset>
        ) : (
          'Loading...'
        )}
        {[initialized, initializedemail] && [
            receiveSms !== newReceiveSms,
            receiveEmail !== newReceiveEmail,
          ] && (
            <>
              <Text style={{ fontSize: 13, marginBottom: 10 }}>
                Press save to save your changes
              </Text>
              <Button label="Save" onClick={onUpdatePreferences} />
            </>
          )}
      </Form>
      <NotificationPreferenceModal
        showNotificationPreferenceModal={showTermsAndConditionsModal}
        closeNotificationpreferenceDrawer={() =>
          setShowTermsAndConditionsModal(false)
        }
      />
    </Container>
  );
};

const NotificationPreferenceModal = ({
  showNotificationPreferenceModal,
  closeNotificationpreferenceDrawer,
}: any) => {
  return (
    <Modal
      maxWidth={900}
      isActive={showNotificationPreferenceModal}
      onClose={closeNotificationpreferenceDrawer}>
      <ModalContent>
        <Container>
          <H1>Terms and conditions</H1>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ lineHeight: '1.5' }}>
              To opt-in for SMS (Short Message Service) alerts, please check the
              “Receive SMS” box above, then click Save 
            </span>
            <span style={{ lineHeight: '1.5', paddingTop: '12px' }}>
              By opting in to receive SMS messages from Hayden Data Corporation,
              you acknowledge that you have read, understood, and agree to abide
              by these terms and conditions: 
            </span>
            <ul style={{ lineHeight: '1.5', paddingTop: '12px' }}>
              <li>
                Consent to Receive SMS Messages: By opting in to receive SMS
                messages you consent to receive informational messages
                containing alerts, updates, and notifications regarding your IoT
                devices from our messaging phone number: (423) 250-9922. 
              </li>
              <li>
                Message Frequency: You acknowledge that message frequency may
                vary depending on your configuration settings and the status of
                your IoT devices.  
              </li>
              <li>
                Data Rates and Charges: Standard messaging and data rates may
                apply. By opting in to receive SMS messages from us, you agree
                to bear any applicable charges associated with receiving these
                messages.  
              </li>
              <li>
                Opt-Out Procedure: You have the right to opt out of receiving
                SMS messages at any time. To opt out, simply reply to any
                message with the word “STOP” or follow the instructions provided
                in the message.  
              </li>
              <li>
                Privacy and Security:  Any data collected for the purpose of
                sending SMS messages will be handled in accordance with our
                Privacy Policy. View our Privacy Policy at
                https://www.haydendata.com/privacy-policy-notice/  
              </li>
            </ul>
          </div>
        </Container>
      </ModalContent>
    </Modal>
  );
};
