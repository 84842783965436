import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import {
  Asset,
  Client,
  Classification,
  CLASSIFICATIONS,
} from '@awareness/types';
import { toRem } from '@awareness-ui/design';
import { addAssets } from '@awareness/assets';
import {
  getSelectedClientConductorTypes,
  getSelectedClientCustomIdLabel,
  getSelectedClientId,
} from '@awareness/user';

import { Button } from '../Button';
import { Input, FieldWrapper, Checkbox } from '../Inputs';
import { H1 } from '../Typography';
import { Modal, ModalContent } from '../Modal';

const Container = styled.div`
  display: flex;
  padding: 16px;
  padding-top: 8px;
  flex-direction: column;
  overflow-y: auto;
`;

const Form = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
`;

interface Props {
  onClose: () => void;
  goToAsset: (id: number) => void;
}

export const NewAssetModal: React.FC<Props> = ({ onClose, goToAsset }) => {
  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);
  const clientIdNumber = useSelector(getSelectedClientId);
  const customIdLabel = useSelector(getSelectedClientCustomIdLabel);
  const conductorTypes = useSelector(getSelectedClientConductorTypes);

  const [loading, setLoading] = useState<boolean>(false);
  const [editName, setName] = useState<string>();
  const [editLat, setLat] = useState<string>();
  const [editLng, setLng] = useState<string>();
  const [editType, setType] = useState<string>();
  const [editCamera, setCamera] = useState<boolean>(false);
  const [hasGateway, setHasGateway] = useState<boolean>(false);
  const [editClassification, setClassification] =
    useState<Classification>('power');
  const [editThingsquareId, setThingsquareId] = useState<string>();
  const [editCustomId, setCustomId] = useState<string>('');
  const [clientId, setClientId] = useState<string>(`${clientIdNumber}`);
  const [clients, setClients] = useState<Client[]>([]);
  const [conductorType, setConductorType] = useState<string>('');

  const dispatch = useDispatch();

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    setLoading(true);
    const url = `${apiEndpoint}/admin/clients`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching clients.');
      }
      setClients(json);
    } catch (err) {
      toast.error(`${err}`);
    }
    setLoading(false);
  };

  const createAsset = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (
      !editName ||
      !editType ||
      !editLat ||
      !editLng ||
      !editClassification ||
      !editThingsquareId ||
      !clientId
    ) {
      toast.error('Missing required field(s)');
      return;
    } else if (editName.length < 3) {
      toast.error('Invalid name');
      return;
    }

    setLoading(true);

    let method = 'POST';
    const url = `${apiEndpoint}/admin/assets`;

    const body = new FormData();
    body.append('name', editName);
    body.append('type', editType);
    body.append('latitude', `${editLat}`);
    body.append('longitude', `${editLng}`);
    body.append('thingsquare_id', editThingsquareId);
    body.append('client_id', clientId);
    body.append('classification', editClassification);
    body.append('has_camera', `${editCamera}`);
    body.append('has_gateway', `${hasGateway}`);
    body.append('custom_id', editCustomId);
    body.append('conductor_type', conductorType);
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: Asset = await response.json();

      if (response.status !== 201) {
        throw new Error('Uh-oh! There was an error saving the asset.');
      }

      dispatch(addAssets({ [`${json.id}`]: json }));
      toast.success('Asset saved!');
      goToAsset(json.id);
      onClose();
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  return (
    <Modal
      style={{ width: 600 }}
      maxWidth={900}
      isActive={true}
      onClose={onClose}>
      <ModalContent>
        <Container>
          <Form onSubmit={createAsset as any}>
            <H1>Create New Asset</H1>
            <Fieldset>
              <FieldWrapper label="Classification">
                <select
                  onChange={(e) => setClassification(e.target.value as any)}
                  value={editClassification}>
                  {CLASSIFICATIONS.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </FieldWrapper>
              <FieldWrapper label="Name">
                <Input
                  style={{ width: 440 }}
                  type="text"
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </FieldWrapper>
              {editClassification === 'power' && (
                <FieldWrapper label="Conductor Type">
                  <select
                    onChange={(e) => setConductorType(e.target.value as any)}
                    value={conductorType}>
                    {conductorTypes.map((c) => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    ))}
                  </select>
                </FieldWrapper>
              )}
              <FieldWrapper label="Type">
                <Input
                  style={{ width: 440 }}
                  type="text"
                  onChange={(e) => setType(e.currentTarget.value)}
                />
              </FieldWrapper>
              <FieldWrapper label="Camera">
                <Checkbox
                  name="Has Camera"
                  label="This device has a camera"
                  icon="Image"
                  onChange={(e) => {
                    setCamera(e.currentTarget.checked);
                  }}
                  checked={editCamera}
                />
              </FieldWrapper>
              <FieldWrapper label="Gateway">
                <Checkbox
                  name="Has Gateway"
                  label="This device has a gateway"
                  icon="Gateway"
                  onChange={(e) => {
                    setHasGateway(e.currentTarget.checked);
                  }}
                  checked={hasGateway}
                />
              </FieldWrapper>
              <Row>
                <FieldWrapper label="Latitude">
                  <Input
                    style={{ width: 205, marginRight: 30 }}
                    type="text"
                    onChange={(e) => setLat(e.currentTarget.value)}
                  />
                </FieldWrapper>
                <FieldWrapper label="Longitude">
                  <Input
                    style={{ width: 205 }}
                    type="text"
                    onChange={(e) => setLng(e.currentTarget.value)}
                  />
                </FieldWrapper>
              </Row>
              <FieldWrapper label="Thingsquare ID">
                <Input
                  style={{ width: 440 }}
                  type="text"
                  onChange={(e) => setThingsquareId(e.currentTarget.value)}
                />
              </FieldWrapper>
              <FieldWrapper label={customIdLabel}>
                <Input
                  style={{ width: 440 }}
                  type="text"
                  onChange={(e) => setCustomId(e.currentTarget.value)}
                />
              </FieldWrapper>
              <FieldWrapper label="Client">
                <select
                  onChange={(e) => setClientId(e.target.value as any)}
                  value={clientId}>
                  {clients.map((client) => (
                    <option value={client.id} key={client.id}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </FieldWrapper>
            </Fieldset>
            <Row>
              <Button
                style={{ marginRight: 10 }}
                disabled={loading}
                onClick={createAsset}
                label="Create"
              />
              <Button
                disabled={loading}
                appearance="secondary"
                onClick={onClose}
                label="Cancel"
              />
            </Row>
          </Form>
        </Container>
      </ModalContent>
    </Modal>
  );
};
