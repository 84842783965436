import React, { useState } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';

import { AssetImage } from '@awareness/types';
import { theme, toRem } from '@awareness-ui/design';
import { useDispatch, useSelector } from 'react-redux';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import { deleteAssetImage } from '@awareness/assets';
import { Icon } from '../Icon';
import { PhotoUpload } from '.';

const ImageGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
  background: ${theme.color.background.medium};
  padding: 10px;
  border-radius: ${toRem(10)};
`;

const SelectedView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.color.background.medium};
  padding: 10px;
  border-radius: ${toRem(10)};
`;

const Image = styled.img`
  max-height: 120px;
  padding: 5px 5px 0;
  border-radius: ${toRem(10)};
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const Link = styled.div`
  text-decoration: underline;
  cursor: pointer;
  padding: 0 5px;
`;

const CloseIcon = styled(Icon).attrs({ icon: 'Close' })`
  cursor: pointer;
  box-sizing: content-box;
  min-width: 20px;
  min-height: 20px;
  padding: 8px;
  align-self: flex-end;
`;

interface Props {
  assetId: number;
  images?: AssetImage[];
  onClose: () => void;
}

const ThumbnailEdit: React.FC<Props> = ({ assetId, images, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [editingImage, setEditingImage] = useState<AssetImage>();
  const [deletingImage, setDeletingImage] = useState<AssetImage>();

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const dispatch = useDispatch();

  async function deletePhoto() {
    if (!deletingImage) {
      return;
    }

    setLoading(true);
    const imageId = deletingImage.id;
    const url = `${apiEndpoint}/assets/${assetId}/images/${imageId}`;
    const method = 'DELETE';
    try {
      const response = await apiFetchAsync({ url, token, method });

      if (response.status !== 204) {
        const json = await response.json();
        throw new Error(
          (json || {}).message || 'Delete failed. Please try again.'
        );
      }

      dispatch(deleteAssetImage({ id: assetId, imageId }));
      toast.success('Image deleted!');
      if (images?.length === 1) {
        onClose();
        return;
      }
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
    setDeletingImage(undefined);
  }

  if (editingImage) {
    return (
      <PhotoUpload
        id={assetId}
        onClose={() => setEditingImage(undefined)}
        editingImage={editingImage}
      />
    );
  }

  if (deletingImage) {
    return (
      <SelectedView>
        <CloseIcon onClick={() => setDeletingImage(undefined)} />
        <Image
          src={deletingImage.url}
          alt={
            deletingImage.description || `Asset image (id: ${deletingImage.id})`
          }
        />
        <div style={{ margin: 10 }}>Are you sure you want to delete?</div>
        <Link
          style={{
            color: loading ? theme.color.background.dark : theme.color.error,
          }}
          onClick={loading ? undefined : deletePhoto}>
          Yes, delete
        </Link>
      </SelectedView>
    );
  }

  return (
    <ImageGroup>
      {(images || []).map((image, i) => {
        const { id, url, description } = image;
        return (
          <div key={id}>
            <Image
              src={url}
              alt={description || `Asset image ${i + 1} (id: ${id})`}
            />
            <Row>
              <Link onClick={() => setEditingImage(image)}>edit</Link>
              <Link onClick={() => setDeletingImage(image)}>delete</Link>
            </Row>
          </div>
        );
      })}
    </ImageGroup>
  );
};

export default ThumbnailEdit;
