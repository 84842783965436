import React from 'react';

import styled, { css } from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';
import { Toast } from 'react-hot-toast/dist/core/types';

import { Icon } from '../Icon';
import { Button } from '../Button';

interface ExtraToastProps {
  title?: string;
  actionLabel?: string;
  onAction?: () => void;
}

interface ToastContainerProps extends Partial<Toast>, ExtraToastProps {
  startPause?: (event: any) => void;
  endPause?: (event: any) => void;
}

const Layout = styled.div<Partial<Toast>>`
  display: flex;
  pointer-events: auto;
  margin-left: auto;
  margin-right: 40px;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  max-width: ${toRem(360)};
  height: auto;
  min-height: ${toRem(77)};
  margin-bottom: ${toRem(5.5)};
  padding: ${toRem(16.5)} ${toRem(22)};
  border-radius: ${toRem(5.5)};
  background-color: ${theme.color.white};
  box-shadow: ${theme.shadow.dialog};
  overflow: visible;

  @media (min-width: ${theme.breakpoint.small}px) {
    min-width: ${toRem(400)};
    max-width: ${toRem(450)};
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${({ type }) =>
    type === 'success' &&
    css`
      border-left: ${toRem(5.5)} solid ${theme.color.status.healthy};
    `}

  ${({ type }) =>
    type === 'error' &&
    css`
      border-left: ${toRem(5.5)} solid ${theme.color.status.critical};
    `}
`;

const RightColumn = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
`;

const Message = styled.div`
  margin: 0;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.75)};
  line-height: 125%;
  color: ${theme.color.text[100]};
`;

const Title = styled(Message)`
  margin-bottom: ${toRem(3.75)};
  font-weight: ${theme.font.weight.semiBold};
`;

const StyledButton = styled(Button)`
  margin-left: ${toRem(22)};
`;

const StyledIcon = styled(Icon).attrs({
  width: 22,
  height: 22,
})`
  margin-right: ${toRem(16.5)};
`;

const ToastContainer: React.FC<ToastContainerProps> = ({
  type,
  message,
  title,
  actionLabel,
  onAction,
  startPause,
  endPause,
}) => {
  return (
    <Layout type={type} onMouseEnter={startPause} onMouseLeave={endPause}>
      {type === 'error' && (
        <StyledIcon icon="Error" fill={theme.color.status.critical} />
      )}
      {type === 'success' && (
        <StyledIcon icon="Success" fill={theme.color.status.healthy} />
      )}
      <RightColumn>
        <Content>
          {title && <Title>{title}</Title>}
          <Message>{message}</Message>
        </Content>

        {actionLabel && (
          <StyledButton
            label={actionLabel}
            type="button"
            appearance="secondary"
            size="small"
            onClick={onAction}
          />
        )}
      </RightColumn>
    </Layout>
  );
};

export default ToastContainer;
