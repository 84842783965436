import { IconTypes } from '@awareness-ui/components';

export const MEASUREMENT_ICON_NAMES: { [key: string]: IconTypes } = {
  'Barometric Pressure': 'ConditionPressure',
  'Battery Voltage': 'StatusCharging',
  'Carbon Monoxide': 'ConditionGas',
  'Gas Resistance': 'ConditionGas',
  'Charge Status': 'StatusCharging',
  'MCU Temperature': 'ConditionTemp',
  'Air Temperature': 'ConditionTemp',
  'MCU Voltage': 'StatusCharging',
  'UV A Index': 'ConditionVegetation',
  'White Light Irradiance': 'ConditionVegetation',
  'Wind Direction': 'Compass',
  'X-Z Pole Angle': 'ConditionSmoke',
  'Delta Rain': 'ConditionUmbrella',
  'Total Rain': 'ConditionUmbrella',
  'Rain Diff': 'ConditionUmbrella',
  'Dew Point': 'ConditionRain',
  'Methane Emissions': 'ConditionGas',
  'Wind Speed': 'Wind',
  Offline: 'StatusCharging',
};

export const CHANNELS_MAP: { [key: string]: string } = {
  'Barometric Pressure': 'Barometric Pressure',
  'Battery Voltage': 'Battery Voltage',
  'Carbon Monoxide': 'Carbon Monoxide',
  'Gas Resistance': 'ConGas ResistanceditionGas',
  'Charge Status': 'Charge Status',
  'MCU Temperature': 'MCU Temperature',
  'Air Temperature': 'Air Temperature',
  'MCU Voltage': 'MCU Voltage',
  'UV A Index': 'UV A Index',
  'White Light Irradiance': 'White Light Irradiance',
  'Wind Direction': 'Wind Direction',
  'X-Z Pole Angle': 'ConditX-Z Pole AngleionSmoke',
  'Delta Rain': 'Delta Rain',
  'Total Rain': 'Total Rain',
  'Rain Diff': 'Rain Diff',
  'Dew Point': 'Dew Point',
  'Methane Emissions': 'Methane Emissions',
  'Wind Speed': 'Wind Speed',
};

export const MEASUREMENT_CHART_PROPS = {
  'Barometric Pressure': { format: '1d', min: 980, max: 1020 },
  'Battery Voltage': { format: '.2f', min: 3.0, max: 3.9 },
  'Carbon Monoxide': { format: '.2f', min: 0, max: 1000 },
  'Gas Resistance': { format: '.2f', min: 0, max: 4500000000 },
  'Charge Status': { format: '.1d', min: 0, max: 1 },
  'MCU Temperature': { min: -35, max: 85 },
  'Air Temperature': { min: -40, max: 85 },
  'MCU Voltage': { format: '.2f', min: 3.0, max: 3.9 },
  'UV A Index': { format: '.2f', min: 0, max: 365 },
  'UV B Index': { format: '.2f', min: 0, max: 330 }, // TODO: verify name
  'UV Index': { format: '.2f', min: 0, max: 20 }, // TODO: verify name
  'White Light Irradiance': { min: 0, max: 25000 },
  'Wind Direction': { min: 0, max: 200 },
  'X-Z Pole Angle': { format: '.2f', min: -180, max: 180 },
  'Y-Z Pole Angle': { format: '.2f', min: -180, max: 180 },
  'Wind Speed': { format: '.2f', min: 0, max: 70 },
  'Percent Relative Humidity': { format: '.1f', min: 0, max: 100 },
  'Rain Diff': null,
  'Delta Rain': null,
  'Total Rain': null,
  'Dew Point': null,
  Wind: null,
  Offline: { format: '.1d', min: 0, max: 1 },
};

export const CHANNEL_NAMES = Object.keys(MEASUREMENT_CHART_PROPS);

export type ChannelName = keyof typeof MEASUREMENT_CHART_PROPS;

export const CHANNEL_AIR_TEMPRATURE = 'Air Temperature';
export const CHANNEL_WIND = 'Wind';
export const CHANNEL_RAIN_DIFF = 'Rain Diff';
export const CHANNEL_DELTA_RAIN = 'Delta Rain';
export const CHANNEL_TOTAL_RAIN = 'Total Rain';
export const CHANNEL_BAROMETRIC_PRESSURE = 'Barometric Pressure';
export const CHANNEL_WIND_SPEED = 'Wind Speed';
export const CHANNEL_WIND_DIRECTION = 'Wind Direction';
export const CHANNEL_GAS_RESISTANCE = 'Gas Resistance';
export const CHANNEL_DEW_POINT = 'Dew Point';

export const TEMPERATURE_UNIT_OPTIONS = [
  { label: '°F', value: '°F' },
  { label: '°C', value: '°C' },
  { label: 'K', value: 'K' },
];

export const RAIN_UNIT_OPTIONS = [
  { label: 'in', value: 'in' },
  { label: 'ml', value: 'ml' },
];

export const BAROMETRIC_PRESSURE_UNIT_OPTIONS = [
  { label: 'hPa', value: 'hPa' },
  { label: 'psi', value: 'psi' },
  { label: 'mbar', value: 'mbar' },
  { label: 'inHg', value: 'inHg' },
];

export const WIND_SPEED_UNIT_OPTIONS = [
  { label: 'mps', value: 'mps' },
  { label: 'fps', value: 'fps' },
  { label: 'mph', value: 'mph' },
];
