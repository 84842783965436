import React from 'react';

import styled, { css, keyframes } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

interface LoaderProps {
  size?: 'large' | 'small';
  color?: string;
  className?: string;
}

const LARGE_SIZE = 11;
const LARGE_GAP = LARGE_SIZE * 2;
const SMALL_SIZE = 8.75;
const SMALL_GAP = SMALL_SIZE * 2;
const TIMING = `750ms`;

const Layout = styled.div<Partial<LoaderProps>>`
  position: relative;
  display: inline-flex;

  ${({ size }) =>
    size === 'large' &&
    css`
      width: ${toRem(LARGE_GAP * 2 + LARGE_SIZE)};
      height: ${toRem(LARGE_SIZE)};
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      width: ${toRem(SMALL_GAP * 2 + SMALL_SIZE)};
      height: ${toRem(SMALL_SIZE)};
    `}

  > * {
    position: absolute;
    top: 0;
    width: ${({ size }) =>
      size === 'large' ? toRem(LARGE_SIZE) : toRem(SMALL_SIZE)};
    height: ${({ size }) =>
      size === 'large' ? toRem(LARGE_SIZE) : toRem(SMALL_SIZE)};
    border-radius: 50%;
    background-color: ${({ color }) =>
      color ? color : theme.color.primary[100]};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
`;

const scaleUp = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const translateXLarge = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(${toRem(LARGE_SIZE * 2)}, 0);
  }
`;

const translateXSmall = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(${toRem(SMALL_SIZE * 2)}, 0);
  }
`;

const scaleDown = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const Dot = styled.span.attrs({
  'aria-hidden': true,
})<Partial<LoaderProps>>`
  &:nth-child(1) {
    left: 0;
    animation: ${scaleUp} ${TIMING} infinite;
  }

  &:nth-child(2) {
    left: 0;
    animation: ${({ size }) =>
        size === 'large' ? translateXLarge : translateXSmall}
      ${TIMING} infinite;
  }

  &:nth-child(3) {
    left: ${({ size }) =>
      size === 'large' ? toRem(LARGE_GAP) : toRem(SMALL_GAP)};
    animation: ${({ size }) =>
        size === 'large' ? translateXLarge : translateXSmall}
      ${TIMING} infinite;
  }

  &:nth-child(4) {
    left: ${({ size }) =>
      size === 'large' ? toRem(LARGE_GAP * 2) : toRem(SMALL_GAP * 2)};
    animation: ${scaleDown} ${TIMING} infinite;
  }
`;

const Loader: React.FC<LoaderProps> = ({
  size = 'large',
  color = theme.color.primary[100],
  className,
}) => {
  return (
    <Layout
      aria-label="Loading"
      size={size}
      color={color}
      className={className}>
      <Dot size={size} />
      <Dot size={size} />
      <Dot size={size} />
      <Dot size={size} />
    </Layout>
  );
};

export default Loader;
