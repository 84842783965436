const font = {
  family: {
    averta: 'Averta, serif',
    inter: 'Inter, sans-serif',
  },
  weight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
};

export default font;
