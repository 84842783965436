import React, { useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '@awareness-ui/design';
import { H1, Button, Steps } from '@awareness-ui/components';
import { DateRangePicker } from 'react-date-range';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const Content = styled.div`
  padding: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DatePickerContainer = styled.div`
  box-shadow: ${theme.shadow.card};
  margin-top: 20px;
  margin-bottom: 30px;
`;

const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;
const NOW = new Date();

interface Props extends RouteChildrenProps {}

export const ReportDateRange: React.FC<Props> = (props) => {
  const channel: string = (props.match?.params || ({} as any)).channel;
  const primaries: string = (props.match?.params || ({} as any)).primaries;
  const secondary: string = (props.match?.params || ({} as any)).secondary;

  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(endDate.valueOf() - DAY_MILLISECONDS * 7)
  );

  const onDatePickerChange = (range: {
    selection: { startDate: Date; endDate: Date };
  }) => {
    setStartDate(range.selection.startDate);
    setEndDate(range.selection.endDate);
  };

  const STEPCOUNT = secondary ? 4 : 3;
  return (
    <MainView>
      <Steps
        type="report"
        count={STEPCOUNT}
        index={2}
        channel={channel}
        idsStr={primaries}
      />
      <Content>
        <H1>Select Dates</H1>
        Select start and end dates for your {channel} report.
        <DatePickerContainer>
          <DateRangePicker
            ranges={[{ startDate, endDate, key: 'selection' }]}
            onChange={onDatePickerChange as any}
            maxDate={NOW}
          />
        </DatePickerContainer>
        <Button
          label="Done"
          to={`/reports/${channel}/${primaries}/${
            secondary ? secondary : ''
          }/${startDate.getTime()},${endDate.getTime()}`}
        />
      </Content>
    </MainView>
  );
};
