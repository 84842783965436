import { takeEvery, call, put } from 'redux-saga/effects';
import { ActionWithPayload, createAction } from 'robodux';

import { APIResponse } from '@awareness/types';
import { apiFetch, getBody, DEFAULT_ERROR_MESSAGE } from '@awareness/api-fetch';
import { setToken } from '../slice';

function* onLogin({
  payload: { email, password, onError, onSuccess },
}: ActionWithPayload<LoginPayload>) {
  const response: APIResponse<{
    token: string;
    require_password_reset: boolean;
  }> = yield call(apiFetch, {
    endpoint: '/login',
    method: 'POST',
    body: getBody({ email, password }),
    showErrorToast: false,
    Authorization: '',
  });

  if (!response || !response.token) {
    yield call(
      onError,
      response ? 'Invalid credentials' : DEFAULT_ERROR_MESSAGE
    );
    return;
  }

  yield put(setToken(response));

  yield call(onSuccess);
}

export interface LoginPayload {
  email: string;
  password: string;
  onError: (e: string) => void;
  onSuccess: () => void;
}

export const login = createAction<LoginPayload>('LOGIN');
export function* loginSaga() {
  yield takeEvery(`${login}`, onLogin);
}
