import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { getChannelUnit } from '@awareness/channel';
import {
  Asset,
  ChannelMeasurement,
  ReduxState,
  Threshold,
  Classification,
  CLASSIFICATIONS,
  SensorMeasurementArray,
  SensorMeasurement,
} from '@awareness/types';
import { formatChannelName, theme, toRem } from '@awareness-ui/design';
import { getPoleDetailLocationVisible } from '@awareness/developer-settings';
import {
  fetchAssetById,
  getAssetById,
  getMeasurementUnits,
  patchAssets,
  removeAssets,
} from '@awareness/assets';
import {
  getIsSuperAdmin,
  getSelectedClientConductorTypes,
  getSelectedClientCustomIdLabel,
  getUserRoles,
} from '@awareness/user';
import {
  capitalize,
  barometricPressureValueConversion,
  windSpeedValueConversion,
  rainDiffValueConversion,
  airTemperatureValueConversion,
  MEASUREMENT_ICON_NAMES,
  CHANNEL_AIR_TEMPRATURE,
  CHANNEL_DEW_POINT,
  CHANNEL_RAIN_DIFF,
  CHANNEL_DELTA_RAIN,
  CHANNEL_WIND_SPEED,
  CHANNEL_BAROMETRIC_PRESSURE,
  CHANNEL_TOTAL_RAIN,
  CHANNEL_GAS_RESISTANCE,
  CHANNEL_WIND_DIRECTION,
  CHANNEL_WIND,
} from '@awareness/util';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { apiFetchAsync } from '@awareness/api-fetch';

import EmptyImage from '@awareness-ui/images/no-channels.svg';
import { Icon } from '../Icon';
import { H1, H3, Label, Text } from '../Typography';
import { Status } from '../Status';
import ChannelCard from './ChannelCard';
import { ActionMenu, ActionMenuItem, ActionButton } from '../ActionMenu';
import {
  PhotoRequest,
  PhotoUpload,
  ThumbnailGroup,
  ThumbnailEdit,
} from '../Photo';
import { Modal, ModalContent } from '../Modal';
import { Chart } from '../Charts';
import { ThresholdView } from '../Threshold';
import { Link } from '../Link';
import { Input, FieldWrapper, Checkbox } from '../Inputs';
import { Button } from '../Button';

interface Props {
  id: null | number;
  setSelectedId?: (id: number | null) => void;
  hasLink?: boolean;
}

const Container = styled.div`
  display: flex;
  padding: 16px;
  padding-top: 8px;
  flex-direction: column;
  overflow-y: auto;
`;

const CloseIcon = styled(Icon).attrs({ icon: 'Close' })`
  cursor: pointer;
  box-sizing: content-box;
  align-self: flex-end;
  min-width: 20px;
  min-height: 20px;
  border-radius: 20px;
  padding: 8px;

  &:hover {
    background-color: ${theme.color.background.light};
  }
`;

const SmallLink = styled.div`
  text-decoration: underline;
  cursor: pointer;
  padding: 0 5px;
  color: ${theme.color.primary[100]};
  margin-top: ${toRem(20)};
`;

const CameraIcon = styled(Icon).attrs({ icon: 'Image' })`
  box-sizing: content-box;
  align-self: flex-end;
  min-width: 24px;
  min-height: 24px;
  padding-left: 8px;
  fill: ${theme.color.text[100]};
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
`;

const LeftLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  text-decoration-color: ${theme.color.text[100]};
  &:hover {
    text-decoration: underline;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

const PoleName = styled(H1)`
  margin-bottom: ${toRem(4)};
  max-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${theme.color.text[100]};
`;

const SectionHeader = styled(H3)`
  margin: ${toRem(40)} 0 ${toRem(16)};
`;

const PoleID = styled.div.attrs({ size: 'small' })`
  display: flex;
  color: ${theme.color.text[44]};
  font-size: ${toRem(12)};
`;

const DetailContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${toRem(12)} 0;
`;

const ClickableDetailRow = styled(DetailRow)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ThresholdInfo = styled(Label)`
  color: ${theme.color.text[44]};
  font-size: ${toRem(12)};
`;

const LeftDetail = styled(Label)`
  text-decoration: uppercase;
  flex: none;
`;

const RightDetail = styled(Label)`
  flex: none;
`;

const Divider = styled.div`
  display: flex;
  height: ${toRem(1)};
  width: 100%;
  background-color: ${theme.color.border.light};
  margin: 0 ${toRem(16)};
`;

const ChannelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${toRem(16)};
`;

const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: span 2;
`;

const ScrollRow = styled.div`
  display: flex;
  width: 100%;
  height: 120px;
  align-items: center;
  overflow: scroll;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const MeasurementCard = styled(Text)<{ selected: boolean }>`
  display: flex;
  border: 1px solid ${theme.color.border.light};
  border-radius: ${toRem(8)};
  padding: ${toRem(4)};
  transition: background-color ${theme.transition.button.slow};
  cursor: pointer;
  min-width: 140px;
  min-height: 64px;
  margin-right: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;

  ${({ selected }) =>
    selected
      ? `background: ${theme.color.primary[100]}; color: ${theme.color.white};`
      : '&:hover { background-color: ${theme.color.background.light}; }'}
`;

const Form = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteButton = styled(Text)`
  color: ${theme.color.error};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${toRem(12)};
  margin: 10px;
`;

type ImageView = 'gallery' | 'upload' | 'request' | 'edit';

export const AssetDetail: React.FC<Props> = ({
  id,
  setSelectedId,
  hasLink,
}) => {
  const asset = useSelector((state: ReduxState) =>
    getAssetById(state, id || undefined)
  );
  const coordinatesVisible = useSelector(getPoleDetailLocationVisible);
  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);
  const roles = useSelector(getUserRoles);
  const isSuper = getIsSuperAdmin(roles);
  const customIdLabel = useSelector(getSelectedClientCustomIdLabel);
  const conductorTypes = useSelector(getSelectedClientConductorTypes);

  const {
    name,
    status,
    online,
    latitude,
    longitude,
    type,
    // measurements,
    measurements: defaultMeasurement,
    images,
    thresholds,
    thingsquare_id,
    classification,
    has_camera,
    has_gateway,
    custom_id,
    conductor_type,
  } = asset || ({} as Asset);

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [photoActionsOpen, setPhotoActionsOpen] = useState<boolean>(false);
  const [imageView, setImageView] = useState<ImageView>('gallery');
  const [selectedMeasurementName, setSelectedMeasurementName] = useState('');
  const [selectedThreshold, setSelectedThreshold] = useState<Threshold>();
  const [editName, setName] = useState<string>();
  const [editLat, setLat] = useState<string>();
  const [editLng, setLng] = useState<string>();
  const [editType, setType] = useState<string>();
  const [editCamera, setCamera] = useState<boolean>(has_camera);
  const [hasGateway, setHasGateway] = useState<boolean>(has_gateway);
  const [editThingsquareId, setThingsquareId] = useState<string>();
  const [editCustomId, setCustomId] = useState<string>();
  const [editConductorType, setConductorType] = useState<string>('');
  const [editClassification, setClassification] =
    useState<Classification>('power');

  const dispatch = useDispatch();

  const channelData = useSelector(getChannelUnit);

  // FIlter out the Gas Resistance channel
  if (defaultMeasurement) delete defaultMeasurement[CHANNEL_GAS_RESISTANCE];

  const measurements =
    defaultMeasurement &&
    Object.keys(defaultMeasurement).reduce((memo: any, channel) => {
      if (channel === CHANNEL_AIR_TEMPRATURE || channel === CHANNEL_DEW_POINT) {
        const temperatureValues = defaultMeasurement[channel];
        const value = {
          ...defaultMeasurement[channel],
          value: airTemperatureValueConversion(
            //@ts-ignore
            Number(temperatureValues.value),
            temperatureValues.unit,
            //@ts-ignore
            channelData[channel]
          ),
          unit: channelData[channel],
        };
        memo[channel] = value;
      } else if (
        channel === CHANNEL_DELTA_RAIN ||
        channel === CHANNEL_TOTAL_RAIN ||
        channel === CHANNEL_RAIN_DIFF
      ) {
        const rainValues = defaultMeasurement[channel];
        const value = {
          ...defaultMeasurement[channel],
          value: rainDiffValueConversion(
            //@ts-ignore
            Number(rainValues.value),
            rainValues.unit,
            //@ts-ignore
            channelData[channel]
          ),
          unit: channelData[channel],
        };
        memo[channel] = value;
      } else if (channel === CHANNEL_BAROMETRIC_PRESSURE) {
        const pressureValues = defaultMeasurement[channel];
        const value = {
          ...defaultMeasurement[channel],
          value: barometricPressureValueConversion(
            //@ts-ignore
            Number(pressureValues.value),
            pressureValues.unit,
            //@ts-ignore
            channelData[channel]
          ),
          unit: channelData[channel],
        };
        memo[channel] = value;
      } else if (channel === CHANNEL_WIND_SPEED) {
        const speedValues = defaultMeasurement[channel];
        const value = {
          ...defaultMeasurement[channel],
          value: windSpeedValueConversion(
            //@ts-ignore
            Number(speedValues.value),
            speedValues.unit,
            //@ts-ignore
            channelData[channel]
          ),
          unit: channelData[channel],
        };
        memo[channel] = value;
      } else if (channel === CHANNEL_WIND) {
        const speedValues = defaultMeasurement[channel];
        const windVal = JSON.parse(speedValues.value)[0];
        const windAngle = JSON.parse(speedValues.value)[1];

        memo[channel] = {
          ...defaultMeasurement[channel],
          value: JSON.stringify([
            windSpeedValueConversion(
              //@ts-ignore
              Number(windVal),
              speedValues.unit,
              //@ts-ignore
              'mph' // default unit for wind
            ),
            windAngle,
          ]),
        };
      } else {
        memo[channel] = defaultMeasurement[channel];
      }
      return memo;
    }, {});

  const history = useHistory();

  const onError = useCallback((err: string) => {
    setLoading(false);
    toast.error(err);
  }, []);

  const onSuccess = useCallback((a: Asset) => {
    setName(a.name);
    setType(a.type);
    setLat(`${a.latitude}`);
    setLng(`${a.longitude}`);
    setThingsquareId(a.thingsquare_id);
    setLoading(false);
    setCamera(a.has_camera);
    setHasGateway(a.has_camera);
    setClassification(a.classification);
    setCustomId(a.custom_id || '');
    setConductorType(a.conductor_type || '');
  }, []);

  useEffect(() => {
    if (id === null) {
      return;
    }

    dispatch(fetchAssetById({ id, onError, onSuccess }));
  }, [id]);

  const closeThresholdModal = useCallback(
    () => setSelectedThreshold(undefined),
    []
  );

  const updateAsset = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!editName || !editType || !editLat || !editLng || !editThingsquareId) {
      toast.error('Missing required field(s)');
      return;
    } else if (editName.length < 3) {
      toast.error('Invalid name');
      return;
    }

    setLoading(true);

    let method = 'PUT';
    const url = `${apiEndpoint}/admin/assets/${id}`;

    const body = new FormData();
    body.append('name', editName);
    body.append('type', editType);
    body.append('latitude', `${editLat}`);
    body.append('longitude', `${editLng}`);
    body.append('thingsquare_id', editThingsquareId);
    body.append('custom_id', editCustomId || '');
    body.append('conductor_type', editConductorType);
    body.append('classification', editClassification);
    body.append('has_camera', `${editCamera}`);
    body.append('has_gateway', `${hasGateway}`);
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: Asset = await response.json();

      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error saving the asset.');
      }

      dispatch(patchAssets({ [`${id}`]: json }));
      toast.success('Asset saved!');
    } catch (err) {
      toast.error(`${err}`);
    }

    setIsEditing(false);
    setLoading(false);
  };

  const deleteAsset = async () => {
    setLoading(true);

    const method = 'DELETE';
    const url = `${apiEndpoint}/admin/assets/${id}`;
    try {
      const response = await apiFetchAsync({ url, token, method });
      if (response.status !== 204) {
        throw new Error('Uh-oh! There was an error deleting the asset.');
      }

      dispatch(removeAssets([`${id}`]));
      toast.success('Asset deleted');
    } catch (err) {
      toast.error(`${err}`);
    }

    setIsDeleting(false);
    setLoading(false);
    history.push('/list');
  };

  const requestImage = async (size: string) => {
    const errorResponses: { [key: number]: string } = {
      404: 'Asset not found, check permissions.',
      400: 'Camera not available for this device ',
      429: 'Maximum number of images have been requested.  Wait a moment, then try again.',
    };
    const url = `${apiEndpoint}/assets/${id}/images/request?size=${size}`;
    const method = 'POST';

    try {
      const response = await apiFetchAsync({ url, token, method });

      if (response.status !== 204) {
        throw new Error(errorResponses[response.status]);
      }

      toast.success('Image requested.');
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  if (loading || !asset || id === null) {
    return (
      <Container>
        <HeaderInfo style={{ justifyContent: 'center' }}>
          {loading ? 'Loading' : null}
        </HeaderInfo>
      </Container>
    );
  }

  if (isEditing) {
    return (
      <Container>
        <Form onSubmit={updateAsset as any}>
          <Fieldset>
            <PoleName>#{id}</PoleName>
            <FieldWrapper label="Name">
              <Input
                defaultValue={name}
                type="text"
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </FieldWrapper>
            {isSuper && (
              <FieldWrapper label="Classification">
                <select
                  onChange={(e) => setClassification(e.target.value as any)}
                  value={editClassification}>
                  {CLASSIFICATIONS.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </FieldWrapper>
            )}
            {editClassification === 'power' && (
              <FieldWrapper label="Conductor Type">
                <select
                  onChange={(e) => setConductorType(e.target.value as any)}
                  value={editConductorType}>
                  {conductorTypes.map((c) => (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  ))}
                </select>
              </FieldWrapper>
            )}
            <FieldWrapper label="Type">
              <Input
                defaultValue={type}
                type="text"
                onChange={(e) => setType(e.currentTarget.value)}
              />
            </FieldWrapper>
            <FieldWrapper label="Camera">
              <Checkbox
                name="Has Camera"
                label="This device has a camera"
                icon="Image"
                onChange={(e) => {
                  setCamera(e.currentTarget.checked);
                }}
                checked={editCamera}
              />
            </FieldWrapper>
            <FieldWrapper label="Gateway">
              <Checkbox
                name="Has gateway"
                label="This device has a gateway"
                icon="Gateway"
                onChange={(e) => {
                  setHasGateway(e.currentTarget.checked);
                }}
                checked={hasGateway}
              />
            </FieldWrapper>
            <FieldWrapper label="Latitude">
              <Input
                defaultValue={latitude}
                type="text"
                onChange={(e) => setLat(e.currentTarget.value)}
              />
            </FieldWrapper>
            <FieldWrapper label="Longitude">
              <Input
                defaultValue={longitude}
                type="text"
                onChange={(e) => setLng(e.currentTarget.value)}
              />
            </FieldWrapper>
            <FieldWrapper label="Thingsquare ID">
              <Input
                defaultValue={thingsquare_id}
                type="text"
                onChange={(e) => setThingsquareId(e.currentTarget.value)}
              />
            </FieldWrapper>
            <FieldWrapper label={customIdLabel}>
              <Input
                defaultValue={custom_id || ''}
                type="text"
                onChange={(e) => setCustomId(e.currentTarget.value)}
              />
            </FieldWrapper>
          </Fieldset>
          {isDeleting ? (
            <Row>
              Are you sure?
              <Button
                label="Yes, delete"
                appearance="secondary"
                size="small"
                onClick={deleteAsset}
                style={{ margin: '0 10px' }}
              />
              <Button
                label="Cancel"
                appearance="secondary"
                size="small"
                onClick={() => setIsDeleting(false)}
              />
            </Row>
          ) : (
            <>
              <Row>
                <Button
                  style={{ marginRight: 10 }}
                  disabled={loading}
                  onClick={updateAsset}
                  label="Update"
                />
                <Button
                  disabled={loading}
                  appearance="secondary"
                  onClick={() => setIsEditing(false)}
                  label="Cancel"
                />
              </Row>
              <DeleteButton onClick={() => setIsDeleting(true)}>
                Delete Asset
              </DeleteButton>
            </>
          )}
        </Form>
      </Container>
    );
  }

  const measurementsArr: SensorMeasurementArray = Object.values(
    measurements || {}
  );

  const HeaderComponent: any = hasLink ? LeftLink : Left;

  return (
    <>
      <Modal
        style={{ width: 'auto' }}
        maxWidth={1000}
        isActive={selectedThreshold !== undefined}
        onClose={closeThresholdModal}>
        <ModalContent>
          {selectedThreshold && (
            <ThresholdView
              threshold={selectedThreshold}
              channelUnits={getMeasurementUnits(measurements)}
              onClose={closeThresholdModal}
            />
          )}
        </ModalContent>
      </Modal>
      <Modal
        maxWidth={1000}
        isActive={!!selectedMeasurementName}
        onClose={() => setSelectedMeasurementName('')}>
        <ModalContent>
          {(measurements || {})[selectedMeasurementName] && (
            <Chart
              id={id}
              {...((measurements || {})[
                selectedMeasurementName
              ] as ChannelMeasurement)}
            />
          )}
          <ScrollRow style={{ overflow: 'scroll' }}>
            {measurementsArr.map((m: SensorMeasurement) => (
              <MeasurementCard
                key={`${m.name} ${m.unit}`}
                selected={m.name === selectedMeasurementName}
                onClick={() => setSelectedMeasurementName(m.name)}>
                {formatChannelName(m.name)}
              </MeasurementCard>
            ))}
          </ScrollRow>
        </ModalContent>
      </Modal>
      <Container>
        {setSelectedId ? (
          <CloseIcon onClick={() => setSelectedId(null)} />
        ) : null}
        {!hasLink && isSuper ? (
          <SmallLink
            style={{ alignSelf: 'flex-end', marginBottom: 10 }}
            onClick={() => setIsEditing(true)}>
            Edit Asset
          </SmallLink>
        ) : null}
        <HeaderInfo>
          <HeaderComponent
            to={hasLink ? `/asset/${id}` : undefined}
            title={`Asset ${id}: ${name}`}>
            <PoleName>
              {name} {has_camera && <CameraIcon />}
            </PoleName>
            <PoleID>#{id}</PoleID>
          </HeaderComponent>
        </HeaderInfo>
        <HeaderInfo style={{ minHeight: 50 }}>
          <Status status={status} />
        </HeaderInfo>

        <HeaderInfo style={{ minHeight: 72 }}>
          <SectionHeader>Image Gallery</SectionHeader>
          <Row>
            {!!images?.length &&
              (imageView === 'edit' ? (
                <SmallLink onClick={() => setImageView('gallery')}>
                  Done
                </SmallLink>
              ) : (
                <SmallLink onClick={() => setImageView('edit')}>Edit</SmallLink>
              ))}
            <ActionMenu
              title="Add"
              onToggle={() => setPhotoActionsOpen(!photoActionsOpen)}
              isVisible={photoActionsOpen}>
              {has_camera && (
                <ActionMenuItem
                  onClick={() => {
                    setImageView('request');
                    setPhotoActionsOpen(false);
                  }}>
                  Request Photo
                </ActionMenuItem>
              )}
              <ActionMenuItem
                onClick={() => {
                  setImageView('upload');
                  setPhotoActionsOpen(false);
                }}>
                Upload Photo
              </ActionMenuItem>
            </ActionMenu>
          </Row>
        </HeaderInfo>

        {imageView === 'gallery' && <ThumbnailGroup images={images} />}
        {imageView === 'upload' && (
          <PhotoUpload id={id} onClose={() => setImageView('gallery')} />
        )}
        {imageView === 'request' && (
          <PhotoRequest
            onClick={() => setImageView('gallery')}
            onClickSmall={() => {
              requestImage('small');
              setImageView('gallery');
            }}
            onClickLarge={() => {
              requestImage('large');
              setImageView('gallery');
            }}
          />
        )}
        {imageView === 'edit' && (
          <ThumbnailEdit
            assetId={id}
            images={images}
            onClose={() => setImageView('gallery')}
          />
        )}

        <SectionHeader>Channel Data</SectionHeader>
        <ChannelContainer>
          {measurements ? (
            measurementsArr.length ? (
              measurementsArr.map((m: any) => {
                if (
                  m.name !== CHANNEL_WIND_SPEED &&
                  m.name !== CHANNEL_WIND_DIRECTION
                ) {
                  return (
                    <ChannelCard
                      key={m.name}
                      onClick={() => setSelectedMeasurementName(m.name)}
                      {...m}
                    />
                  );
                }
              })
            ) : (
              <EmptyContainer>
                <img
                  src={EmptyImage}
                  alt="No Channel Data"
                  width={75}
                  height={75}
                />
                <Label>No Channel Data</Label>
              </EmptyContainer>
            )
          ) : (
            'Loading...'
          )}
        </ChannelContainer>

        <HeaderInfo style={{ minHeight: 72 }}>
          <SectionHeader>Thresholds</SectionHeader>
          <ActionButton
            onClick={() => setSelectedThreshold({ asset_id: id } as Threshold)}
          />
        </HeaderInfo>
        {thresholds ? (
          thresholds?.length ? (
            thresholds?.map((t) => (
              <ClickableDetailRow
                key={t.id}
                onClick={() => setSelectedThreshold(t)}>
                <Row>
                  <Icon icon={MEASUREMENT_ICON_NAMES[t.channel_name]} />
                  <div style={{ marginLeft: 12 }}>
                    <H3>{t.channel_name}</H3>
                    <ThresholdInfo>{t.severity}</ThresholdInfo>
                  </div>
                </Row>
                <RightDetail style={{ textAlign: 'right' }}>
                  {t.threshold} {t.unit}
                  <div>
                    <ThresholdInfo>{t.type}</ThresholdInfo>
                  </div>
                </RightDetail>
              </ClickableDetailRow>
            ))
          ) : (
            <Label>No Thresholds</Label>
          )
        ) : (
          'Loading...'
        )}

        <SectionHeader>Details</SectionHeader>
        <DetailContainer>
          {custom_id ? (
            <DetailRow>
              <LeftDetail>{customIdLabel}</LeftDetail>
              <Divider />
              <RightDetail>{custom_id}</RightDetail>
            </DetailRow>
          ) : null}
          {conductor_type ? (
            <DetailRow>
              <LeftDetail>Conductor Type</LeftDetail>
              <Divider />
              <RightDetail>{conductor_type}</RightDetail>
            </DetailRow>
          ) : null}
          {coordinatesVisible ? (
            <React.Fragment>
              <DetailRow>
                <LeftDetail>Latitude</LeftDetail>
                <Divider />
                <RightDetail>{latitude}</RightDetail>
              </DetailRow>
              <DetailRow>
                <LeftDetail>Longitude</LeftDetail>
                <Divider />
                <RightDetail>{longitude}</RightDetail>
              </DetailRow>
            </React.Fragment>
          ) : null}
          <DetailRow>
            <LeftDetail>Classification</LeftDetail>
            <Divider />
            <RightDetail>{capitalize(classification)}</RightDetail>
          </DetailRow>
          <DetailRow>
            <LeftDetail>Status</LeftDetail>
            <Divider />
            <RightDetail>{capitalize(status)}</RightDetail>
          </DetailRow>
          <DetailRow>
            <LeftDetail>Connection</LeftDetail>
            <Divider />
            <RightDetail>{online ? 'Online' : 'Offline'}</RightDetail>
          </DetailRow>
          <DetailRow>
            <LeftDetail>Type</LeftDetail>
            <Divider />
            <RightDetail>{type}</RightDetail>
          </DetailRow>
        </DetailContainer>
      </Container>
    </>
  );
};
