import { ChannelName } from '@awareness/util';

export type AssetStatus =
  | 'healthy'
  | 'predictive'
  | 'potential'
  | 'critical'
  | 'tilting'
  | 'smoke'
  | 'gas'
  | 'vegetation'
  | 'pressure'
  | 'arcing'
  | 'temperature'
  | 'online'
  | 'offline'
  | 'charging'
  | 'discharging';

export interface Asset {
  online: boolean;
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  classification: Classification;
  status: AssetStatus;
  thingsquare_id: string;
  type: string;
  has_camera: boolean;
  has_gateway: boolean;
  custom_id: string | null;
  conductor_type: string | null;
  measurements?: { [key: string]: ChannelMeasurement };
  images?: AssetImage[];
  thresholds?: Threshold[];
}

export interface AssetImage {
  id: number;
  url: string;
  description?: string;
  exif_data?: {
    exif: {
      datetime: string;
      lens_model: string;
    };
    gps: {
      longitude: number;
      latitude: number;
      altitude: number;
    };
    resolution_units: string;
    x_resolution: number;
    y_resolution: number;
  };
}

export interface AssetGeoJSON {
  type: 'Feature';
  properties: Asset;
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
}

export type AssetState = { [key: number]: Asset };

export interface ChannelListItem {
  name: ChannelName;
  unit: string;
}

export type ChannelList = ChannelListItem[];

export interface Measurement {
  value: string;
  consumed_at?: string;
  timestamp: string;
}

export interface APIThreshold {
  id: number;
  asset_id: number;
  channel_name: ChannelName;
  severity: AssetStatus;
  threshold: number;
  type: 'maximum' | 'minimum';
  description: string;
}

export interface Threshold extends APIThreshold {
  unit: string;
}

export interface ChannelMeasurement extends ChannelListItem, Measurement {}

export const CLASSIFICATIONS = [
  {
    id: 'power',
    name: 'Power Pole',
  },
  {
    id: 'light',
    name: 'Light Pole',
  },
  {
    id: 'bridge',
    name: 'Bridge',
  },
  {
    id: 'gas',
    name: 'Oil/Gas',
  },
  {
    id: 'pipeline',
    name: 'Pipeline',
  },
  {
    id: 'wind',
    name: 'Wind Turbine',
  },
  {
    id: 'other',
    name: 'Other',
  },
] as const;

export type Classification = typeof CLASSIFICATIONS[number]['id'];
