export const getTimeSinceTimestamp = (timestamp: string, now: Date) => {
  const timestampDate = new Date(timestamp);
  const timeSince = Math.floor(
    (now.getTime() - timestampDate.getTime()) / 1000
  );

  let str = '';
  if (timeSince > YEAR) {
    str = Math.floor(timeSince / YEAR) + ' years ago';
  } else if (timeSince > DAY) {
    str = Math.floor(timeSince / DAY) + ' days ago';
  } else if (timeSince > HOUR) {
    str = Math.floor(timeSince / HOUR) + ' hours ago';
  } else if (timeSince > MINUTE) {
    str = Math.floor(timeSince / MINUTE) + ' minutes ago';
  } else {
    str = timeSince + ' seconds ago';
  }
  return str;
};

const YEAR = 31536000;
const DAY = 86400;
const HOUR = 3600;
const MINUTE = 60;

const MONTH_ABBREVIATIONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function formatDate(d: Date) {
  const day = d.getDate();
  const month = MONTH_ABBREVIATIONS[d.getMonth()];
  const year = d.getFullYear();
  const dayStr = day < 10 ? `0${day}` : day;
  return `${dayStr} ${month} ${year}`;
}
