import styled, { css } from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';
import { H3 } from '../Typography';

export const TableLayout = styled.table<{ disabled?: boolean }>`
  display: table;
  flex-direction: column;
  margin: ${toRem(16)} 1px;
  border-collapse: collapse;
  ${({ disabled }) =>
    disabled
      ? `
      opacity: 0.6;
      pointer-events: none;`
      : ''}
`;

export const TableHead = styled.thead`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @media (min-width: ${theme.breakpoint.med}px) {
    display: table-row-group;
  }
`;

export const TableHeaderRow = styled.tr`
  display: none;
  width: 100%;
  border-bottom: ${toRem(1)} solid ${theme.color.border.medium};

  @media (min-width: ${theme.breakpoint.med}px) {
    display: table-row;
    vertical-align: top;
    height: ${toRem(27)};
    padding: ${toRem(2)} ${toRem(22)} 0;
  }
`;

interface HeaderCellProps {
  align?: 'center' | 'left' | 'right';
  type?: 'actions' | 'default';
  value?: string;
  isSorted?: boolean;
}

export const TableHeaderCell = styled.th<HeaderCellProps>`
  position: relative;
  padding: 0 ${toRem(22)};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${toRem(11)};
  line-height: 100%;
  text-align: left;
  color: ${theme.color.text[88]};

  ${({ align }) =>
    align === 'center' &&
    css`
      text-align: center;

      > * {
        margin: 0 auto;
      }
    `}

  ${({ align }) =>
    align === 'right' &&
    css`
      text-align: right;

      > * {
        margin: 0 0 0 auto;
      }
    `}
  
    ${({ type }) =>
    type === 'actions' &&
    css`
      width: ${toRem(33)};
    `}
  
      ${({ isSorted }) =>
    isSorted &&
    css`
      transform: ${({ align }: HeaderCellProps) =>
        align === 'center' || align === 'right'
          ? `translateX(${toRem(-theme.table.label.offset / 2)})`
          : 'none'};
    `}
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @media (min-width: ${theme.breakpoint.med}px) {
    display: table-row-group;
  }
`;

export const MobileList = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ disabled }) =>
    disabled
      ? `
  opacity: 0.6;
  pointer-events: none;
  `
      : ''}
`;

export const TableMainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: ${theme.breakpoint.med}px) {
    flex-direction: row;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 65px;
`;

export const TablePageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.medium};
  padding: 0 ${toRem(16)};
  min-height: 124px;

  @media (min-width: ${theme.breakpoint.med}px) {
    flex-direction: row;
    padding: 0 ${toRem(32)};
  }
`;

export const TablePageLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${toRem(20)} 0 ${toRem(10)};

  @media (min-width: ${theme.breakpoint.med}px) {
    padding: ${toRem(40)} 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: ${toRem(20)} 0;
  }
`;

export const TableSubHeading = styled(H3)`
  font-weight: ${theme.font.weight.regular};
  margin-bottom: ${toRem(10)};
`;
