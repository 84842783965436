import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { logout } from '@awareness/logout';
import { theme, toRem } from '@awareness-ui/design';
import { patchDeveloperSettings } from '@awareness/developer-settings';
import NavItem from './NavItem';
import { Icon, IconTypes } from '../Icon';
import { LogoMark } from '../Logo';
import { Subtext } from '../Typography';
import { getUserRoles, getIsAdmin } from '@awareness/user';

interface NavigationItem {
  label: string;
  icon: IconTypes;
  iconActive: IconTypes;
  to: string;
}

const navigationItems: Array<NavigationItem> = [
  {
    label: 'Map View',
    icon: 'SiteOutline',
    iconActive: 'Site',
    to: '/',
  },
  {
    label: 'List View',
    icon: 'List',
    iconActive: 'List',
    to: '/list',
  },
  {
    label: 'View Reports',
    icon: 'ReportOutline',
    iconActive: 'Report',
    to: '/reports',
  },
  {
    label: 'Manage Users',
    icon: 'PeopleOutline',
    iconActive: 'People',
    to: '/users',
  },
  {
    label: 'Manage Groups',
    icon: 'Group',
    iconActive: 'Group',
    to: '/group',
  },
];

const Layout = styled.nav.attrs({
  role: 'navigation',
})<{ isOpen: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  z-index: ${theme.zIndex.header + 10};
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${toRem(11)} ${toRem(11)} 0;
  background-color: ${theme.color.secondary[100]};
  overflow: ${({ isOpen }) => (isOpen ? 'hidden' : 'scroll')};
  transition: left ${theme.transition.nav};

  @media (min-width: ${theme.breakpoint.small}px) {
    position: static;
    z-index: ${theme.zIndex.nav};
    flex-shrink: 0;
    align-items: flex-start;
    width: ${toRem(theme.nav.width.closed)};
    overflow: hidden;
    transition: width ${theme.transition.nav};

    ${({ isOpen }) =>
      isOpen &&
      css`
        width: ${toRem(theme.nav.width.open)};
        padding: ${toRem(11)} ${toRem(theme.layout.gutter.small)} 0;
      `}
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${toRem(11)};
  width: 100%;
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${toRem(22)};
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  margin: ${toRem(3)} 0;
`;

const StyledLogoMark = styled(LogoMark)<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'inline-block' : 'none')};
`;

const MenuButton = styled.button<{ isOpen: boolean }>`
  transition: background-color ${theme.transition.button.default};
  border-radius: ${toRem(11)};
  width: ${toRem(44)};
  padding: ${toRem(8)} 0;

  &:hover {
    background-color: ${theme.color.secondary[88]};
  }
`;

const LogoutButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? 'flex-start' : 'center')};
  width: ${({ isOpen }) => (isOpen ? '100%' : toRem(44))};
  padding: ${toRem(2)} 0;
`;

const LogoutText = styled.div`
  color: ${theme.color.white};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  margin-left: ${toRem(4)};
`;

const Version = styled(Subtext)`
  margin-left: 2px;
`;

interface NavProps {
  navOpen: boolean;
  setNavOpen: (o: boolean) => void;
}

const Nav: React.FC<NavProps> = ({ navOpen, setNavOpen }) => {
  const location = useLocation();
  const currentPage = location.pathname;
  const roles = useSelector(getUserRoles);
  const isAdmin = getIsAdmin(roles);

  const dispatch = useDispatch();

  const [hiddenSettingsCount, setHiddenSettingsCount] = useState(0);

  return (
    <Layout isOpen={navOpen}>
      <Top>
        <TopMenu>
          <MenuButton
            isOpen={navOpen}
            onClick={() => {
              setNavOpen(!navOpen);
            }}>
            <StyledIcon
              icon="Menu"
              fill={theme.color.white}
              width={16.5}
              height={16.5}
            />
          </MenuButton>
          <StyledLogoMark fill={theme.color.white} isOpen={navOpen} />
        </TopMenu>
        {(isAdmin ? navigationItems : navigationItems.slice(0, 2)).map(
          (item) => {
            return (
              <NavItem
                key={item.label}
                label={item.label}
                icon={item.icon}
                iconActive={item.iconActive}
                itemActive={
                  item.to === '/'
                    ? currentPage === item.to
                    : currentPage.search(item.to) === 0
                }
                navOpen={navOpen}
                to={item.to}
                onClick={
                  window.screenX < theme.breakpoint.small
                    ? () => setNavOpen(false)
                    : undefined
                }
              />
            );
          }
        )}
      </Top>
      <Bottom>
        <LogoutButton isOpen={navOpen} onClick={() => dispatch(logout())}>
          <StyledIcon
            icon="Logout"
            fill={theme.color.white}
            width={16}
            height={16}
          />
          {navOpen ? <LogoutText>Logout</LogoutText> : null}
        </LogoutButton>
        {navOpen ? (
          <Version
            role="text"
            title={`Updated at ${process.env.REACT_APP_TIMESTAMP || ''}`}
            onClick={() => {
              if (hiddenSettingsCount < 4) {
                setHiddenSettingsCount(hiddenSettingsCount + 1);
                return;
              }
              dispatch(patchDeveloperSettings({ modalOpen: true }));
              setHiddenSettingsCount(0);
            }}>
            {process.env.REACT_APP_TAG || ''}
          </Version>
        ) : null}
      </Bottom>
    </Layout>
  );
};

export default Nav;
