import React from 'react';
import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';
import { Icon, IconTypes } from '../Icon';

interface ToggleProps {
  onClick: () => void;
  icon: IconTypes;
}

const Layout = styled.button`
  position: absolute;
  right: 10px;
  top: 108px;
  padding-top: 2px;
  justify-content: center;
  align-items: center;
  width: ${toRem(30)};
  height: ${toRem(30)};
  z-index: 1;
  border-radius: ${toRem(6)};
  background-color: ${theme.color.white};
  transition: background-color ${theme.transition.button.default};
  box-shadow: ${theme.shadow.mapcontrols};
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.background.medium};
  }
`;

const MapToggle: React.FC<ToggleProps> = ({ onClick, icon }) => {
  return (
    <Layout onClick={onClick}>
      <Icon width={17} height={17} icon={icon} />
    </Layout>
  );
};

export default MapToggle;
