import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';
import { Label } from '../Typography';

const ActionMenuItem = styled(Label)`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${toRem(theme.dropdown.item.height.desktop)};
  margin-bottom: ${toRem(5.5)};
  padding: 0 ${toRem(11)};
  border-radius: ${toRem(5.5)};
  border: ${toRem(1)} solid transparent;
  background-color: transparent;
  cursor: pointer;
  transition: background-color ${theme.transition.button.default};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${theme.color.hover};
  }

  &:focus {
    border-color: rgba(0, 0, 0, 0.055);
    outline: none;
  }
`;

export default ActionMenuItem;
