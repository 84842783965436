import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { Asset } from '@awareness/types';
import { getFiltersQueryParams } from '@awareness/assets';
import { apiFetchAsync } from '@awareness/api-fetch';
import { theme } from '@awareness-ui/design';
import {
  Button,
  H1,
  H3,
  Subtext,
  TableMainView,
  TableContainer,
  MobileList,
  AssetMobileListItem,
  TableLayout,
  TableHead,
  TableBody,
  TableHeaderRow,
  TableHeaderCell,
  SortLabel,
  AssetListItem,
  Pagination,
  Icon,
  StatusDot,
  Steps,
} from '@awareness-ui/components';
import { useWindowSize } from '@awareness-ui/hooks';
import { ChannelName } from '@awareness/util';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const SelectedView = styled.div`
  display: flex;
  width: 0;
  height: 0;
  flex-direction: column;
  background-color: ${theme.color.white};
  border-left: 1px solid ${theme.color.border.medium};
  transition: ${theme.transition.nav};
  position: relative;
  z-index: ${theme.zIndex.sideview};
  overflow: visible;
  margin-bottom: ${theme.header.height}px;
  border-width: 1px;
  padding: 24px 16px;

  @media (max-width: ${theme.breakpoint.small}px) {
    min-width: 100%;
    min-height: 65%;
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    margin-top: -10px;
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoint.small}px) {
    min-width: ${theme.map.detailView.medium}px;
    height: auto;
  }

  @media (min-width: ${theme.breakpoint.large}px) {
    min-width: ${theme.map.detailView.large}px;
    height: auto;
  }
`;

const SelectedListItem = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 5px;
  background-color: ${theme.color.background.light};
  margin-bottom: 10px;
  height: 50px;
  padding: 10px;
`;

const Name = styled(H3)`
  font-weight: ${theme.font.weight.medium};
`;

const CloseIcon = styled(Icon).attrs({ icon: 'Close' })`
  cursor: pointer;
  box-sizing: content-box;
  align-self: flex-end;
  min-width: 16px;
  min-height: 16px;
  border-radius: 16px;
  padding: 6px;
  background-color: ${theme.color.background.medium};
  margin-left: 16px;
`;

const SELECTION_LIMIT = 7;

interface Props extends RouteChildrenProps {}

export const ReportAssetSelection: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [selectedIds, setSelectedIds] = useState<Map<number, Asset>>(new Map());
  const [primaryChannel, setPrimaryChannel] = useState<ChannelName>();
  const [secondaryChannel, setSecondaryChannel] = useState<ChannelName>();

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;
  const channelsStr: string = (props.match?.params || ({} as any)).channel;

  useEffect(() => {
    const [primary, secondary] = channelsStr.split(',') as ChannelName[];
    setPrimaryChannel(primary);
    if (secondary) setSecondaryChannel(secondary);
    fetchAssets();
  }, [pageSize, nextPageNumber]);

  const fetchAssets = async () => {
    setLoading(true);

    const queryParams = getFiltersQueryParams([], pageSize, nextPageNumber);
    const url = `${apiEndpoint}/client/assets${queryParams}&channel_name=${primaryChannel}`;

    try {
      const response = await apiFetchAsync({ url, token });
      const json: Asset[] = await response.json();

      if (response.status !== 200 || !Array.isArray(json)) {
        throw new Error('Uh-oh! There was an error loading assets.');
      }

      setAssets(json);

      if (!json.length) {
        toast('0 assets match your search', { title: 'No Assets' } as any);
      }
    } catch (err) {
      toast.error(`${err}`, {
        actionLabel: 'Try Again',
        onAction: fetchAssets,
      } as any);
    }

    setLoading(false);
    setPageNumber(nextPageNumber);
  };

  const toggleSelectedId = (asset: Asset) => {
    const ids = new Map(selectedIds);
    if (ids.get(asset.id)) {
      ids.delete(asset.id);
      setSelectedIds(ids);
      return;
    }
    if (ids.size >= SELECTION_LIMIT) {
      toast.error('Maximum number of assets selected');
      return;
    }
    const search = assets.find((a) => a === asset);
    if (search) {
      ids.set(search.id, search);
      setSelectedIds(ids);
    }
  };

  const pageCount =
    (assets.length >= pageSize ? pageNumber + 1 : pageNumber) + 1;
  const noAssetsMessage = loading
    ? 'Loading...'
    : 'No assets match your search. Try going back and choosing a different channel.';
  const uriIds = Array.from(selectedIds.keys()).join(',');
  const nextPageUri = `/reports/${channelsStr}/${uriIds}${
    secondaryChannel ? '/none' : '/-'
  }`;
  const STEPCOUNT = secondaryChannel ? 4 : 3;
  return (
    <MainView>
      <Steps
        type="report"
        count={STEPCOUNT}
        index={1}
        channel={primaryChannel}
      />
      <TableMainView>
        <TableContainer style={isMobile ? { marginBottom: 0 } : undefined}>
          <>
            {assets.length === 0 ? (
              noAssetsMessage
            ) : isMobile ? (
              <MobileList disabled={loading}>
                {assets.map((a) => {
                  return (
                    <AssetMobileListItem
                      {...a}
                      key={a.id}
                      onClick={() => toggleSelectedId(a)}
                      disabled={selectedIds.has(a.id)}
                    />
                  );
                })}
              </MobileList>
            ) : (
              <TableLayout disabled={loading}>
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <SortLabel label="Asset Name" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Status" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Connection" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Type" />
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {assets.map((a) => (
                    <AssetListItem
                      key={a.id}
                      {...a}
                      onClick={() => toggleSelectedId(a)}
                      disabled={selectedIds.has(a.id)}
                    />
                  ))}
                </TableBody>
              </TableLayout>
            )}
            <Pagination
              pageCount={pageCount}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setNextPageNumber}
            />
          </>
        </TableContainer>
        <SelectedView>
          <H1>Choose Assets</H1>
          <Subtext style={{ marginBottom: 32 }}>
            Choose up to 7 assets to compare {primaryChannel} over time.
          </Subtext>
          <Subtext style={{ marginBottom: 16 }}>
            {selectedIds.size
              ? `Selected assets (${selectedIds.size})`
              : `No assets selected`}
          </Subtext>
          {Array.from(selectedIds.values()).map((asset) => {
            return (
              <SelectedListItem key={asset.id}>
                {asset && <StatusDot status={asset?.status} />}
                <Name>{asset?.name || ''}</Name>
                <CloseIcon onClick={() => toggleSelectedId(asset)} />
              </SelectedListItem>
            );
          })}
          <Button
            label="Next"
            disabled={selectedIds.size === 0}
            to={selectedIds.size ? nextPageUri : undefined}
          />
        </SelectedView>
      </TableMainView>
    </MainView>
  );
};
