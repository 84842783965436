import React, { ReactNode, SelectHTMLAttributes } from 'react';
import styled from 'styled-components';

interface Option {
  label: string;
  value: string;
}

interface SelectProps {
  options: Option[];
  onChange: (value: string) => void;
}

const Select: React.FC<SelectProps> = ({ options = [], onChange }) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  return (
    <select
      style={{
        position: 'absolute',
        width: '60px',
        right: '10px',
        bottom: '10px',
      }}
      onChange={handleSelectChange}
      onClick={(e: React.MouseEvent<HTMLSelectElement>) => e.stopPropagation()}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
