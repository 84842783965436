import React from 'react';

import styled, { css } from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';

interface ModalCloseButtonProps {
  sticky?: boolean;
  onClick?: () => void;
}

const Layout = styled.div<Partial<ModalCloseButtonProps>>`
  display: flex;
  position: absolute;
  top: ${toRem(22)};
  right: ${toRem(22)};
  z-index: ${theme.zIndex.modal};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0;
  background: ${theme.color.background.medium};
  border-radius: ${toRem(16)};
  width: ${toRem(32)};
  height: ${toRem(32)};
  cursor: pointer;

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: ${toRem(0)};
      right: ${toRem(0)};
    `}
`;

const ModalCloseButton: React.FC<ModalCloseButtonProps> = ({
  sticky,
  onClick,
}) => {
  return (
    <Layout sticky={sticky} onClick={onClick}>
      <Icon icon="Close" height={16} width={16} />
    </Layout>
  );
};

export default ModalCloseButton;
