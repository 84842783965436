import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Button,
  FieldWrapper,
  FormError,
  H1,
  Input,
  LayoutSplit,
  Logo,
  PasswordInput,
  Text,
} from '@awareness-ui/components';
import { theme, toRem } from '@awareness-ui/design';
import { login } from '@awareness/auth';
//import { getEmailError, getPasswordError } from '@awareness/util';
import RightImage from '@awareness-ui/images/login-hero.jpg';
import { patchDeveloperSettings } from '@awareness/developer-settings';

const AuthContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${toRem(theme.spacing.large)} 0;
  align-items: center;
  text-align: center;
  flex-flow: column nowrap;
`;

const LoadingModal = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.background.dark};
  opacity: 0.6;
  z-index: 1;
  align-items: center;
  justify-content: center;
  color: ${theme.color.text[0]};
`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  flex-flow: column nowrap;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: ${toRem(66)};
`;

const AuthForm = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const AuthFieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
`;

interface Props extends RouteChildrenProps {}

export const Login: React.FC<Props> = ({ history }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hiddenSettingsCount, setHiddenSettingsCount] = useState(0);

  const onSubmit = (event: React.MouseEvent) => {
    event.preventDefault();

    /*setSubmitted(true);
    if (getEmailError(email) || getPasswordError(password)) {
      return;
    }*/

    setLoading(true);
    dispatch(login({ email, password, onError, onSuccess }));
  };

  const onSuccess = () => {
    history.push('/');
  };

  const onError = (e: string) => {
    setLoading(false);
    setErrorMessage(e);
    setSubmitted(true);
  };

  //const emailError = submitted ? getEmailError(email) : undefined;
  //const passwordError = submitted ? getPasswordError(password) : undefined;

  return (
    <LayoutSplit
      left={
        <AuthContainer>
          {loading ? <LoadingModal>Loading...</LoadingModal> : null}
          <HeaderContainer>
            <StyledLogo
              onClick={() => {
                if (hiddenSettingsCount < 4) {
                  setHiddenSettingsCount(hiddenSettingsCount + 1);
                  return;
                }
                dispatch(patchDeveloperSettings({ modalOpen: true }));
                setHiddenSettingsCount(0);
              }}
            />
            <H1>Welcome Back</H1>
            <Text>Log into your Hayden Data account</Text>
          </HeaderContainer>
          <AuthForm onSubmit={onSubmit as any}>
            <AuthFieldset>
              <FieldWrapper label="Email">
                <Input
                  type="email"
                  placeholder="you@email.com"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </FieldWrapper>
              <FieldWrapper
                label="Password"
                action="Forgot password?"
                onAction={() => history.push('/reset-password')}>
                <PasswordInput
                  onChange={(e) => setPassword(e.currentTarget.value)}
                />
              </FieldWrapper>
            </AuthFieldset>
            {errorMessage && <FormError>{errorMessage}</FormError>}
            <Button
              label="Log In"
              type="submit"
              appearance="primary"
              size="large"
              onClick={onSubmit}
            />
          </AuthForm>
        </AuthContainer>
      }
      right={<HeroImage src={RightImage} alt="Map Image" />}
    />
  );
};
