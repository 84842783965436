import React from 'react';

import { theme, toRem } from '@awareness-ui/design';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import ModalCloseButton from './ModalCloseButton';
import ModalOverlay from './ModalOverlay';

interface ModalProps {
  isActive: boolean;
  onClose: () => void;
  sticky?: boolean;
  maxWidth?: number;
  style?: any;
}

const Container = styled(motion.div).attrs({
  'aria-hidden': 'true',
})<Partial<ModalProps>>`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme.zIndex.modal};
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${toRem(theme.layout.gutter.small)};
  background-color: transparent;
  overflow: scroll;

  @media (min-width: ${theme.breakpoint.med}px) {
    padding: 0;
  }

  ${({ sticky }) =>
    sticky &&
    css`
      justify-content: flex-start;

      @media (min-width: ${theme.breakpoint.med}px) {
        justify-content: center;
        padding: 0;
        overflow: hidden;
      }
    `}
`;

const Layout = styled.section<Partial<ModalProps>>`
  display: flex;
  position: relative;
  z-index: ${theme.zIndex.modal + 2};
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? toRem(maxWidth) : toRem(420))};
  max-height: 96%;
  overflow: scroll;
  margin: ${toRem(theme.layout.gutter.large)} 0;
  padding: 0 ${toRem(theme.layout.gutter.small)};
  border-radius: ${toRem(22)};
  background-color: ${theme.color.white};
  box-shadow: ${theme.shadow.modal};

  @media (min-width: ${theme.breakpoint.med}px) {
    padding: 0 ${toRem(24)};
    margin-left: ${toRem(60)};
  }

  ${({ sticky }) =>
    sticky &&
    css`
      height: auto;
      max-height: 100%;
      margin: 0;
      overflow: hidden;

      @media (min-width: ${theme.breakpoint.med}px) {
        max-height: ${toRem(600)};
        margin: 0;
        padding: 0 ${toRem(24)};
      }
    `}
`;

const Modal: React.FC<ModalProps> = ({
  isActive,
  onClose,
  sticky,
  maxWidth,
  children,
  ...props
}) => {
  return (
    <AnimatePresence>
      {isActive && (
        <Container
          sticky={sticky}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.2 }}>
          <Layout sticky={sticky} maxWidth={maxWidth} {...props}>
            <ModalCloseButton sticky={sticky} onClick={onClose} />
            {children}
          </Layout>
          <ModalOverlay onClick={onClose} />
        </Container>
      )}
    </AnimatePresence>
  );
};

export default Modal;
