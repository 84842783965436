import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router-dom';
import toast from 'react-hot-toast';

import { theme } from '@awareness-ui/design';
import { getFiltersQueryParams } from '@awareness/assets';
import {
  AssetDetail,
  Filters,
  H1,
  Search,
  MobileList,
  Pagination,
  TableMainView,
  TablePageLeft,
  TableHead,
  TableLayout,
  TableBody,
  TableHeaderRow,
  TableHeaderCell,
  AssetMobileListItem,
  SortLabel,
  TableContainer,
  TablePageHeader,
  TableSubHeading,
  NewAssetModal,
  Button,
} from '@awareness-ui/components';
import { useWindowSize } from '@awareness-ui/hooks';
import { Asset, AssetStatus } from '@awareness/types';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { AssetListItem } from '@awareness-ui/components';
import { getIsSuperAdmin, getUserRoles } from '@awareness/user';

const DetailView = styled.div<{ isOpen: boolean }>`
  display: flex;
  width: 0;
  height: 0;
  flex-direction: column;
  background-color: ${theme.color.white};
  border-left: 1px solid ${theme.color.border.medium};
  transition: ${theme.transition.nav};
  position: relative;
  z-index: ${theme.zIndex.sideview};
  overflow: visible;
  margin-bottom: ${theme.header.height}px;
  border-width: ${({ isOpen }) => (isOpen ? '1px' : 0)};

  @media (max-width: ${theme.breakpoint.small}px) {
    min-width: 100%;
    min-height: ${({ isOpen }) => (isOpen ? '65%' : 0)};
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    margin-top: -10px;
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoint.small}px) {
    min-width: ${({ isOpen }) => (isOpen ? theme.map.detailView.medium : 0)}px;
    height: auto;
  }

  @media (min-width: ${theme.breakpoint.large}px) {
    min-width: ${({ isOpen }) => (isOpen ? theme.map.detailView.large : 0)}px;
    height: auto;
  }
`;

interface Props extends RouteChildrenProps {}

export const AssetList: React.FC<Props> = ({ history }) => {
  const [selectedId, setSelectedId] = useState<null | number>(null);
  const [filters, setFilters] = useState<AssetStatus[]>([]);
  const [loading, setLoading] = useState(true);
  const [addingAsset, setAddingAsset] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [assets, setAssets] = useState<Asset[]>([]);

  const roles = useSelector(getUserRoles);
  const isSuper = getIsSuperAdmin(roles);
  const [useSuperEndpoint, setUseSuperEndpoint] = useState(isSuper);
  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;

  useEffect(() => {
    fetchAssets();
  }, [filters, pageSize, nextPageNumber, useSuperEndpoint]);

  const fetchAssets = async () => {
    setLoading(true);

    const queryParams = getFiltersQueryParams(
      filters,
      pageSize,
      nextPageNumber
    );
    const url = useSuperEndpoint
      ? `${apiEndpoint}/admin/assets${queryParams}`
      : `${apiEndpoint}/assets/filter${queryParams}`;

    try {
      const response = await apiFetchAsync({ url, token });
      const json: Asset[] = await response.json();

      if (response.status !== 200 || !Array.isArray(json)) {
        throw new Error('Uh-oh! There was an error loading assets.');
      }

      setAssets(json);

      if (!json.length) {
        toast('0 assets match your search', { title: 'No Assets' } as any);
      }
    } catch (err) {
      toast.error(`${err}`, {
        actionLabel: 'Try Again',
        onAction: fetchAssets,
      } as any);
    }

    setLoading(false);
    setPageNumber(nextPageNumber);
  };

  const pageCount =
    (assets.length >= pageSize ? pageNumber + 1 : pageNumber) + 1;

  const goToAsset = useCallback(
    (id: number) => history.push(`/asset/${id}`),
    []
  );

  const toggleFilter = (filter: AssetStatus) => {
    const newFilters = filters.includes(filter)
      ? filters.filter((f) => f !== filter)
      : [...filters, filter];
    setFilters(newFilters);
  };

  const clearFilters = useCallback(() => setFilters([]), []);

  const noAssetsMessage = loading ? 'Loading...' : 'No Assets';

  return (
    <TableMainView>
      {addingAsset && (
        <NewAssetModal
          onClose={() => setAddingAsset(false)}
          goToAsset={goToAsset}
        />
      )}
      <Filters
        toggleFilter={toggleFilter}
        clearFilters={clearFilters}
        filters={filters}
        positionAbsolute={false}
        setUseSuperEndpoint={isSuper && setUseSuperEndpoint}
        useSuperEndpoint={useSuperEndpoint}
      />
      <TableContainer>
        <TablePageHeader>
          <TablePageLeft>
            <H1>Asset List</H1>
            <TableSubHeading>Monitoring: {assets.length}</TableSubHeading>
          </TablePageLeft>
          <div>
            <Search />
            {isSuper && (
              <Button
                label="Add Asset"
                onClick={() => setAddingAsset(true)}
                size="small"
                style={{ margin: '10px 0', marginLeft: 'auto' }}
              />
            )}
          </div>
        </TablePageHeader>
        <>
          {assets.length === 0 ? (
            noAssetsMessage
          ) : isMobile ? (
            <MobileList disabled={loading}>
              {assets.map((a) => {
                return (
                  <AssetMobileListItem
                    {...a}
                    key={a.id}
                    onClick={() => goToAsset(a.id)}
                  />
                );
              })}
            </MobileList>
          ) : (
            <TableLayout disabled={loading}>
              <TableHead>
                <TableHeaderRow>
                  <TableHeaderCell>
                    <SortLabel label="Asset Name" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <SortLabel label="Status" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <SortLabel label="Connection" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <SortLabel label="Type" />
                  </TableHeaderCell>
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {assets.map((a) => (
                  <AssetListItem
                    key={a.id}
                    {...a}
                    onClick={
                      selectedId === a.id
                        ? () => setSelectedId(null)
                        : () => setSelectedId(a.id)
                    }
                  />
                ))}
              </TableBody>
            </TableLayout>
          )}
          <Pagination
            pageCount={pageCount}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setNextPageNumber}
          />
        </>
      </TableContainer>
      <DetailView isOpen={selectedId !== null}>
        <AssetDetail
          id={selectedId as any}
          setSelectedId={setSelectedId}
          hasLink={true}
        />
      </DetailView>
    </TableMainView>
  );
};
