import React, { InputHTMLAttributes } from 'react';

import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

import CheckBG from './image/checkbox-bg.svg';
import { Icon, IconTypes } from '../Icon';

interface CheckboxProps extends InputHTMLAttributes<any> {
  label?: any;
  name?: string;
  icon: IconTypes;
}

const Layout = styled.div<Partial<CheckboxProps>>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: ${toRem(6)} 0;

  input {
    width: ${toRem(18)};
    height: ${toRem(18)};
  }
`;

const Input = styled.input<Partial<CheckboxProps>>`
  appearance: none;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: ${toRem(8)};
  border-radius: ${toRem(2.75)};
  border: ${toRem(1)} solid ${theme.color.input.border.default};
  outline: none;
  background-color: ${theme.color.input.bg.default};
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.input.bg.hover};
  }

  &:focus {
    outline: none;
    border-color: ${theme.color.input.border.focus};
  }

  :checked {
    border-color: ${theme.color.primary[100]};
    background-color: ${theme.color.primary[100]};
    background-image: url(${CheckBG});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
  }
`;

const Label = styled.label<Partial<CheckboxProps>>`
  display: flex;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.medium};
  font-size: ${toRem(13)};
  line-height: 125%;
  color: ${theme.color.text[88]};
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  a {
    display: inline;
  }
`;

const Checkbox: React.FC<CheckboxProps> = ({ label, icon, ...props }) => {
  return (
    <Layout>
      <Label>
        <Input type="checkbox" {...props} />
        <Icon icon={icon} width={16} height={16} style={{ marginRight: 8 }} />
        {label && label}
      </Label>
    </Layout>
  );
};

export default Checkbox;
