import { createGlobalStyle } from 'styled-components';

import { theme } from '@awareness-ui/design';

const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
  }

  div#root {
    flex-direction: column;
    min-height: 100%;
    display: inline;
  }
  body {
    margin: 0;
    min-height: 100%;
    display: inline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    font-family: ${theme.font.family.inter};
    font-weight: ${theme.font.weight.regular};
    font-size: 16px;
    line-height: 100%;
    color: ${theme.color.text[100]};
  }

  form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  form, fieldset {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
  }

  input, select {
    width: 100%;
  }

  a {
    &:active,
    &:visited {
      color: inherit;
    }
  }

  button {
    appearance: none;
    width: auto;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  ul, ol {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyles;
