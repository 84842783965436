import React, { useCallback, useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { Alert, Threshold } from '@awareness/types';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getAlertsCount } from '@awareness/alerts';
import { useWindowSize } from '@awareness-ui/hooks';
import {
  AlertListItem,
  AlertMobileListItem,
  H1,
  MobileList,
  Pagination,
  SortLabel,
  TablePageLeft,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableHeaderRow,
  TableLayout,
  TableContainer,
  TableMainView,
  TablePageHeader,
  TableSubHeading,
  Modal,
  ModalContent,
  ThresholdView,
} from '@awareness-ui/components';
import { theme } from '@awareness-ui/design';

interface Props extends RouteChildrenProps {}

export const AlertList: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [selectedThreshold, setSelectedThreshold] = useState<Threshold>();

  const count = useSelector(getAlertsCount);
  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;

  useEffect(() => {
    fetchAlerts();
  }, [pageSize, pageNumber]);

  const fetchAlerts = async () => {
    setLoading(true);

    const url = `${apiEndpoint}/alarms`;

    try {
      const response = await apiFetchAsync({ url, token });
      const json: Alert[] = await response.json();

      if (response.status !== 200 || !Array.isArray(json)) {
        throw new Error('Uh-oh! There was an error loading alerts.');
      }

      setAlerts(json);
    } catch (err) {
      toast.error(`${err}`, {
        actionLabel: 'Try Again',
        onAction: fetchAlerts,
      } as any);
    }

    setLoading(false);
  };

  const pageCount = Math.ceil(count / pageSize);

  const goToAsset = useCallback(
    (id: number) => history.push(`/asset/${id}`),
    []
  );

  const noAlertsMessage = loading ? 'Loading...' : 'No Assets';
  const closeThresholdModal = useCallback(
    () => setSelectedThreshold(undefined),
    []
  );

  return (
    <>
      <Modal
        style={{ width: 'auto' }}
        maxWidth={900}
        isActive={selectedThreshold !== undefined}
        onClose={closeThresholdModal}>
        <ModalContent>
          {selectedThreshold && (
            <ThresholdView
              threshold={selectedThreshold}
              onClose={closeThresholdModal}
            />
          )}
        </ModalContent>
      </Modal>
      <TableMainView>
        <TableContainer style={isMobile ? undefined : { padding: 16 }}>
          {isMobile ? null : (
            <TablePageHeader>
              <TablePageLeft>
                <H1>Alert List</H1>
                <TableSubHeading>Monitoring: {count}</TableSubHeading>
              </TablePageLeft>
            </TablePageHeader>
          )}
          <>
            {alerts.length === 0 ? (
              noAlertsMessage
            ) : isMobile ? (
              <MobileList disabled={loading}>
                {alerts.map((a) => {
                  return (
                    <AlertMobileListItem
                      key={a.id}
                      {...a}
                      onClick={() => goToAsset(a.asset_id)}
                    />
                  );
                })}
              </MobileList>
            ) : (
              <TableLayout disabled={loading}>
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <SortLabel label="Alert Name" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Status" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Date" />
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {alerts.map((a) => (
                    <AlertListItem
                      key={a.id}
                      onClick={() => goToAsset(a.asset_id)}
                      {...a}
                    />
                  ))}
                </TableBody>
              </TableLayout>
            )}
            <Pagination
              pageCount={pageCount}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setPageNumber}
            />
          </>
        </TableContainer>
      </TableMainView>
    </>
  );
};
