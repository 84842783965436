import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { theme } from '@awareness-ui/design';
import {
  Button,
  H1,
  H3,
  ClientListItem,
  ClientMobileListItem,
  MobileList,
  SortLabel,
  TableBody,
  TableContainer,
  TableHead,
  TableHeaderCell,
  TableHeaderRow,
  TableLayout,
} from '@awareness-ui/components';
import { Client, User } from '@awareness/types';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getUser, setUser } from '@awareness/user';
import { useWindowSize } from '@awareness-ui/hooks';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const Header = styled.div`
  width: 100%;
  height: 150px;
  background: ${theme.color.background.light};
  padding: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${theme.color.border.medium};
`;

const Content = styled.div`
  padding: 55px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface Props extends RouteChildrenProps {}

export const Clients: React.FC<Props> = ({ history, location }) => {
  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);
  const user = useSelector(getUser);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    setLoading(true);

    const url = `${apiEndpoint}/admin/clients`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching clients.');
      }

      setClients(json);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const setUserClient = async (client: Client) => {
    const params = new URLSearchParams(location.search);
    const redirectUrl = params.get('redirect_to') || '/users';
    if (client.id === user.client?.id) {
      history.push(redirectUrl);
      return;
    }

    setLoading(true);

    const url = `${apiEndpoint}/admin/accounts/${user.id}`;
    const method = 'PUT';
    const body = new FormData();
    body.append('client_id', `${client.id}`);
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: User = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error changing your client.');
      }

      dispatch(setUser(json));
      history.push(redirectUrl);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <MainView>
        <Header />
        <Content>Loading...</Content>
      </MainView>
    );
  }

  return (
    <MainView>
      <Header>
        <H1>Choose a Client</H1>
      </Header>
      <Content>
        <Row>
          <H3>Companies ({clients.length})</H3>
          <Button
            label="+ Add Company"
            size="small"
            to="/users?new_client=true"
          />
        </Row>
        <TableContainer>
          {clients.length ? (
            isMobile ? (
              <MobileList>
                {clients.map((c) => {
                  return (
                    <ClientMobileListItem
                      key={c.id}
                      onClick={() => setUserClient(c)}
                      {...c}
                    />
                  );
                })}
              </MobileList>
            ) : (
              <TableLayout>
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <SortLabel label="Name" />
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {clients.map((c) => (
                    <ClientListItem
                      key={c.id}
                      onClick={() => setUserClient(c)}
                      selected={user.client?.id === c.id}
                      {...c}
                    />
                  ))}
                </TableBody>
              </TableLayout>
            )
          ) : (
            'No Clients'
          )}
        </TableContainer>
      </Content>
    </MainView>
  );
};
