import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { theme, toRem } from '@awareness-ui/design';
import {
  formatCellNumber,
  getCellNumberError,
  getEmailError,
  getRolesFormData,
} from '@awareness/util';
import { Account, User } from '@awareness/types';
import { getIsAdmin, getIsSuperAdmin, getUser } from '@awareness/user';

import { H2, Text } from '../Typography';
import { FieldWrapper, Input } from '../Inputs';
import { Button } from '../Button';
import Radio from '../Inputs/Radio';

interface AccountProps {
  client_id: number;
  account_id: number;
  onEditSuccess?: (a: Account) => void;
  onDeleteSuccess?: (account_id: number) => void;
  hideTitle?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.large}px;
  min-height: 50%;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeleteButton = styled(Text)`
  color: ${theme.color.error};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${toRem(12)};
  margin-top: 10px;
`;

export const EditAccount: React.FC<AccountProps> = ({
  account_id,
  onEditSuccess,
  onDeleteSuccess,
  client_id,
  hideTitle,
}) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [cell, setCell] = useState<string>('');
  const [roles, setRoles] = useState<string[]>([]);
  const [newRoles, setNewRoles] = useState<string[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>();

  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);
  const user = useSelector(getUser);
  const isSuper = getIsSuperAdmin(user.roles);

  const isNewAccount = account_id === undefined;

  useEffect(() => {
    if (!isNewAccount) {
      fetchAccount();
    }
  }, []);

  const fetchAccount = async () => {
    setLoading(true);
    const url = `${apiEndpoint}/client/accounts/${account_id}`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json: User = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching the account.');
      }
      setName(json.name || '');
      setEmail(json.email || '');
      setCell(json.cell_number || '');
      setRoles(json.roles || []);
      setNewRoles(json.roles || []);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const updateAccount = async (event: React.MouseEvent) => {
    event.preventDefault();
    const emailError = getEmailError(email);
    const cell_number = formatCellNumber(cell || '');
    const cellError = getCellNumberError(cell_number);
    if (!name) {
      toast.error('Please enter a name');
      return;
    } else if (emailError) {
      toast.error(emailError);
      return;
    } else if (cell_number.length && cellError) {
      toast.error(cellError);
      return;
    }
    setLoading(true);

    let method = 'POST';
    let url =
      isSuper && getIsAdmin(newRoles)
        ? `${apiEndpoint}/admin/clients/${client_id}/accounts`
        : `${apiEndpoint}/client/accounts`;
    if (!isNewAccount) {
      url = `${apiEndpoint}/client/accounts/${account_id}`;
      method = 'PUT';
    }

    const body = new FormData();
    body.append('name', name);
    body.append('email', email as string);
    body.append('cell_number', cell_number);
    if (isSuper) {
      body.append('client_id', `${client_id}`);
    }
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: Account = await response.json();

      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error saving the account.');
      }

      if (!isNewAccount) {
        await updateUserRoles();
      }

      onEditSuccess && onEditSuccess(json);
      toast.success('Account saved!');
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const deleteAccount = async () => {
    setLoading(true);

    const method = 'DELETE';
    const url = isSuper
      ? `${apiEndpoint}/admin/accounts/${account_id}`
      : `${apiEndpoint}/client/accounts/${account_id}`;
    try {
      const response = await apiFetchAsync({ url, token, method });
      if (response.status !== 204) {
        throw new Error('Uh-oh! There was an error deleting the account.');
      }

      onDeleteSuccess && onDeleteSuccess(account_id);
      toast.success('Account deleted');
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const updateSuperRole = async () => {
    if (getIsSuperAdmin(roles) === getIsSuperAdmin(newRoles)) {
      return;
    }
    const method = 'PUT';
    const url = `${apiEndpoint}/admin/accounts/${account_id}/${
      getIsSuperAdmin(newRoles) ? 'promote' : 'demote'
    }`;
    try {
      const response = await apiFetchAsync({ url, token, method });
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error changing super role.');
      }
      setRoles(newRoles);
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const updateUserRoles = async () => {
    const method = 'PUT';
    const url = `${apiEndpoint}/client/accounts/${account_id}/roles`;
    const body = getRolesFormData(
      roles.filter((r) => r !== 'super'),
      newRoles.filter((r) => r !== 'super')
    );
    updateSuperRole();
    if (body) {
      try {
        const response = await apiFetchAsync({ url, token, method, body });
        const json: Account = await response.json();
        if (response.status !== 200) {
          throw new Error('Uh-oh! There was an error changing role(s).');
        }
        setRoles(json.roles || []);
      } catch (err) {
        toast.error(`${err}`);
      }
    }
  };

  return (
    <Container>
      {!hideTitle && <H2>{isNewAccount ? 'Create' : 'Edit'} Account</H2>}
      <Form onSubmit={updateAccount as any}>
        <Fieldset disabled={loading}>
          <FieldWrapper label="Email">
            <Input onChange={(e) => setEmail(e.target.value)} value={email} />
          </FieldWrapper>
          <>
            <FieldWrapper label="Name">
              <Input onChange={(e) => setName(e.target.value)} value={name} />
            </FieldWrapper>
            <FieldWrapper label="Cell Number">
              <Input
                type="number"
                onChange={(e) => setCell(e.target.value)}
                value={cell}
              />
            </FieldWrapper>
          </>
          {onEditSuccess && (
            <FieldWrapper label="Roles">
              {['admin', 'technician', ...(isSuper ? ['super'] : [])].map(
                (role) => (
                  <Radio
                    key={role}
                    name={role}
                    value={role}
                    label={role}
                    icon="People"
                    onChange={(ev) => {
                      setSelectedRole(ev.target.value);
                      setNewRoles(
                        ev.target.checked
                          ? [...newRoles, role]
                          : newRoles.filter((i) => i !== role)
                      );
                    }}
                    checked={selectedRole === role}
                  />
                )
              )}
            </FieldWrapper>
          )}

          {deleting ? (
            <Row>
              Are you sure?
              <Button
                label="Yes, delete"
                appearance="secondary"
                size="small"
                onClick={deleteAccount}
                style={{ margin: '0 10px' }}
              />
              <Button
                label="Cancel"
                appearance="secondary"
                size="small"
                onClick={() => setDeleting(false)}
              />
            </Row>
          ) : (
            <>
              <Button
                disabled={loading}
                onClick={updateAccount}
                label={isNewAccount ? 'Create' : 'Update'}
              />
              {!isNewAccount && onDeleteSuccess && (
                <DeleteButton onClick={() => setDeleting(true)}>
                  Delete Account
                </DeleteButton>
              )}
            </>
          )}
        </Fieldset>
      </Form>
    </Container>
  );
};
