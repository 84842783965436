import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  Map,
  Marker,
  NavigationControl,
  LngLatBounds,
  GeoJSONSource,
} from 'mapbox-gl';
import { Point } from 'geojson';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';

import { theme, toRem } from '@awareness-ui/design';
import {
  Filters,
  Icon,
  MapToggle,
  StatusCircle,
} from '@awareness-ui/components';
import { AssetGeoJSON, Asset, AssetStatus, ReduxState } from '@awareness/types';
import {
  getAssetIcon,
  getFiltersQueryParams,
  transformAssetsToGeoJSON,
} from '@awareness/assets';
import { getRequiredPassword, getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { apiFetchAsync } from '@awareness/api-fetch';
import toast from 'react-hot-toast';
import { getIsSuperAdmin, getUserRoles } from '@awareness/user';

const STREET_STYLE = 'mapbox://styles/mapbox/light-v10';
const SATELLITE_STYLE = 'mapbox://styles/mapbox/satellite-v9';

const healthy = ['==', ['get', 'status'], 'healthy'];
const predictive = ['==', ['get', 'status'], 'predictive'];
const potential = ['==', ['get', 'status'], 'potential'];
const critical = ['==', ['get', 'status'], 'critical'];

const COLORS = [
  theme.color.status.healthy,
  theme.color.status.predictive,
  theme.color.status.potential,
  theme.color.status.critical,
];

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const MapEl = styled.div<{ disabled: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
  .mapboxgl-ctrl {
    display: block;
  }
  .mapboxgl-marker {
    display: flex;
    padding: 10px;
  }

  ${({ disabled }) =>
    disabled
      ? `
  opacity: 0.6;
  pointer-events: none;
  &:after {
    content: 'Loading...';
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  `
      : ''}
`;

const ClusterPopup = styled.div`
  background-color: ${theme.color.background.darker};
`;

const Cluster = styled.div`
  cursor: pointer;

  ${ClusterPopup} {
    display: none;

    ::before {
      content: ' ';
      position: absolute;
      left: 62px;
      bottom: -18px;
      height: 0;
      border: 10px solid transparent;
      border-top-color: ${theme.color.background.darker};
      z-index: 1;
    }
  }

  &:hover {
    z-index: 1;

    ${ClusterPopup} {
      display: flex;
      position: absolute;
      top: -37px;
      right: -45px;
      z-index: 1;
      padding: 12px;
      border-radius: 30px;
    }
  }
`;

const ClusterPopupNumber = styled.div<{ status: AssetStatus }>`
  padding: 0 12px;
  font-weight: ${theme.font.weight.bold};
  color: ${({ status }) => (theme.color.status as any)[status]};
  position: relative;
  font-size: 13px;

  ::before {
    content: '•';
    font-size: 24px;
    position: absolute;
    left: 0px;
    top: 0px;
  }
`;

const IndividualPopup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.background.darker};
  font-size: 14px;
`;

const Pin = styled.div`
  cursor: pointer;

  .selected {
    stroke: ${theme.color.white};
    stroke-width: 1.5px;
  }

  ${IndividualPopup} {
    display: none;

    ::before {
      content: ' ';
      position: absolute;
      left: -18px;
      top: 35px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: ${theme.color.background.darker};
      z-index: 1;
    }
  }

  &:hover {
    z-index: 1;

    ${IndividualPopup} {
      display: flex;
      position: absolute;
      margin-top: -26px;
      margin-left: 48px;
      z-index: 1;
      padding: 12px;
      border-radius: 20px;
    }
  }
`;

const PopupTop = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${toRem(8)};
  align-items: center;
`;

const PopupTopInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${toRem(8)};
`;

const PoleName = styled.div`
  color: ${theme.color.text[0]};
  font-weight: ${theme.font.weight.bold};
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  direction: rtl;
  text-align: left;
`;
const PoleID = styled.div.attrs({ size: 'small' })`
  color: ${theme.color.text[22]};
  font-size: 12px;
  margin-top: -2px;
`;
const PoleStatusDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${theme.color.text[0]};
  font-weight: ${theme.font.weight.bold};
`;

const Value = styled.div`
  color: ${theme.color.text[0]};
  font-weight: ${theme.font.weight.bold};
  margin-left: ${toRem(4)};
`;

const StatWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${toRem(8)};
`;

function donutSegment(
  start: number,
  end: number,
  r: number,
  r0: number,
  color: string
) {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
    y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
    y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  return [
    '<path d="M',
    r + r0 * x0,
    r + r0 * y0,
    'L',
    r + r * x0,
    r + r * y0,
    'A',
    r,
    r,
    0,
    largeArc,
    1,
    r + r * x1,
    r + r * y1,
    'L',
    r + r0 * x1,
    r + r0 * y1,
    'A',
    r0,
    r0,
    0,
    largeArc,
    0,
    r + r0 * x0,
    r + r0 * y0,
    '" fill="' + color + '" />',
  ].join(' ');
}

function createDonutChart(
  { healthy, predictive, potential, critical }: ClusterProps,
  onClick: () => void
): HTMLElement | null {
  const offsets = [];
  const counts = [healthy, predictive, potential, critical];
  var total = 0;
  for (var i = 0; i < counts.length; i++) {
    offsets.push(total);
    total += counts[i];
  }
  const fontSize =
    total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
  const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
  const r0 = Math.round(r * 0.85);
  const whiteR0 = r0 * 1.25;
  const w = whiteR0 * 2 + 8;

  var clusterHtml =
    '<svg width="' +
    w +
    '" height="' +
    w +
    '" viewbox="-8 -8 ' +
    w +
    ' ' +
    w +
    '" text-anchor="middle" style="font: ' +
    fontSize +
    'px sans-serif; display: block">';

  clusterHtml +=
    '<circle cx="' + r + '" cy="' + r + '" r="' + whiteR0 + '" fill="white" />';

  for (i = 0; i < counts.length; i++) {
    clusterHtml += donutSegment(
      offsets[i] / total,
      (offsets[i] + counts[i]) / total,
      r,
      r0,
      COLORS[i]
    );
  }

  clusterHtml +=
    '<text dominant-baseline="central" transform="translate(' +
    r +
    ', ' +
    r +
    ')">' +
    total.toLocaleString() +
    '</text></svg>';

  const popupHtml = renderToStaticMarkup(
    <ClusterPopup>
      <ClusterPopupNumber status="healthy">{healthy}</ClusterPopupNumber>
      <ClusterPopupNumber status="predictive">{predictive}</ClusterPopupNumber>
      <ClusterPopupNumber status="potential">{potential}</ClusterPopupNumber>
      <ClusterPopupNumber status="critical">{critical}</ClusterPopupNumber>
    </ClusterPopup>
  );

  let el: HTMLElement = document.createElement('div');
  el.innerHTML = renderToStaticMarkup(<Cluster />);
  el = el.firstChild as HTMLElement;
  el.innerHTML = popupHtml + clusterHtml;
  el.onclick = onClick;
  return el;
}

interface IndividualProps extends Asset {
  coordinates: { lat: number; lng: number };
}

interface ClusterProps {
  healthy: number;
  predictive: number;
  potential: number;
  critical: number;
}

interface MapProps {
  apiEndpoint: string;
  token: string;
  roles: undefined | string[];
  requirePassword: boolean;
}

interface Props extends MapProps {
  setSelectedId: (id: number | null) => void;
  selectedId: number | null;
}

type MapStyle = 'street' | 'satellite';

interface MapState {
  style: MapStyle;
  initialized: boolean;
  initializedZoom: boolean;
  filters: AssetStatus[];
  loading: boolean;
  assets: AssetGeoJSON[];
  useSuperEndpoint: boolean;
}

export class MapView extends React.Component<Props, MapState> {
  map = null as any as Map;
  selectedPin: HTMLElement | null = null;

  state = {
    style: 'street' as MapStyle,
    initialized: false,
    initializedZoom: false,
    filters: [] as AssetStatus[],
    loading: true,
    assets: [] as AssetGeoJSON[],
    useSuperEndpoint: false,
  };

  componentDidMount() {
    if (!this.props.requirePassword) this.initializeMap();
  }

  componentDidUpdate() {
    this.mapUpdated();
  }

  mapUpdated = () => {
    if (!this.state.initialized && this.props.roles) {
      this.setState({
        initialized: true,
        useSuperEndpoint: getIsSuperAdmin(this.props.roles),
      });

      this.fetchAssets(() => {
        this.map.on('styledata', this.debouncedOnMapLoad);
        this.debouncedOnMapLoad();
      });
    }
  };

  initializeMap() {
    this.map = new Map({
      container: 'map',
      zoom: 0.3,
      center: [0, 20],
      style: this.state.style === 'street' ? STREET_STYLE : SATELLITE_STYLE,
      accessToken:
        'pk.eyJ1Ijoic25ldXBhbmUiLCJhIjoiY2xkeGlmc2F1MGhyaTNycGtxYWViaWduMiJ9.LxjA84ndCKKJ1LvNLV1fZA      ',
    });

    this.map.addControl(new NavigationControl());
    (window as any).map = this.map;

    this.mapUpdated();
  }

  onMapLoad = (reload = false) => {
    const alreadyLoaded = !!this.map.getSource('assets');
    if (alreadyLoaded && !reload) {
      return;
    }

    if (alreadyLoaded) {
      this.map.removeLayer('asset');
      this.map.removeSource('assets');
    }

    const features = this.state.assets;

    const selectedId = this.props.selectedId;
    if (selectedId !== null) {
      const selectedAsset = features.find(
        (a) => a.properties.id === selectedId
      );
      if (selectedAsset) {
        const { x, y } = this.map.project(selectedAsset.geometry.coordinates);
        this.flyIntoPin(x, y, 16);
      }
    }

    try {
      this.map.addSource('assets', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
        cluster: true,
        clusterRadius: 70,
        clusterMaxZoom: 14,
        clusterProperties: {
          healthy: ['+', ['case', healthy, 1, 0]],
          predictive: ['+', ['case', predictive, 1, 0]],
          potential: ['+', ['case', potential, 1, 0]],
          critical: ['+', ['case', critical, 1, 0]],
        },
      });

      this.map.addLayer({
        id: 'asset',
        type: 'fill',
        source: 'assets',
        filter: ['!=', 'cluster', true],
      });
    } catch (err) {
      console.log('ERROR loading map assets', err);
    }

    // objects for caching and keeping track of HTML marker objects (for performance)
    const markers: { [key: string]: Marker } = {};
    var markersOnScreen: { [key: string]: Marker } = {};

    const updateMarkers = () => {
      const newMarkers: { [key: string]: Marker } = {};
      const features = this.map.querySourceFeatures('assets');

      if (!features.length) {
        for (const id in markersOnScreen) {
          markersOnScreen[id].remove();
        }
        return;
      }

      features.forEach((feature) => {
        const coords = (feature.geometry as Point).coordinates;
        const props = feature.properties;
        if (!props) {
          return;
        }

        let id: number | string;
        let marker;
        let element;
        if (props.cluster) {
          id = props.cluster_id;
          marker = markers[id];

          if (!marker) {
            const onClick = () => this.flyIntoCluster(id as number, coords);
            element = createDonutChart(props as ClusterProps, onClick);
          }
        } else {
          id = `pin-${props.id}`;
          marker = markers[id];
          if (!marker) {
            props.coordinates = coords;
            element = this.createPin(props as IndividualProps);
          }
        }

        if (element) {
          marker = new Marker({ element }).setLngLat(coords as any);
          markers[id] = marker;
        }

        newMarkers[id] = marker;

        if (!markersOnScreen[id]) {
          marker.addTo(this.map);
        }
      });

      for (const id in markersOnScreen) {
        if (!newMarkers[id]) {
          markersOnScreen[id].remove();
        }
      }

      markersOnScreen = newMarkers;

      if (!this.state.initializedZoom) {
        const bounds = features.reduce(
          (boundAcc, feat: any) => boundAcc.extend(feat.geometry.coordinates),
          new LngLatBounds()
        );
        try {
          this.map.fitBounds(bounds, {
            padding: 40,
            maxZoom: features.length > 1 ? undefined : 3.5,
          });
        } catch (err) {
          console.log('ZOOM ERROR', err);
        }
        this.setState({ initializedZoom: true });
      }
    };

    this.map.on('data', (e: any) => {
      if (e.sourceId !== 'assets' || !e.isSourceLoaded) return;

      this.map.on('move', updateMarkers);
      this.map.on('moveend', updateMarkers);
      updateMarkers();
    });

    this.setState({ loading: false });
  };

  debouncedOnMapLoad = debounce(() => this.onMapLoad(), 1000);

  createPin = (props: IndividualProps): HTMLElement | null => {
    if (!props) {
      return null;
    }

    const fill = (theme.color.status as any)[props.status as AssetStatus];
    var html = renderToStaticMarkup(
      <Pin>
        <IndividualPopup>
          <PopupTop>
            <StatusCircle fill={fill} classification={props.classification} />
            <PopupTopInfo>
              <PoleName>{props.name}</PoleName>
              <PoleID>#{props.id}</PoleID>
            </PopupTopInfo>
          </PopupTop>
          <PoleStatusDiv>
            <StatWrapper>
              <Icon icon="Power" fill="white" />
              <Value>{props.online ? 'Online' : 'Offline'}</Value>
            </StatWrapper>
            {/* <StatWrapper>
              <Icon icon="Battery" fill="white" />
              <Value>72%</Value>
            </StatWrapper> */}
          </PoleStatusDiv>
        </IndividualPopup>
        <Icon
          icon={props.has_gateway ? 'Carrot' : 'Pin'}
          width={38}
          height={40}
          fill={fill}
          overrideBorder={!props.online}
        />
        <Icon
          icon={getAssetIcon(props.classification)}
          width={16}
          height={16}
          fill={theme.color.white}
          style={{ position: 'absolute', marginTop: 10, marginLeft: 11 }}
        />
      </Pin>
    );

    const parentEl = document.createElement('div');
    parentEl.innerHTML = html;
    const pinEl = parentEl.firstChild as HTMLElement;

    if (!pinEl) {
      return null;
    }

    pinEl.onclick = (ev: any) => {
      const { layerX: pinLeftX, layerY: pinTopY } = ev;

      const currentlySelected = this.props.selectedId === props.id;

      if (this.selectedPin) {
        this.clearSelectedPin();
      } else if (pinLeftX !== undefined) {
        this.flyIntoPin(pinLeftX, pinTopY);
      }

      if (!currentlySelected) {
        pinEl.children[1].classList.add('selected');
        this.selectedPin = pinEl;
      }

      this.props.setSelectedId(currentlySelected ? null : props.id);
    };

    if (this.props.selectedId === props.id) {
      pinEl.children[1].classList.add('selected');
      this.selectedPin = pinEl;
    }

    return pinEl;
  };

  clearSelectedPin = () => {
    if (this.selectedPin) {
      this.selectedPin.children[1].classList.remove('selected');
      this.selectedPin = null;
    }
  };

  flyIntoPin = (pinLeftX: number, pinTopY: number, minZoom?: number) => {
    const [mapWidth, mapHeight] = (this.map as any)._containerDimensions();

    const windowWidth = window.innerWidth;
    const detailViewWidth =
      windowWidth >= theme.breakpoint.large
        ? theme.map.detailView.large
        : theme.map.detailView.medium;
    const isSmallScreen = windowWidth < theme.breakpoint.small;
    if (isSmallScreen || minZoom) {
      const windowHeight = window.innerHeight;
      const detailViewHeight = (windowHeight - theme.header.height) * 0.75;
      const pinBottomY = mapHeight - pinTopY;

      if (pinBottomY < detailViewHeight) {
        const bottom = isSmallScreen ? mapHeight / 2 + detailViewHeight / 3 : 0;
        const right = isSmallScreen ? 0 : detailViewWidth;
        const padding = {
          bottom,
          top: 0,
          left: 0,
          right,
        };
        const center = this.map.unproject([pinLeftX, pinTopY]);
        let zoom = this.map.getZoom();
        if (zoom < (minZoom || 2.2)) {
          zoom = minZoom || 2.2;
        }
        this.map.flyTo({
          center,
          zoom,
          padding,
          bearing: 0,
          speed: minZoom ? 2.5 : 0.7,
          curve: 1,
        } as any);
      }
    } else {
      const pinRightX = mapWidth - pinLeftX;
      if (pinRightX < detailViewWidth) {
        this.map.panBy([detailViewWidth - pinRightX + 50, 0]);
      }
    }
  };

  flyIntoCluster = (id: number, center: any) => {
    (this.map.getSource('assets') as GeoJSONSource).getClusterExpansionZoom(
      id,
      (err, zoom) => {
        if (!err) {
          const speed = 1 + (zoom - this.map.getZoom()) * 0.2;
          zoom += 0.1;
          this.map.flyTo({
            center,
            zoom: zoom,
            speed,
            bearing: 0,
            curve: 1,
          });
        }
      }
    );
  };

  setMapStyle = () => {
    if (this.state.style === 'street') {
      this.setState({ style: 'satellite' });
      this.map.setStyle(SATELLITE_STYLE);
    } else {
      this.setState({ style: 'street' });
      this.map.setStyle(STREET_STYLE);
    }
  };

  getMapStyleToggleIcon = () => {
    if (this.state.style === 'street') return 'Satellite';
    if (this.state.style === 'satellite') return 'Flatview';
    else return 'Satellite';
  };

  fetchAssets = async (callback?: () => void) => {
    const { apiEndpoint, token } = this.props;
    const { filters } = this.state;
    const isSuper = getIsSuperAdmin(this.props.roles);

    const queryParams = getFiltersQueryParams(
      filters,
      undefined,
      undefined,
      true
    );
    const url = isSuper
      ? `${apiEndpoint}/admin/assets${queryParams}`
      : `${apiEndpoint}/assets/filter${queryParams}`;

    try {
      const response = await apiFetchAsync({ url, token });
      const json: Asset[] = await response.json();

      if (response.status !== 200 || !Array.isArray(json)) {
        throw new Error('Uh-oh! There was an error loading assets.');
      }

      this.setState({ assets: transformAssetsToGeoJSON(json) });

      if (!json.length) {
        toast('0 assets match your search', { title: 'No Assets' } as any);
      }
    } catch (err) {
      toast.error(`${err}`, {
        actionLabel: 'Try Again',
        onAction: this.fetchAssetsDebounced,
      } as any);
    }

    if (callback) {
      callback();
    } else {
      this.onMapLoad(true);
    }
  };

  fetchAssetsDebounced = debounce(this.fetchAssets, 1500);

  toggleFilter = (filter: AssetStatus) => {
    const currentFilters = this.state.filters;
    const filters = currentFilters.includes(filter)
      ? currentFilters.filter((f) => f !== filter)
      : [...currentFilters, filter];
    this.setState({ filters, loading: true }, this.fetchAssetsDebounced);
  };

  clearFilters = () => {
    this.setState({ filters: [], loading: true }, this.fetchAssetsDebounced);
  };

  setUseSuperEndpoint = (v: boolean) =>
    this.setState({ useSuperEndpoint: v }, this.fetchAssetsDebounced);

  render() {
    const isSuper = getIsSuperAdmin(this.props.roles);

    return (
      <MapContainer>
        <Filters
          toggleFilter={this.toggleFilter}
          clearFilters={this.clearFilters}
          filters={this.state.filters}
          positionAbsolute={true}
          setUseSuperEndpoint={isSuper && this.setUseSuperEndpoint}
          useSuperEndpoint={this.state.useSuperEndpoint}
        />
        <MapEl id="map" disabled={this.state.loading} />
        <MapToggle
          onClick={this.setMapStyle}
          icon={this.getMapStyleToggleIcon()}
        />
      </MapContainer>
    );
  }
}

const mapStateToProps = (state: ReduxState): MapProps => ({
  apiEndpoint: getApiEndpoint(state),
  token: getToken(state),
  roles: getUserRoles(state),
  requirePassword: getRequiredPassword(state),
});

export const MapViewConn = connect(mapStateToProps, null, null, {
  forwardRef: true,
})(MapView);
