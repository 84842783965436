import React from 'react';

import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';
import ActionButton from './ActionButton';

interface ActionMenuProps {
  title?: string;
  onToggle?: () => void;
  isVisible?: boolean;
  children?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  position: relative;
  margin-top: ${toRem(20)};
  padding: 8px;
`;

interface NavListProps {
  isVisible?: boolean;
}

const NavList = styled.div<NavListProps>`
  position: absolute;
  top: ${toRem(32)};
  right: 0;
  z-index: ${theme.zIndex.dialog};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: ${toRem(180)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? '100' : '0')};
  background-color: ${theme.color.white};
  border-radius: ${toRem(11)};
  box-shadow: ${theme.shadow.dialog};
  padding: ${toRem(8)};
  transition: all 0.25s ease-in-out;
`;

const ActionMenuListTitle = styled.span`
  width: 100%;
  margin: ${toRem(12)} 0;
  padding: 0 ${toRem(11)};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.medium};
  font-size: ${toRem(11)};
  line-height: 100%;
  color: ${theme.color.text[88]};
`;

const ActionMenu: React.FC<ActionMenuProps> = ({
  title,
  isVisible,
  onToggle,
  children,
}) => {
  return (
    <Container>
      <ActionButton isActive={isVisible} onClick={onToggle} />
      <NavList isVisible={isVisible}>
        {title && <ActionMenuListTitle>{title}</ActionMenuListTitle>}
        {children}
      </NavList>
    </Container>
  );
};

export default ActionMenu;
