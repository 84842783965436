import React from 'react';

import styled from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';

const Input = styled.input`
  width: 100%;
  height: ${toRem(theme.input.height)};
  padding: 0 ${toRem(11)};
  border-top-left-radius: ${toRem(8)};
  border-bottom-left-radius: ${toRem(8)};
  appearance: none;
  border: ${toRem(1)} solid ${theme.color.border.medium};
  outline: none;
  background-color: ${theme.color.input.bg.default};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  color: ${theme.color.text[100]};

  @media (min-width: ${theme.breakpoint.small}px) {
    width: ${toRem(200)};
  }

  @media (min-width: ${theme.breakpoint.med}px) {
    width: ${toRem(320)};
  }

  &::placeholder {
    color: ${theme.color.placeholder};
  }

  &:hover {
    border-color: ${theme.color.input.border.hover};
    background-color: ${theme.color.input.bg.hover};
  }

  &:focus {
    border-color: ${theme.color.input.border.focus};
  }

  &:disabled {
    border: none;

    &:hover {
      background-color: ${theme.color.white};
    }

    &:focus {
      background-color: ${theme.color.white};
    }
  }
`;

const SearchForm = styled.form`
  width: auto;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${toRem(16)};

  @media (min-width: ${theme.breakpoint.med}px) {
    margin-bottom: 0;
    width: auto;
  }
`;

const SearchButton = styled.button`
  width: ${toRem(theme.input.height)};
  height: ${toRem(theme.input.height)};
  background: ${theme.color.primary[100]};
  border-top-right-radius: ${toRem(8)};
  border-bottom-right-radius: ${toRem(8)};
  align-items: center;
  justify-content: center;
`;

const Search: React.FC = () => {
  return (
    <SearchForm>
      <Input
        type="search"
        id="searchAssets"
        placeholder="Search Assets"
        name="Search Assets"
      />
      <SearchButton>
        <Icon icon="Search" width={16} height={16} fill="white" />
      </SearchButton>
    </SearchForm>
  );
};

export default Search;
