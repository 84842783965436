import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { theme } from '@awareness-ui/design';
import {
  getDeveloperSettings,
  patchDeveloperSettings,
  resetDeveloperSettings,
} from '@awareness/developer-settings';
import { H3, Label } from '../Typography';
import { Input } from '../Inputs';
import {
  getApiEndpoint,
  LOCAL_API_ENDPOINT,
  PRODUCTION_API_ENDPOINT,
  setApiEndpoint,
  STAGING_API_ENDPOINT,
} from '@awareness/api-endpoint';
import { Button } from '../Button';

interface Props {}

const ModalBackground = styled.div`
  display: flex;
  position: absolute;
  z-index: ${theme.zIndex.modal};
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.background.light};
  border-radius: 15px;
  padding: 40px;
  box-shadow: ${theme.shadow.modal};
  max-height: 85%;
  overflow-y: scroll;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  display: flex;
  margin: 20px;
  border-bottom: 1px solid ${theme.color.border.medium};
  width: 110%;
`;

export const DeveloperSettingsModal: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { modalOpen, poleDetailLocationVisible } =
    useSelector(getDeveloperSettings);
  const apiEndpoint = useSelector(getApiEndpoint);

  if (!modalOpen) {
    return null;
  }

  const closeModal = () =>
    dispatch(patchDeveloperSettings({ modalOpen: false }));

  return (
    <ModalBackground onClick={closeModal}>
      <Modal onClick={(ev) => ev.stopPropagation()}>
        <H3 style={{ marginBottom: 50 }}>Developer Settings</H3>
        <Row style={{ width: '100%' }}>
          <Label style={{ marginRight: 20 }}>API Endpoint</Label>
          <Row>
            <Button
              style={{ marginRight: 2 }}
              size="small"
              label="Local"
              onClick={() => dispatch(setApiEndpoint(LOCAL_API_ENDPOINT))}
            />
            <Button
              style={{ marginRight: 2 }}
              size="small"
              label="Stage"
              onClick={() => dispatch(setApiEndpoint(STAGING_API_ENDPOINT))}
            />
            <Button
              style={{ marginRight: 2 }}
              size="small"
              label="Prod"
              onClick={() => dispatch(setApiEndpoint(PRODUCTION_API_ENDPOINT))}
            />
          </Row>
        </Row>
        <Input
          value={apiEndpoint}
          onChange={(e) => dispatch(setApiEndpoint(e.currentTarget.value))}
        />
        <Line />
        <Row style={{ width: '100%' }}>
          <Input
            style={{ width: 20, marginRight: 10 }}
            type="checkbox"
            checked={poleDetailLocationVisible}
            onChange={(e) =>
              dispatch(
                patchDeveloperSettings({
                  poleDetailLocationVisible: e.currentTarget.checked,
                })
              )
            }
          />
          <Label>Coordinates in pole detail view</Label>
        </Row>
        <Line />
        <Row>
          <Button label="Done" onClick={closeModal} />
          <Button
            size="small"
            appearance="secondary"
            label="Reset"
            onClick={() => dispatch(resetDeveloperSettings())}
            style={{ marginLeft: 10 }}
          />
        </Row>
      </Modal>
    </ModalBackground>
  );
};
