import React from 'react';

import styled from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';
import { H3, Label } from '../Typography';
import { Button } from '../Button';
import { Icon } from '../Icon';

interface RequestProps {
  onClick?: () => void;
  onClickSmall?: () => void;
  onClickLarge?: () => void;
}

const Layout = styled.div`
  display: flex;
  position: relative;
  min-height: 200px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.color.background.medium};
  border-radius: ${toRem(10)};
`;

const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitle = styled(Label)`
  color: ${theme.color.text[88]};
  margin: ${toRem(8)} 0 ${toRem(32)};
`;

const HelperText = styled(Label)`
  color: ${theme.color.text[88]};
  margin-top: ${toRem(8)};
  font-size: ${toRem(11)};
`;

const CloseContainer = styled.div<RequestProps>`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: ${toRem(8)};
  cursor: pointer;
`;

const PhotoRequest: React.FC<RequestProps> = ({
  onClick,
  onClickSmall,
  onClickLarge,
}) => {
  return (
    <Layout>
      <CloseContainer onClick={onClick}>
        <Icon icon="Close" width={16} height={16} />
      </CloseContainer>
      <H3>Request Photo</H3>
      <SubTitle>Request a photo from asset</SubTitle>
      <ActionContainer>
        <ButtonWrapper onClick={onClickSmall}>
          <Button label="Low Resolution" size="small" />
          <HelperText>within 5 mins</HelperText>
        </ButtonWrapper>
        <ButtonWrapper onClick={onClickLarge}>
          <Button label="High Resolution" size="small" />
          <HelperText>within 50 mins</HelperText>
        </ButtonWrapper>
      </ActionContainer>
    </Layout>
  );
};

export default PhotoRequest;
