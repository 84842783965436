import styled, { css } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

interface TextProps {
  size?: 'default' | 'small';
}

interface MessageProps {
  hasError?: boolean;
  indent?: boolean;
  key?: string;
}

export const H1 = styled.h1`
  margin-bottom: ${toRem(theme.spacing.xsmall)};
  font-family: ${theme.font.family.averta};
  font-weight: ${theme.font.weight.bold};
  font-size: ${toRem(22)};
  line-height: 100%;

  @media (min-width: ${theme.breakpoint.med}px) {
    font-size: ${toRem(27)};
  }
`;

export const H2 = styled.h2`
  margin-bottom: 0;
  font-family: ${theme.font.family.averta};
  font-weight: ${theme.font.weight.light};
  font-size: ${toRem(19.25)};
  line-height: 119.2%;

  @media (min-width: ${theme.breakpoint.med}px) {
    font-size: ${toRem(22)};
  }
`;

export const H3 = styled.h3`
  margin-bottom: 0;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${toRem(16.5)};
  line-height: 100%;
`;

export const H4 = styled.h4`
  margin-bottom: 0;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.25)};
  line-height: 143%;
`;

export const Label = styled.label`
  margin: 0;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.medium};
  font-size: ${toRem(13.25)};
  line-height: 100%;

  span {
    color: ${theme.color.text[33]};
  }
`;

export const Text = styled.p<TextProps>`
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(16.5)};
  line-height: 125%;
  color: ${theme.color.text[88]};

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: ${toRem(13.25)};
    `}

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Message = styled(Text)<MessageProps>`
  font-size: ${toRem(11)};

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${theme.color.error};
      text-align: center;
    `}

  ${({ indent }) =>
    indent &&
    css`
      padding-left: ${toRem(44)};
    `}
`;

export const FormError = styled(Message).attrs({
  hasError: true,
})`
  margin-bottom: ${toRem(22)};
`;

export const Subtext = styled.div`
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.light};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  color: ${theme.color.text[44]};
`;
