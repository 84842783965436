import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { RouteChildrenProps } from 'react-router-dom';

import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import { Asset, Group as GroupType, User } from '@awareness/types';
import {
  H1,
  Button,
  Subtext,
  Icon,
  Text,
  TableLayout,
  TableHead,
  TableHeaderRow,
  TableHeaderCell,
  SortLabel,
  AccountListItem,
  AssetListItem,
  TableBody,
  Pagination,
  Modal,
  ModalContent,
} from '@awareness-ui/components';
import { theme } from '@awareness-ui/design';
import { getAssetIcon, getFiltersQueryParams } from '@awareness/assets';
import { EditGroup } from './EditGroup';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const Header = styled.div`
  width: 100%;
  background: ${theme.color.background.light};
  padding: 55px;
  padding-bottom: 90px;
  border-bottom: 1px solid ${theme.color.border.medium};
`;

const Content = styled.div`
  padding: 0 55px 55px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardRow = styled(Row)`
  margin-top: -55px;
  margin-left: 10px;
  margin-bottom: 25px;
`;

const Card = styled.div`
  width: 350px;
  background: ${theme.color.white};
  border-radius: 11px;
  border: 1px solid ${theme.color.border.light};
  padding: 20px;
  line-height: 68px;
  margin-left: 20px;
`;

const CardIcon = styled(Icon)`
  width: 44px;
  height: 44px;
  padding: 4px 0;
  fill: ${theme.color.text[88]};
  background: ${theme.color.background.medium};
  border-radius: 44px;
  margin-right: 20px;
`;

const UnderlinedButton = styled(Text)<{ selected: boolean }>`
  cursor: pointer;
  border-bottom: 2px solid
    ${({ selected }) =>
      selected ? theme.color.primary[100] : theme.color.border.medium};
  line-height: 38px;
  margin: 20px 12px;
`;

interface Props extends RouteChildrenProps {}

export const Group: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [selectedList, setSelectedList] = useState<'Asset' | 'User'>('Asset');
  const [name, setName] = useState('');
  const [assets, setAssets] = useState<Asset[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const startEditing = props.location.search.includes('edit=true');
  const [editingGroupName, setEditingGroupName] = useState(startEditing);
  const [editingId, setEditingId] = useState<number>();
  const [deleting, setDeleting] = useState(false);

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);
  const id: string = (props.match?.params || ({} as any)).id;

  useEffect(() => {
    if (!name) {
      fetchInitialData();
      return;
    }
    selectedList === 'Asset' ? fetchAssets() : fetchUsers();
  }, [selectedList, pageSize, nextPageNumber]);

  const fetchInitialData = async () => {
    await Promise.all([fetchGroup(), fetchAssets(), fetchUsers()]);
    setLoading(false);
  };

  const fetchGroup = async () => {
    const url = `${apiEndpoint}/client/assets/groups/${id}`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json: GroupType = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching the group.');
      }

      setName(json.name);
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const fetchAssets = async () => {
    const queryParams = getFiltersQueryParams([], pageSize, nextPageNumber);
    const url = `${apiEndpoint}/client/assets/groups/${id}/assets${queryParams}`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json: Asset[] = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching the assets.');
      }

      setAssets(json);
    } catch (err) {
      toast.error(`${err}`);
    }
    setPageNumber(nextPageNumber);
  };

  const fetchUsers = async () => {
    const queryParams = getFiltersQueryParams([], pageSize, nextPageNumber);
    const url = `${apiEndpoint}/client/assets/groups/${id}/users${queryParams}`;
    try {
      const response = await apiFetchAsync({ url, token });
      const json: User[] = await response.json();
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error fetching the users.');
      }

      setUsers(json);
    } catch (err) {
      toast.error(`${err}`);
    }
    setPageNumber(nextPageNumber);
  };

  const deleteItem = async () => {
    setLoading(true);

    const method = 'PUT';
    const type = selectedList === 'Asset' ? 'assets' : 'users';
    const url = `${apiEndpoint}/client/assets/groups/${id}/${type}`;
    const body = new FormData();
    body.append('remove[]', `${editingId}`);

    try {
      const response = await apiFetchAsync({ url, token, method, body });
      if (response.status !== 204) {
        throw new Error(`Uh-oh! There was an error deleting ${type}.`);
      }

      selectedList === 'Asset'
        ? setAssets(assets.filter((a) => a.id !== editingId))
        : setUsers(users.filter((u) => u.id !== editingId));
      toast.success(`${selectedList} deleted`);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const onUpdateSuccess = useCallback((name: string) => {
    setName(name);
    setEditingGroupName(false);
  }, []);

  const onDeleteSuccess = useCallback(() => props.history.push('/group'), []);

  const listItems = selectedList === 'Asset' ? assets : users;
  const pageCount =
    (listItems.length >= pageSize ? pageNumber + 1 : pageNumber) + 1;

  const ListExtraRow = () => (
    <tr>
      <td>
        <Row style={{ margin: 5 }}>
          {deleting ? (
            <>
              Are you sure?
              <Button
                label="Yes, delete"
                appearance="secondary"
                size="small"
                onClick={deleteItem}
                style={{ margin: '0 10px' }}
              />
              <Button
                label="Cancel"
                appearance="secondary"
                size="small"
                onClick={() => setDeleting(false)}
              />
            </>
          ) : (
            <>
              <Button
                label="Delete from group"
                appearance="secondary"
                size="small"
                onClick={() => setDeleting(true)}
                style={{ margin: '0 20px' }}
              />
              <Button
                label={`View ${selectedList}`}
                appearance="secondary"
                size="small"
                to={
                  selectedList === 'Asset'
                    ? `/asset/${editingId}`
                    : `/users/${editingId}`
                }
              />
            </>
          )}
        </Row>
      </td>
    </tr>
  );

  const clearEditingId = useCallback(() => {
    setEditingId(undefined);
    setDeleting(false);
  }, []);
  const setIdEditing = useCallback((i: number) => {
    setEditingId(i);
    setDeleting(false);
  }, []);

  return (
    <MainView>
      <Modal
        style={{ width: 'auto' }}
        maxWidth={900}
        isActive={editingGroupName && !!name}
        onClose={() => setEditingGroupName(false)}>
        <ModalContent>
          <EditGroup
            id={id}
            name={name}
            onUpdate={onUpdateSuccess}
            onDelete={onDeleteSuccess}
          />
        </ModalContent>
      </Modal>
      <Header>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <Subtext
              style={{
                marginBottom: 10,
              }}>{`Groups > ${name}`}</Subtext>

            <H1>{name}</H1>
            <Button
              size="small"
              appearance="secondary"
              style={{ width: 80 }}
              label="< Back"
              onClick={() => props.history.goBack()}
            />
          </Col>
          <Button
            label="Edit Group"
            onClick={() => setEditingGroupName(true)}
          />
        </Row>
      </Header>
      <CardRow>
        <Card>
          <Row>
            <CardIcon icon={getAssetIcon(assets[0]?.classification)} />
            {assets.length} Assets
          </Row>
        </Card>
        <Card>
          <Row>
            <CardIcon icon="PeopleOutline" />
            {users.length} Users
          </Row>
        </Card>
      </CardRow>
      <Content>
        {loading ? (
          'Loading...'
        ) : (
          <>
            <Row style={{ justifyContent: 'space-between' }}>
              <Row>
                <UnderlinedButton
                  selected={selectedList === 'Asset'}
                  onClick={() => setSelectedList('Asset')}>
                  Assets ({assets.length})
                </UnderlinedButton>
                <UnderlinedButton
                  selected={selectedList === 'User'}
                  onClick={() => setSelectedList('User')}>
                  Users ({users.length})
                </UnderlinedButton>
              </Row>
              <Button
                label={`Add ${selectedList}`}
                to={
                  selectedList === 'Asset'
                    ? `/group/${id}/add-asset`
                    : `/group/${id}/add-user`
                }
              />
            </Row>
            {selectedList === 'Asset' && (
              <TableLayout style={{ width: '100%' }}>
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <SortLabel label="Asset Name" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Status" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Connection" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Type" />
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {assets.map((a) => (
                    <>
                      <AssetListItem
                        key={a.id}
                        onClick={
                          editingId === a.id
                            ? clearEditingId
                            : () => setIdEditing(a.id)
                        }
                        {...a}
                      />
                      {editingId === a.id && <ListExtraRow />}
                    </>
                  ))}
                </TableBody>
              </TableLayout>
            )}
            {selectedList === 'User' && (
              <TableLayout style={{ width: '100%' }}>
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <SortLabel label="Name" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Role" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Activity" />
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {users.map((u) => (
                    <>
                      <AccountListItem
                        key={u.id}
                        onClick={
                          editingId === u.id
                            ? clearEditingId
                            : () => setIdEditing(u.id)
                        }
                        {...u}
                      />
                      {editingId === u.id && <ListExtraRow />}
                    </>
                  ))}
                </TableBody>
              </TableLayout>
            )}
            <Pagination
              pageCount={pageCount}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setNextPageNumber}
            />
          </>
        )}
      </Content>
    </MainView>
  );
};
