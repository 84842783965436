import React from 'react';
import styled, { css } from 'styled-components';
import { theme, toRem } from '@awareness-ui/design';

import {
  Account,
  Alert,
  Asset,
  Client,
  Group,
  UserActivity,
} from '@awareness/types';
import { formatDate, MEASUREMENT_ICON_NAMES } from '@awareness/util';
import { getIsAdmin, getIsSuperAdmin } from '@awareness/user';
import { Icon } from '../Icon';
import { Status } from '../Status';
import { H3, H4 } from '../Typography';
import { Button } from '../Button';

export const Row = styled.tr<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${toRem(11)};
  border-bottom: ${toRem(1)} solid ${theme.color.border.medium};
  border-left: ${toRem(2.75)} solid transparent;
  background-color: ${({ disabled }) =>
    disabled ? theme.color.background.medium : theme.color.white};
  transition: all ${theme.transition.button.default};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.25)};
  line-height: 130%;
  text-align: left;
  color: ${theme.color.text[100]};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        border-left-color: ${theme.color.primary[100]};
        background-color: ${theme.color.background.light};
      }
    `}

  @media (min-width: ${theme.breakpoint.med}px) {
    display: table-row;
    padding: 0 0;
  }
`;

const CameraIcon = styled(Icon).attrs({ icon: 'Image' })`
  box-sizing: content-box;
  min-width: 24px;
  min-height: 24px;
  padding-left: 8px;
  fill: ${theme.color.text[100]};
`;

interface CellProps {
  align?: 'center' | 'left' | 'right';
}

export const Cell = styled.td<CellProps>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${toRem(8.75)};
  font: inherit;
  text-align: left;
  color: inherit;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font: inherit;
    font-weight: ${theme.font.weight.semiBold};
    color: ${theme.color.text[100]};
  }

  @media (min-width: ${theme.breakpoint.med}px) {
    display: table-cell;
    width: auto;
    height: ${toRem(66)};
    margin-bottom: 0;
    padding: ${toRem(11)} ${toRem(22)};

    &::before {
      display: none;
    }
  }

  ${({ align }) =>
    align === 'center' &&
    css`
      @media (min-width: ${theme.breakpoint.med}px) {
        text-align: center;

        > * {
          margin: 0 auto;
        }
      }
    `}

  ${({ align }) =>
    align === 'right' &&
    css`
      @media (min-width: ${theme.breakpoint.med}px) {
        text-align: right;

        > * {
          margin: 0 0 0 auto;
        }
      }
    `}
`;

export const Name = styled(H3)`
  font-weight: ${theme.font.weight.medium};
  margin-bottom: ${toRem(4)};
`;

export const Serial = styled(H4)`
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(11)};
  text-transform: uppercase;
`;

interface AssetProps extends Asset {
  onClick: () => void;
  disabled?: boolean;
}

export const AssetListItem: React.FC<AssetProps> = ({
  id,
  name,
  status,
  online,
  type,
  onClick,
  disabled,
  has_camera,
}) => {
  return (
    <Row onClick={onClick} disabled={disabled}>
      <Cell>
        <Name>
          {name} {has_camera && <CameraIcon />}
        </Name>
        <Serial>{id}</Serial>
      </Cell>
      <Cell>
        <Status status={status} />
      </Cell>
      <Cell>{online ? 'Online' : 'Offline'}</Cell>
      <Cell>{type}</Cell>
    </Row>
  );
};

interface AlertProps extends Alert {
  onClick?: () => void;
}

export const AlertListItem: React.FC<AlertProps> = ({
  timestamp,
  severity,
  channel_name,
  onClick,
}) => {
  const date = new Date(timestamp);
  return (
    <Row onClick={onClick}>
      <Cell>
        <Icon icon={MEASUREMENT_ICON_NAMES[channel_name]} />
        <Name>{channel_name}</Name>
      </Cell>
      <Cell>
        <Status status={severity} />
      </Cell>
      <Cell>{formatDate(date)}</Cell>
    </Row>
  );
};

interface GroupProps extends Group {
  onClick?: () => void;
}

export const GroupListItem: React.FC<GroupProps> = ({ name, onClick }) => {
  return (
    <Row onClick={onClick}>
      <Cell>
        <Name>{name}</Name>
      </Cell>
    </Row>
  );
};

interface ClientProps extends Client {
  onClick?: () => void;
  selected: boolean;
}

export const ClientListItem: React.FC<ClientProps> = ({
  name,
  onClick,
  selected,
}) => {
  return (
    <Row onClick={onClick}>
      <Cell>
        <Name>{name}</Name>
        {selected && <Serial>selected</Serial>}
      </Cell>
    </Row>
  );
};

interface AccountProps extends Account {
  onClick?: () => void;
  disabled?: boolean;
  showActivity?: boolean;
}

export const AccountListItem: React.FC<AccountProps> = ({
  id,
  name,
  email,
  roles,
  onClick,
  disabled,
  showActivity,
}) => {
  return (
    <Row onClick={onClick} disabled={disabled}>
      <Cell>
        <Name>{name}</Name>
      </Cell>
      <Cell>
        <Name>{email}</Name>
      </Cell>
      <Cell>
        <Serial>{roles?.join(' ,')}</Serial>
      </Cell>
      {showActivity && (
        <Cell>
          <Button
            label="View Activity"
            appearance="secondary"
            size="small"
            to={`/users/activity/${id}`}
          />
        </Cell>
      )}
    </Row>
  );
};

interface UserActivityProps extends UserActivity {
  onClick?: () => void;
}

export const UserActivityListItem: React.FC<UserActivityProps> = ({
  user_id,
  timestamp,
  event_code,
  onClick,
}) => {
  return (
    <Row onClick={onClick}>
      <Cell>
        <Name>{user_id}</Name>
      </Cell>
      <Cell>{event_code}</Cell>
      <Cell>
        <Serial>{timestamp}</Serial>
      </Cell>
    </Row>
  );
};
