import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { RouteChildrenProps } from 'react-router-dom';

import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';
import { apiFetchAsync } from '@awareness/api-fetch';
import { Group } from '@awareness/types';
import { toRem } from '@awareness-ui/design';
import {
  H1,
  Button,
  FieldWrapper,
  Input,
  Steps,
} from '@awareness-ui/components';
import {
  getIsSuperAdmin,
  getSelectedClientName,
  getUserRoles,
} from '@awareness/user';

const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
`;

const Form = styled.form`
  margin-top: 50px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
`;

interface Props extends RouteChildrenProps {}

export const NewGroup: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [editName, setName] = useState<string>();

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);
  const roles = useSelector(getUserRoles);
  const isSuper = getIsSuperAdmin(roles);
  const clientName = useSelector(getSelectedClientName);

  const createGroup = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!editName) {
      toast.error('Missing required field(s)');
      return;
    } else if (editName.length < 3) {
      toast.error('Invalid name');
      return;
    }

    setLoading(true);

    let method = 'POST';
    const url = `${apiEndpoint}/client/assets/groups`;

    const body = new FormData();
    body.append('name', editName);
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: Group = await response.json();

      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error saving the group.');
      }

      toast.success('Group saved!');
      history.push(`/group/${json.id}/add-asset?new=true`);
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  return (
    <Container>
      <Steps count={3} index={0} type="group" />
      <Form onSubmit={createGroup as any}>
        <H1>Create Group{isSuper ? ` in ${clientName}` : ''}</H1>
        <Fieldset>
          <FieldWrapper label="Name">
            <Input
              style={{ width: 440 }}
              type="text"
              onChange={(e: any) => setName(e.currentTarget.value)}
            />
          </FieldWrapper>
        </Fieldset>
        <Row>
          <Button
            style={{ marginRight: 10 }}
            disabled={loading}
            onClick={createGroup}
            label="Next"
          />
          <Button
            disabled={loading}
            appearance="secondary"
            to="/group"
            label="Cancel"
          />
        </Row>
      </Form>
    </Container>
  );
};
