const colors = {
  background: {
    darker: '#1B202D',
    dark: '#3D4455',
    light: '#FAFBFF',
    medium: '#F3F3FA',
  },
  border: {
    light: '#F4F5F6',
    medium: '#ECECED',
  },
  brand: {
    main: '#D43630',
  },
  error: '#FA4747',
  text: {
    100: '#1B202D',
    88: '#494D57',
    66: '#6D7179',
    44: '#8A8D94',
    33: '#B7B8B8',
    22: '#CFCFCF',
    11: '#E7E7E7',
    0: '#ffffff',
  },
  hover: '#F3F3F3',
  input: {
    bg: {
      default: '#ffffff',
      disabled: '#dedede',
      focus: '#ffffff',
      hover: '#FAFBFC',
    },
    border: {
      default: '#ECECED',
      disabled: '#dedede',
      focus: '#99a3c4',
      hover: '#dfdfe0',
    },
  },
  placeholder: '#9FA0A0',
  primary: {
    100: '#3F63F5',
    88: '#6582F7',
  },
  secondary: {
    100: '#1B202D',
    88: '#494D57',
  },
  tertiary: {
    100: '#11D4A0',
    88: '#2ED9AB',
  },
  selected: {
    dark: '#E6E8EA',
    light: 'rgba(37,39,39,.055)',
  },
  status: {
    potential: '#F7CB00',
    critical: '#F48282',
    pending: '#ECEDEF',
    healthy: '#00DAA4',
    predictive: '#6031E8',
  },
  graph: [
    '#394C5A',
    '#64B09E',
    '#B54539',
    '#B360F4',
    '#2758A3',
    '#FBBC05',
    '#3F63F5',
  ],
  white: '#ffffff',
};

export default colors;
