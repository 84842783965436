import { select, put } from 'redux-saga/effects';

import {
  getApiEndpoint,
  setApiEndpoint,
  API_ENDPOINTS,
  DEFAULT_API_ENDPOINT,
} from './slice';

export function* checkApiEndpoint() {
  const apiEndpoint: string = yield select(getApiEndpoint);
  if (!Object.values(API_ENDPOINTS).includes(apiEndpoint)) {
    yield put(setApiEndpoint(DEFAULT_API_ENDPOINT));
  }
}
