import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router-dom';
import toast from 'react-hot-toast';

import { theme } from '@awareness-ui/design';
import {
  H1,
  MobileList,
  Pagination,
  TableMainView,
  TablePageLeft,
  TableHead,
  TableLayout,
  TableBody,
  TableHeaderRow,
  TableHeaderCell,
  UserActivityMobileListItem,
  SortLabel,
  TableContainer,
  TablePageHeader,
  UserActivityListItem,
} from '@awareness-ui/components';
import { useWindowSize } from '@awareness-ui/hooks';
import { UserActivity } from '@awareness/types';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { getToken } from '@awareness/auth';

function getQueryParams(
  userId?: string,
  pageSize?: number,
  pageNumber?: number
): string {
  const userIdQuery = userId !== undefined && `user_id=${userId}`;
  const pageSizeQuery = pageSize !== undefined && `page_size=${pageSize}`;
  const pageNumberQuery =
    pageNumber !== undefined && `page_number=${pageNumber + 1}`;
  const queryParams = [userIdQuery, pageSizeQuery, pageNumberQuery]
    .filter(Boolean)
    .join('&');
  return queryParams ? `?${queryParams}` : '';
}

interface Props extends RouteChildrenProps {
  userId?: string;
}

export const UserActivityList: React.FC<Props> = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [activities, setActivities] = useState<UserActivity[]>([]);

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;

  useEffect(() => {
    fetchActivities();
  }, [pageSize, nextPageNumber]);

  const fetchActivities = async () => {
    setLoading(true);

    const queryParams = getQueryParams(userId, pageSize, nextPageNumber);
    const url = `${apiEndpoint}/client/audits${queryParams}`;

    try {
      const response = await apiFetchAsync({ url, token });
      const json: UserActivity[] = await response.json();

      if (response.status !== 200 || !Array.isArray(json)) {
        throw new Error('Uh-oh! There was an error loading user activities.');
      }

      setActivities(json);

      if (!json.length) {
        toast('0 activities match your search', {
          title: 'No Activities',
        } as any);
      }
    } catch (err) {
      toast.error(`${err}`, {
        actionLabel: 'Try Again',
        onAction: fetchActivities,
      } as any);
    }

    setLoading(false);
    setPageNumber(nextPageNumber);
  };

  const pageCount =
    (activities.length >= pageSize ? pageNumber + 1 : pageNumber) + 1;

  const noActivitiesMessage = loading ? 'Loading...' : 'No Activities';

  return (
    <TableMainView>
      <TableContainer style={isMobile ? undefined : { padding: 16 }}>
        <TablePageHeader>
          <TablePageLeft>
            <H1>User Activity List</H1>
          </TablePageLeft>
        </TablePageHeader>
        <>
          {activities.length === 0 ? (
            noActivitiesMessage
          ) : isMobile ? (
            <MobileList disabled={loading}>
              {activities.map((a, i) => {
                return (
                  <UserActivityMobileListItem
                    {...a}
                    key={`${a.user_id}-${i}`}
                  />
                );
              })}
            </MobileList>
          ) : (
            <TableLayout disabled={loading}>
              <TableHead>
                <TableHeaderRow>
                  <TableHeaderCell>
                    <SortLabel label="User" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <SortLabel label="Event" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <SortLabel label="Time" />
                  </TableHeaderCell>
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                {activities.map((a, i) => (
                  <UserActivityListItem key={`${a.user_id}-${i}`} {...a} />
                ))}
              </TableBody>
            </TableLayout>
          )}
          <Pagination
            pageCount={pageCount}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageSize={setPageSize}
            setPageNumber={setNextPageNumber}
          />
        </>
      </TableContainer>
    </TableMainView>
  );
};
