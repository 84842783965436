import React from 'react';
import styled from 'styled-components';

import { toRem, theme } from '@awareness-ui/design';
import { Label, Message } from '../Typography';

interface FieldWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  optional?: boolean;
  message?: string;
  indentMessage?: boolean;
  hasError?: boolean;
  action?: any;
  onAction?: () => void;
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${toRem(16.5)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: ${toRem(8)};
`;

const Feedback = styled.section`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-top: ${toRem(8)};
`;

const StyledMessage = styled(Message)`
  text-align: left;
`;

const Action = styled(Message)`
  font-weight: ${theme.font.weight.semiBold};
  text-decoration: underline;
  color: ${theme.color.primary[100]};
  cursor: pointer;
`;

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  children,
  label,
  optional,
  hasError,
  message,
  indentMessage = false,
  action,
  onAction,
  ...props
}) => {
  return (
    <Layout {...props}>
      <Header>
        {label && (
          <Label>
            {label} {optional && <span>(optional)</span>}
          </Label>
        )}
        {action ? (
          typeof action === 'string' ? (
            <Action onClick={onAction}>{action}</Action>
          ) : (
            action
          )
        ) : null}
      </Header>
      {children}

      <Feedback>
        {message && (
          <StyledMessage hasError={hasError} indent={indentMessage}>
            {message}
          </StyledMessage>
        )}
      </Feedback>
    </Layout>
  );
};

export default FieldWrapper;
