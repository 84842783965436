import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

const StyledLink = styled(Link)`
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.75)};
  line-height: 100%;
  text-decoration: underline;
`;

export default StyledLink;
