import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { User } from '@awareness/types';
import { getFiltersQueryParams } from '@awareness/assets';
import { apiFetchAsync } from '@awareness/api-fetch';
import { theme } from '@awareness-ui/design';
import {
  Button,
  H1,
  H3,
  Subtext,
  TableMainView,
  TableContainer,
  MobileList,
  AccountMobileListItem,
  TableLayout,
  TableHead,
  TableBody,
  TableHeaderRow,
  TableHeaderCell,
  SortLabel,
  AccountListItem,
  Pagination,
  Icon,
  Steps,
} from '@awareness-ui/components';
import { useWindowSize } from '@awareness-ui/hooks';

const MainView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

const SelectedView = styled.div`
  display: flex;
  width: 0;
  height: 0;
  flex-direction: column;
  background-color: ${theme.color.white};
  border-left: 1px solid ${theme.color.border.medium};
  transition: ${theme.transition.nav};
  position: relative;
  z-index: ${theme.zIndex.sideview};
  overflow: visible;
  margin-bottom: ${theme.header.height}px;
  border-width: 1px;
  padding: 24px 16px;

  @media (max-width: ${theme.breakpoint.small}px) {
    min-width: 100%;
    min-height: 65%;
    border-radius: 14px 14px 0 0;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
    margin-top: -10px;
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoint.small}px) {
    min-width: ${theme.map.detailView.medium}px;
    height: auto;
  }

  @media (min-width: ${theme.breakpoint.large}px) {
    min-width: ${theme.map.detailView.large}px;
    height: auto;
  }
`;

const SelectedListItem = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 5px;
  background-color: ${theme.color.background.light};
  margin-bottom: 10px;
  height: 50px;
  padding: 10px;
`;

const Name = styled(H3)`
  font-weight: ${theme.font.weight.medium};
`;

const CloseIcon = styled(Icon).attrs({ icon: 'Close' })`
  cursor: pointer;
  box-sizing: content-box;
  align-self: flex-end;
  min-width: 16px;
  min-height: 16px;
  border-radius: 16px;
  padding: 6px;
  background-color: ${theme.color.background.medium};
  margin-left: 16px;
`;

interface Props extends RouteChildrenProps {}

export const GroupUserSelection: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedIds, setSelectedIds] = useState<Map<number, User>>(new Map());

  const apiEndpoint = useSelector(getApiEndpoint);
  const token = useSelector(getToken);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < theme.breakpoint.med;

  useEffect(() => {
    fetchUsers();
  }, [pageSize, nextPageNumber]);

  const fetchUsers = async () => {
    setLoading(true);

    const queryParams = getFiltersQueryParams([], pageSize, nextPageNumber);
    const url = `${apiEndpoint}/client/accounts${queryParams}`;

    try {
      const response = await apiFetchAsync({ url, token });
      const json: User[] = await response.json();

      if (response.status !== 200 || !Array.isArray(json)) {
        throw new Error('Uh-oh! There was an error loading users.');
      }

      setUsers(json);

      if (!json.length) {
        toast('0 users match your search', { title: 'No Users' } as any);
      }
    } catch (err) {
      toast.error(`${err}`, {
        actionLabel: 'Try Again',
        onAction: fetchUsers,
      } as any);
    }

    setLoading(false);
    setPageNumber(nextPageNumber);
  };

  const addUsers = async () => {
    setLoading(true);

    const method = 'PUT';
    const url = `${apiEndpoint}/client/assets/groups/${id}/users`;
    const body = new FormData();
    selectedIds.forEach((i) => body.append('add[]', `${i.id}`));
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      if (response.status !== 204) {
        throw new Error('Uh-oh! There was an error adding users.');
      }

      props.history.push(`/group/${id}`);
      toast.success('Users added!');
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const toggleSelectedId = (user: User) => {
    const ids = new Map(selectedIds);
    if (ids.get(user.id)) {
      ids.delete(user.id);
      setSelectedIds(ids);
      return;
    }

    const search = users.find((u) => u === user);
    if (search) {
      ids.set(search.id, search);
      setSelectedIds(ids);
    }
  };

  const pageCount =
    (users.length >= pageSize ? pageNumber + 1 : pageNumber) + 1;
  const noUsersMessage = loading ? 'Loading...' : 'No users';
  const id: string = (props.match?.params || ({} as any)).id;
  const isNewGroup = location.search.includes('new=true');

  return (
    <MainView>
      {isNewGroup && <Steps count={3} type="group" index={2} groupId={id} />}
      <TableMainView>
        <TableContainer style={isMobile ? { marginBottom: 0 } : undefined}>
          <>
            {users.length === 0 ? (
              noUsersMessage
            ) : isMobile ? (
              <MobileList disabled={loading}>
                {users.map((u) => {
                  return (
                    <AccountMobileListItem
                      {...u}
                      key={u.id}
                      onClick={() => toggleSelectedId(u)}
                      disabled={selectedIds.has(u.id)}
                    />
                  );
                })}
              </MobileList>
            ) : (
              <TableLayout disabled={loading}>
                <TableHead>
                  <TableHeaderRow>
                    <TableHeaderCell>
                      <SortLabel label="Email" />
                    </TableHeaderCell>
                    <TableHeaderCell>
                      <SortLabel label="Role" />
                    </TableHeaderCell>
                  </TableHeaderRow>
                </TableHead>
                <TableBody>
                  {users.map((u) => (
                    <AccountListItem
                      key={u.id}
                      {...u}
                      onClick={() => toggleSelectedId(u)}
                      disabled={selectedIds.has(u.id)}
                    />
                  ))}
                </TableBody>
              </TableLayout>
            )}
            <Pagination
              pageCount={pageCount}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={setPageSize}
              setPageNumber={setNextPageNumber}
            />
          </>
        </TableContainer>
        <SelectedView>
          <H1>Choose Users</H1>
          <Subtext style={{ marginBottom: 16 }}>
            {selectedIds.size
              ? `Selected users (${selectedIds.size})`
              : `No users selected`}
          </Subtext>
          {Array.from(selectedIds.values()).map((user) => {
            return (
              <SelectedListItem key={user.id}>
                <Name>{user?.name || ''}</Name>
                <CloseIcon onClick={() => toggleSelectedId(user)} />
              </SelectedListItem>
            );
          })}
          <Button
            label={'Save'}
            disabled={selectedIds.size === 0}
            onClick={addUsers}
          />
        </SelectedView>
      </TableMainView>
    </MainView>
  );
};
