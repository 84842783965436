import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  max-width: 650px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 10px;
  position: relative;
`;

const Step = styled.div<{ count: number }>`
  position: relative;
  width: ${({ count }) => 100 / (count - 1)}%;
`;

const Line = styled.div<{ completed: boolean }>`
  height: 6px;
  background-color: ${({ completed }) =>
    completed ? theme.color.primary[100] : theme.color.background.medium};
  position: absolute;
  top: 7px;
  z-index: -1;
  width: 100%;
`;

const StepStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18px;
  padding: 0 8px;
  white-space: nowrap;
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.light};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  color: ${theme.color.text[44]};
  text-decoration: none;
`;

const StepLink = styled(Link)`
  ${StepStyles}
`;

const StepDiv = styled.div`
  ${StepStyles}
`;

const Dot = styled.div<{ completed: boolean; current: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-bottom: 8px;
  box-shadow: ${theme.shadow.mapcontrols};
  background-color: ${({ completed }) =>
    completed ? theme.color.primary[100] : theme.color.background.medium};

  ${({ current }) =>
    current &&
    css`
      border: 2px solid ${theme.color.primary[100]};
    `};
`;

const getReportTo = (i: number, channel?: string, idsStr?: string) => {
  if (i === 0) {
    return '/reports';
  } else if (i === 1 && channel) {
    return `/reports/${channel}`;
  } else if (i === 3 && channel && idsStr) {
    return `/reports/${channel}/${idsStr}`;
  }
};

interface Props {
  count: number;
  index: number;
  type: 'report' | 'group';
  channel?: string;
  idsStr?: string;
  groupId?: string;
}

export const Steps: React.FC<Props> = ({
  count,
  index,
  type,
  channel,
  idsStr,
  groupId,
}) => {
  const steps = Array(count).fill('');

  return (
    <Container>
      {steps.map((s, i) => {
        const completed = i < index;
        let to: any;
        if (completed) {
          if (type === 'report') {
            to = getReportTo(i, channel, idsStr);
          } else if (type === 'group' && groupId) {
            to = `/group/${groupId}`;
          }
        }
        const StepEl: any = to ? StepLink : StepDiv;

        if (i + 1 === count) {
          return (
            <StepEl key={`step-${i}`} to={to}>
              <Dot completed={completed} current={i === index} />
              {s || `Step ${i + 1}`}
            </StepEl>
          );
        }

        return (
          <Step count={count} key={`step-${i}`}>
            <Line completed={completed} />
            <StepEl to={to}>
              <Dot completed={i < index} current={i === index} />
              {s || `Step ${i + 1}`}
            </StepEl>
          </Step>
        );
      })}
    </Container>
  );
};
