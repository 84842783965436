export * from './date-time';
export * from './form-validation';
export * from './measurement-props';
export * from './roles-form-data';
export * from './status';
import { ChannelMeasurement, Measurement } from '@awareness/types';
import { ChannelName } from '@awareness/util';
import * as XLSX from 'xlsx';

export function capitalize(str: string) {
  if (!str) {
    return '';
  }
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function timeSeriesToCSV(
  data: {
    columns: string[];
    name: string;
    utc: boolean;
    points: Array<Array<string>>;
  },
  unit: ChannelName
) {
  const header: string[] = data?.columns;
  const dataRows: Array<string | null> = data?.points
    .map((row: any) => {
      let rowData = row
        .slice(1)
        .map(
          (el: number) =>
            (el === null ? '' : +el.toFixed(2)) + ' (' + unit + ')'
        );
      if (rowData?.join('').length === 0) return null;
      return [
        new Date(row[0]).toLocaleString().replace(',', ' -'),
        ...rowData,
      ].join(',');
    })
    .filter((el) => el && el.length > 0);
  return [header?.join(','), ...(dataRows || [])].join('\n');
}

export function airTemperatureValueConversion(
  value: number,
  fromUnit: string = '°C',
  toUnit: string
): number {
  if (fromUnit === toUnit) {
    return value;
  }
  if (toUnit === '°C') {
    if (fromUnit === '°F') {
      // Fahrenheit to Celsius
      return ((value - 32) * 5) / 9;
    } else if (fromUnit === 'K') {
      // kelvin to celsius
      return value - 273.15;
    }
  } else if (toUnit === '°F') {
    // Celsius to Fahrenheit
    if (fromUnit === '°C' || fromUnit === '°') {
      return (value * 9) / 5 + 32;
    } else if (fromUnit === 'K') {
      // Kelvin to Fahrenheit
      return ((value - 273.15) * 9) / 5 + 32;
    }
  } else if (toUnit === 'K') {
    if (fromUnit === '°F') {
      //  Fahrenheit to Kelvin
      return ((value - 32) * 5) / 9 + 273.15;
    } else if (fromUnit === '°C') {
      // Fahrenheit to celsius
      return value + 273.15;
    }
  }

  return value;
}

export function barometricPressureValueConversion(
  value: number,
  fromUnit: string = 'hPa',
  toUnit: string
): number {
  if (fromUnit === toUnit || (!fromUnit && !toUnit)) {
    return value;
  }
  const unitConversions: Record<string, Record<string, number>> = {
    hPa: {
      psi: 0.0145038,
      mbar: 1,
      inHg: 0.0295299,
    },
    psi: {
      hPa: 68.9476,
      mbar: 68.9476,
      inHg: 2.03602,
    },
    mbar: {
      hPa: 1,
      psi: 0.0145038,
      inHg: 0.0295299,
    },
    inHg: {
      hPa: 33.8639,
      psi: 0.491154,
      mbar: 33.8639,
    },
  };

  return value * unitConversions[fromUnit][toUnit];
}

export function rainDiffValueConversion(
  value: number,
  fromUnit: string = 'in',
  toUnit: string
): number {
  if (fromUnit === toUnit) {
    return value;
  }
  const unitConversions: Record<string, number> = {
    mlToInches: 0.0610237,
    inchesToMl: 16.3871,
  };

  if (fromUnit === 'ml' && toUnit === 'in') {
    return value * unitConversions.mlToInches;
  } else if (fromUnit === 'in' && toUnit === 'ml') {
    return value * unitConversions.inchesToMl;
  } else {
    return 0;
  }
}

export function windSpeedValueConversion(
  value: number,
  fromUnit: string = 'mps',
  toUnit: string
): number {
  if (fromUnit === toUnit) return value;
  // Define unit conversion constants
  const mpsToFPS = 3.28084; // 1 m/s = 3.28084 ft/s
  const mpsToMPH = 2.23694; // 1 m/s = 2.23694 mph

  // Normalize input and target units to lowercase for comparison
  fromUnit = fromUnit.toLowerCase();
  toUnit = toUnit.toLowerCase();

  // Perform the conversion based on the source and target units
  if (fromUnit === 'mps' || fromUnit === '') {
    if (toUnit === 'fps') {
      return value * mpsToFPS;
    } else if (toUnit === 'mph') {
      return value * mpsToMPH;
    }
  } else if (fromUnit === 'fps') {
    if (toUnit === 'mps') {
      return value / mpsToFPS;
    } else if (toUnit === 'mph') {
      return (value / mpsToFPS) * mpsToMPH;
    }
  } else if (fromUnit === 'mph') {
    if (toUnit === 'mps') {
      return value / mpsToMPH;
    } else if (toUnit === 'fps') {
      return (value / mpsToMPH) * mpsToFPS;
    }
  }

  return value;
}

export function getWindDirection(degrees: number): string {
  if (degrees <= 11.25 || degrees > 348.75) {
    return 'N';
  } else if (degrees > 11.25 && degrees <= 33.75) {
    return 'NNE';
  } else if (degrees > 33.75 && degrees <= 56.25) {
    return 'NE';
  } else if (degrees > 56.25 && degrees <= 78.75) {
    return 'ENE';
  } else if (degrees > 78.75 && degrees <= 101.25) {
    return 'E';
  } else if (degrees > 101.25 && degrees <= 123.75) {
    return 'ESE';
  } else if (degrees > 123.75 && degrees <= 146.25) {
    return 'SE';
  } else if (degrees > 146.25 && degrees <= 168.75) {
    return 'SSE';
  } else if (degrees > 168.75 && degrees <= 191.25) {
    return 'S';
  } else if (degrees > 191.25 && degrees <= 213.75) {
    return 'SSW';
  } else if (degrees > 213.75 && degrees <= 236.25) {
    return 'SW';
  } else if (degrees > 236.25 && degrees <= 258.75) {
    return 'WSW';
  } else if (degrees > 258.75 && degrees <= 281.25) {
    return 'W';
  } else if (degrees > 281.25 && degrees <= 303.75) {
    return 'WNW';
  } else if (degrees > 303.75 && degrees <= 326.25) {
    return 'NW';
  } else if (degrees > 326.25 && degrees <= 348.75) {
    return 'NNW';
  } else {
    return '';
  }
}

export const downloadExcel = (
  data: any,
  unit: string,
  channelName: ChannelName
) => {
  const formattedData = data.reduce(
    (memo: any[], d: any) => {
      const [timestamp, value] = d;
      const date = new Date(timestamp);
      const now = new Date();
      // Display time only if data points have the same year and month
      const formattedTime = date.toLocaleTimeString([], {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      memo.push([formattedTime, value]);
      return memo;
    },
    [['Date', `Value (${unit})`]]
  );
  const ws = XLSX.utils.json_to_sheet(formattedData);
  ws['!cols'] = [
    { wch: 30 }, // Column A width: 15 characters
    { wch: 20 }, // Column B width: 20 characters
    { wch: 25 }, // Column C width: 25 characters
  ];
  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, channelName);
  XLSX.writeFile(wb, `${channelName}.xlsx`);
};

export const downloadWindDataExcel = (
  data: any,
  unit: string,
  channelName: ChannelName
) => {
  const formattedData = data.reduce(
    (memo: any[], d: any) => {
      const { timestamp, value } = d;
      const [windSpeed, windAngle] = JSON.parse(value);
      const date = new Date(timestamp);
      const now = new Date();
      // Display time only if data points have the same year and month
      const formattedTime = date.toLocaleTimeString([], {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      memo.push([formattedTime, windSpeed, getWindDirection(windAngle)]);
      return memo;
    },
    [['Date', `Value (${unit})`, 'Direction']]
  );
  const ws = XLSX.utils.json_to_sheet(formattedData);
  ws['!cols'] = [
    { wch: 30 }, // Column A width: 15 characters
    { wch: 20 }, // Column B width: 20 characters
    { wch: 25 }, // Column C width: 25 characters
  ];
  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, channelName);
  XLSX.writeFile(wb, `${channelName}.xlsx`);
};
