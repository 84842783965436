const shadow = {
  mapcontrols: '0px 0px 3px rgba(0, 0, 0, 0.4)',
  card: '2px 2px 10px rgba(0, 0, 0, 0.15)',
  dialog:
    '0px 18px 85px rgba(0, 0, 0, 0.07), 0px 7.51997px 35.511px rgba(0, 0, 0, 0.0503198), 0px 4.02054px 18.9859px rgba(0, 0, 0, 0.0417275), 0px 2.25388px 10.6433px rgba(0, 0, 0, 0.035), 0px 1.19702px 5.65259px rgba(0, 0, 0, 0.0282725), 0px 0.498106px 2.35217px rgba(0, 0, 0, 0.0196802)',
  modal:
    '0px 42px 141px rgba(0, 0, 0, 0.04), 0px 17.5466px 58.9064px rgba(0, 0, 0, 0.0287542), 0px 9.38125px 31.4942px rgba(0, 0, 0, 0.0238443), 0px 5.25905px 17.6554px rgba(0, 0, 0, 0.02), 0px 2.79304px 9.37664px rgba(0, 0, 0, 0.0161557), 0px 1.16225px 3.90183px rgba(0, 0, 0, 0.0112458);',
};

export default shadow;
