import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import {
  Button,
  FieldWrapper,
  Input,
  H1,
  Text,
} from '@awareness-ui/components';
import { toRem } from '@awareness-ui/design';
import { getEmailError } from '@awareness/util';
import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Form = styled.form`
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
  max-width: 400px;
`;

const Fieldset = styled.fieldset`
  margin: ${toRem(33)} 0;
  width: auto;
  max-width: 400px;
`;

interface Props extends RouteChildrenProps {}

export const ResetPassword: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);

  const onSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (getEmailError(email)) {
      toast.error('Invalid email');
      return;
    }

    setLoading(true);
    const url = `${apiEndpoint}/auth/forgot`;
    const body = new FormData();
    body.append('email', email);
    const method = 'POST';

    try {
      const response = await apiFetchAsync({ url, token, method, body });

      if (response.status !== 204) {
        throw new Error(
          "Uh-oh! We couldn't reset the password for that email."
        );
      }

      toast.success('Password reset!', { title: 'Check your email' } as any);
      setSubmitted(true);
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  const emailError = submitted ? getEmailError(email) : undefined;

  return (
    <Container>
      <Form onSubmit={onSubmit as any}>
        {submitted ? (
          <>
            <Text
              size="small"
              style={{ textAlign: 'center', marginBottom: 20 }}>
              If an account with that email address exists, we've reset it's
              password.
            </Text>
            <Button to="/login" label="Back" />
          </>
        ) : (
          <>
            <H1>Reset Password</H1>
            <Text style={{ textAlign: 'center' }}>
              Enter your email address and we'll send you an email with a new
              password.
            </Text>
            <Fieldset disabled={loading}>
              <FieldWrapper
                label="Email"
                message={emailError}
                hasError={!!emailError}>
                <Input
                  type="email"
                  placeholder="you@email.com"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </FieldWrapper>
            </Fieldset>
            <Button label="Submit" onClick={onSubmit} />
          </>
        )}
      </Form>
    </Container>
  );
};
