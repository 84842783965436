import { PURGE, REHYDRATE } from 'redux-persist';
import { takeEvery, call, put } from 'redux-saga/effects';
import { createAction } from 'robodux';

import { apiFetch } from '@awareness/api-fetch';

const clearCache = () => ({
  type: PURGE,
  key: 'root',
  result: () => null,
});

function* onLogout() {
  yield call(apiFetch, {
    endpoint: '/logout',
    method: 'POST',
  });

  yield put(clearCache());

  yield put({ type: REHYDRATE });
}

export const logout = createAction('LOGOUT');

function* logoutSaga() {
  yield takeEvery(`${logout}`, onLogout);
}

export const sagas = { logoutSaga };
