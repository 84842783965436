import { createSlice } from 'robodux';

import { ReduxState } from '@awareness/types';
import { DeveloperSettingsState } from '@awareness/types/developer-settings';
import { createSelector } from 'reselect';

const initialState = {
  modalOpen: false,
  poleDetailLocationVisible: false,
};

const { actions, reducer, name } = createSlice({
  name: 'developerSettings',
  initialState,
  reducers: {
    setDeveloperSettings: (_, payload: DeveloperSettingsState) => payload,
    patchDeveloperSettings: (
      state,
      payload: Partial<DeveloperSettingsState>
    ) => ({ ...state, ...payload }),
    resetDeveloperSettings: () => initialState,
  },
});
export const {
  setDeveloperSettings,
  patchDeveloperSettings,
  resetDeveloperSettings,
} = actions;

export const getDeveloperSettings = (state: ReduxState) =>
  state.developerSettings;

export const getDeveloperSettingsModalOpen = createSelector(
  getDeveloperSettings,
  (d) => d.modalOpen
);
export const getPoleDetailLocationVisible = createSelector(
  getDeveloperSettings,
  (d) => d.poleDetailLocationVisible
);

const reducers = { [name]: reducer };

export { reducers };
