import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { theme, toRem } from '@awareness-ui/design';
import {
  formatUserRole,
  getIsSuperAdmin,
  getSelectedClientLogo,
  getUserName,
  getUserRoles,
} from '@awareness/user';
import haydenLogo from '@awareness-ui/images/hayden-logo.png';

import { H4 } from '../Typography';
import { Icon } from '../Icon';
import AlertsModal from './AlertsModal';

interface HeaderProps {
  isOpen?: boolean;
}

const Layout = styled.header`
  display: flex;
  z-index: ${theme.zIndex.header};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${toRem(theme.header.height)};
  min-height: ${toRem(theme.header.height)};
  background-color: ${theme.color.white};
  border-bottom: 1px solid ${theme.color.border.medium};
`;

const AccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${toRem(11)};
`;

const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: ${toRem(11)};
  background: ${theme.color.white};
`;

const AccountDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${toRem(11)};
  padding-top: ${toRem(5)};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.medium};
  font-size: ${toRem(13.25)};
  line-height: 100%;
`;

const MenuButton = styled.button.attrs({})<Partial<HeaderProps>>`
  border-radius: ${toRem(11)};
  width: ${toRem(44)};

  @media (min-width: ${theme.breakpoint.small}px) {
    display: none;
  }
`;

const IconButton = styled.div`
  cursor: pointer;
  padding: ${toRem(14)};
`;

const UserLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: ${theme.color.text[100]};
`;

interface Props {
  navOpen: boolean;
  setNavOpen: (o: boolean) => void;
}

const Header: React.FC<Props> = ({ navOpen, setNavOpen }) => {
  const [alertsOpen, setAlertsOpen] = useState(false);
  const name = useSelector(getUserName);
  const logo = useSelector(getSelectedClientLogo);
  const roles = useSelector(getUserRoles);
  const isSuper = getIsSuperAdmin(roles);
  const toggleAlertsOpen = () => setAlertsOpen(!alertsOpen);

  let avatarSrc = isSuper ? haydenLogo : logo || haydenLogo;

  return (
    <Layout>
      <AccountContainer>
        <MenuButton isOpen={navOpen} onClick={() => setNavOpen(!navOpen)}>
          <Icon
            icon="Menu"
            fill={theme.color.text}
            width={16.5}
            height={16.5}
          />
        </MenuButton>
        <UserLink to="/me">
          {roles !== undefined && <Avatar src={avatarSrc} />}
          <AccountDetails>
            <div>{name}</div>
            <H4>{formatUserRole(roles)}</H4>
          </AccountDetails>
        </UserLink>
      </AccountContainer>
      {/* <div>
        <IconButton onClick={toggleAlertsOpen}>
          <Icon icon="Bell" width={16.5} height={16.5} />
        </IconButton>
        <AlertsModal isActive={alertsOpen} onClose={toggleAlertsOpen} />
      </div> */}
    </Layout>
  );
};

export default Header;
