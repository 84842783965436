import React, { useState } from 'react';

import {
  Account,
  Alert,
  Asset,
  Client,
  Group,
  UserActivity,
} from '@awareness/types';
import { theme, toRem } from '@awareness-ui/design';
import styled from 'styled-components';

import { Status } from '../Status';
import { H3, H4, Label } from '../Typography';
import { Icon } from '../Icon';
import { formatDate } from '@awareness/util';

const Layout = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  border-bottom: 1px solid ${theme.color.border.medium};
  background-color: ${({ disabled }) =>
    disabled ? theme.color.background.medium : 'none'};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${toRem(16)};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled(H3)`
  font-weight: ${theme.font.weight.medium};
  margin-bottom: ${toRem(4)};
`;

const Serial = styled(H4)`
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(11)};
  text-transform: uppercase;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Dropdown = styled.div`
  padding: ${toRem(16)};
  margin-right: -${toRem(16)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 ${toRem(16)};
  padding-bottom: ${toRem(16)};
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${toRem(12)} 0;
`;

const LeftDetail = styled(Label)`
  text-decoration: uppercase;
  flex: none;
`;

const RightDetail = styled(Label)`
  flex: none;
`;

const Divider = styled.div`
  display: flex;
  height: ${toRem(1)};
  width: 100%;
  background-color: ${theme.color.border.light};
  margin: 0 ${toRem(16)};
`;

interface AssetProps extends Asset {
  onClick: () => void;
  disabled?: boolean;
}

export const AssetMobileListItem: React.FC<AssetProps> = ({
  name,
  id,
  status,
  online,
  onClick,
  type,
  disabled,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Layout onClick={onClick} disabled={disabled}>
      <Header>
        <Left>
          <Name>{name}</Name>
          <Serial>{id}</Serial>
        </Left>
        <Right>
          <Status status={status} />
          <Dropdown
            onClick={(ev) => {
              setExpanded(!isExpanded);
              ev.stopPropagation();
            }}>
            {isExpanded ? (
              <Icon icon="DirectionUp" width={14} height={14} />
            ) : (
              <Icon icon="DirectionDown" width={14} height={14} />
            )}
          </Dropdown>
        </Right>
      </Header>
      {isExpanded ? (
        <Content>
          <DetailRow>
            <LeftDetail>connection</LeftDetail>
            <Divider />
            <RightDetail>{online ? 'Online' : 'Offline'}</RightDetail>
          </DetailRow>
          <DetailRow>
            <LeftDetail>type</LeftDetail>
            <Divider />
            <RightDetail>{type}</RightDetail>
          </DetailRow>
        </Content>
      ) : null}
    </Layout>
  );
};

interface AlertProps extends Alert {
  onClick: () => void;
}

export const AlertMobileListItem: React.FC<AlertProps> = ({
  channel_name,
  severity,
  timestamp,
  asset_id,
  onClick,
}) => {
  const date = new Date(timestamp);
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Layout onClick={onClick}>
      <Header>
        <Left>
          <Name>{channel_name}</Name>
        </Left>
        <Right>
          <Status status={severity} />
          <Dropdown
            onClick={(ev) => {
              setExpanded(!isExpanded);
              ev.stopPropagation();
            }}>
            {isExpanded ? (
              <Icon icon="DirectionUp" width={14} height={14} />
            ) : (
              <Icon icon="DirectionDown" width={14} height={14} />
            )}
          </Dropdown>
        </Right>
      </Header>
      {isExpanded ? (
        <Content>
          <DetailRow>
            <LeftDetail>date</LeftDetail>
            <Divider />
            <RightDetail>{formatDate(date)}</RightDetail>
          </DetailRow>
          <DetailRow>
            <LeftDetail>name</LeftDetail>
            <Divider />
            <RightDetail>{asset_id}</RightDetail>
          </DetailRow>
        </Content>
      ) : null}
    </Layout>
  );
};

interface GroupProps extends Group {
  onClick: () => void;
}

export const GroupMobileListItem: React.FC<GroupProps> = ({
  name,
  onClick,
}) => {
  return (
    <Layout onClick={onClick}>
      <Header>
        <Left>
          <Name>{name}</Name>
        </Left>
      </Header>
    </Layout>
  );
};

interface ClientProps extends Client {
  onClick: () => void;
}

export const ClientMobileListItem: React.FC<ClientProps> = ({
  name,
  onClick,
}) => {
  return (
    <Layout onClick={onClick}>
      <Header>
        <Left>
          <Name>{name}</Name>
        </Left>
      </Header>
    </Layout>
  );
};

interface AccountProps extends Account {
  onClick?: () => void;
  disabled?: boolean;
}

export const AccountMobileListItem: React.FC<AccountProps> = ({
  name,
  email,
  onClick,
  disabled,
}) => {
  return (
    <Layout onClick={onClick} disabled={disabled}>
      <Header>
        <Left>
          <Name>{name}</Name>
        </Left>
        <Right>
          <Name>{email}</Name>
        </Right>
      </Header>
    </Layout>
  );
};

interface UserActivityProps extends UserActivity {
  onClick?: () => void;
}

export const UserActivityMobileListItem: React.FC<UserActivityProps> = ({
  user_id,
  onClick,
}) => {
  return (
    <Layout onClick={onClick}>
      <Header>
        <Left>
          <Name>{user_id}</Name>
        </Left>
      </Header>
    </Layout>
  );
};
