import React from 'react';

import { NavLink, NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';
import { IconTypes } from '../Icon/IconTypes';

interface NavItemProps extends NavLinkProps {
  label: string;
  icon: IconTypes;
  iconActive: IconTypes;
  to: string;
  itemActive?: boolean;
  navOpen?: boolean;
  onClick?: () => void;
  className?: string;
}

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: ${toRem(11)};
  border-radius: ${toRem(11)};
  transition: background-color ${theme.transition.button.default};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  text-decoration: none;
  color: ${theme.color.white};

  svg {
    fill: ${theme.color.white};
    transition: fill ${theme.transition.button.default};
  }

  &:hover {
    background-color: ${theme.color.primary[100]};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    background-color: ${theme.color.primary[100]};
    font-weight: ${theme.font.weight.semiBold};
    color: ${theme.color.white};

    svg {
      fill: ${theme.color.white};
    }
  }
`;

const Layout = styled.div<Partial<NavItemProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${toRem(44)};
  height: ${toRem(44)};
  padding: 0 0;
  border-radius: ${toRem(11)};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.medium};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  text-decoration: none;
  color: inherit;
  transition: background-color ${theme.transition.button.default};

  ${({ navOpen }) =>
    navOpen &&
    css`
      flex-flow: row nowrap;
      justify-content: flex-start;
      width: 100%;
      padding: 0 ${toRem(16)};
    `}
`;

const StyledIcon = styled(Icon).attrs({
  'aria-hidden': true,
})<any>`
  margin-right: ${(p) => (p.$navOpen ? toRem(11) : 0)};
`;

const Label = styled.span<Partial<NavItemProps>>`
  width: 0;
  visibility: hidden;
  opacity: 0;

  ${({ navOpen }) =>
    navOpen &&
    css`
      width: auto;
      visibility: visible;
      opacity: 1;
    `}
`;

const NavItem: React.FC<NavItemProps> = ({
  label,
  icon,
  iconActive,
  itemActive,
  navOpen,
  to,
  onClick,
  className,
  exact = true,
}) => {
  return (
    <StyledNavLink
      to={to}
      onClick={onClick}
      className={className}
      aria-label={`Navigate to ${label}`}
      exact={exact}>
      <Layout navOpen={navOpen}>
        <StyledIcon
          $navOpen={navOpen}
          icon={itemActive ? iconActive : icon}
          width={16.5}
          height={16.5}
        />
        <Label navOpen={navOpen}>{label}</Label>
      </Layout>
    </StyledNavLink>
  );
};

export default NavItem;
