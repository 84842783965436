import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { apiFetchAsync } from '@awareness/api-fetch';
import { getToken } from '@awareness/auth';
import { getApiEndpoint } from '@awareness/api-endpoint';
import { Client, User } from '@awareness/types';
import { theme, toRem } from '@awareness-ui/design';
import { setUser } from '@awareness/user';

import { FieldWrapper, Input } from '../Inputs';
import { Button } from '../Button';
import { Text, H1, Label, H4 } from '../Typography';
import { useDropzone } from 'react-dropzone';
import { Icon } from '../Icon';

const Form = styled.form`
  margin-top: 50px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${toRem(22)};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteButton = styled(Text)`
  color: ${theme.color.error};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${toRem(12)};
  margin-top: 8px;
`;

const Layout = styled.div`
  display: flex;
  min-height: 200px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.color.background.light};
  border-radius: ${toRem(10)};
  border: 1px dashed ${theme.color.primary[100]};
  padding-top: 10px;
  margin: 20px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DropdownChildren = styled.div<{ isVisible?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 0;
  overflow: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      height: auto;
      overflow: visible;
    `}
`;

const ToggleArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${toRem(20)};
  width: ${toRem(20)};
  background: ${theme.color.background.medium};
  border-radius: ${toRem(5)};
  cursor: pointer;
`;

const ListItemContainer = styled(H4)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  border-bottom: 1px solid ${theme.color.border.medium};
`;

const ONE_FILE_MESSAGE = 'Only one file can be uploaded at a time';

interface Props {
  client?: Client;
  user: User;
  isNewClient: boolean;
  onClose: () => void;
}

export const EditCompany: React.FC<Props> = ({
  client = {} as Client,
  user,
  isNewClient,
  onClose,
}) => {
  const loadFiles = useCallback((files: File[]) => {
    if (files.length > 1) {
      toast.error(ONE_FILE_MESSAGE);
    } else {
      setFile(files[0]);
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = (ev) => {
        if (ev.target) {
          setImgSrc(ev.target.result as string);
        }
      };
      reader.readAsDataURL(files[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop: loadFiles,
    multiple: false,
    accept: 'image/*, image/heif, image/heic',
  });
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>(client.name || '');
  const [slug, setSlug] = useState(client.slug || '');
  const [metricUnits, setMetricUnits] = useState(client.metric_units || false);
  const [customIdLabel, setCustomIdLabel] = useState(
    client.custom_id_label || 'Client Identifier'
  );
  const [conductorTypes, setConductorTypes] = useState<string[]>(
    client.conductor_types || []
  );
  const [file, setFile] = useState<File>();
  const [imgSrc, setImgSrc] = useState<string>('');

  const [isExpanded, setExpanded] = useState(false);

  const token = useSelector(getToken);
  const apiEndpoint = useSelector(getApiEndpoint);

  const dispatch = useDispatch();

  const { id } = client;

  const updateClient = async () => {
    if (!name) {
      toast.error('Please enter a name');
      return;
    }
    setLoading(true);

    let method = 'POST';
    let url = `${apiEndpoint}/admin/clients`;
    if (!isNewClient) {
      url += `/${id}`;
      method = 'PUT';
    }
    const body = new FormData();
    body.append('name', name);
    body.append('custom_id_label', customIdLabel);
    body.append('metric_units', `${metricUnits}`);
    conductorTypes.forEach((type) => {
      body.append(`conductor_types[]`, type);
    });
    if (!conductorTypes.length) {
      body.append(`conductor_types`, '');
    }

    if (slug) {
      body.append('slug', slug);
    }
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      const json: Client = await response.json();
      if (response.status !== (isNewClient ? 201 : 200)) {
        throw new Error('Uh-oh! There was an error saving the client.');
      }

      const logoError = await uploadLogo(json.id);
      if (logoError) {
        throw new Error(logoError);
      }

      const updatedClient = { ...user.client, ...json };
      dispatch(setUser({ ...user, client: updatedClient }));

      toast.success(`Client ${isNewClient ? 'created' : 'updated'}!`);
      onClose();
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const uploadLogo = async (clientId: number) => {
    if (!file) {
      return;
    }
    const method = 'PUT';
    const url = `${apiEndpoint}/admin/clients/${clientId}/logo`;
    const body = new FormData();
    body.append('image', file);
    try {
      const response = await apiFetchAsync({ url, token, method, body });
      if (response.status !== 200) {
        throw new Error('Uh-oh! There was an error saving the client.');
      }
    } catch (err) {
      return `${err}`;
    }
  };

  const deleteClient = async () => {
    setLoading(true);

    try {
      const clientsResponse = await apiFetchAsync({
        url: `${apiEndpoint}/admin/clients`,
        token,
      });
      const clients: Client[] = await clientsResponse.json();
      const newClientId = clients.filter((c) => c.id !== client.id)[0]?.id;
      if (newClientId === undefined) {
        throw new Error('Error: unable to delete the only client');
      }

      const body = new FormData();
      body.append('client_id', `${newClientId}`);
      const editUserResponse = await apiFetchAsync({
        url: `${apiEndpoint}/admin/accounts/${user.id}`,
        token,
        method: 'PUT',
        body,
      });
      const userJson: User = await editUserResponse.json();
      if (editUserResponse.status !== 200) {
        throw new Error('Uh-oh! There was an error changing your client.');
      }
      dispatch(setUser(userJson));

      const method = 'DELETE';
      const url = `${apiEndpoint}/admin/clients/${id}`;
      const response = await apiFetchAsync({ url, token, method });
      if (response.status !== 204) {
        throw new Error('Uh-oh! There was an error deleting the client.');
      }

      toast.success('Client deleted');
      onClose();
    } catch (err) {
      toast.error(`${err}`);
    }

    setLoading(false);
  };

  const openFileBrowser = () => inputRef.current?.click();

  const removeConductorType = (index: number) => {
    const newConductorTypes = [...conductorTypes];
    newConductorTypes.splice(index, 1);
    setConductorTypes(newConductorTypes);
  };

  return (
    <Form>
      <H1>{isNewClient ? 'Create' : 'Edit'} Company</H1>
      <Layout {...getRootProps()} onClick={undefined}>
        {file ? (
          <img src={imgSrc as any} style={{ maxHeight: 180 }} />
        ) : isDragActive ? (
          <Content>
            <Icon
              icon="Image"
              height={38}
              width={44}
              fill={theme.color.primary[100]}
            />
            Drag image here
          </Content>
        ) : (
          <Content>
            <Icon
              icon="Image"
              height={38}
              width={44}
              fill={theme.color.primary[100]}
            />
            Drag image here or
            <input {...getInputProps()} />
            <Button
              style={{ margin: 20 }}
              label="Choose file"
              size="small"
              onClick={openFileBrowser}
            />
          </Content>
        )}
      </Layout>
      <FieldWrapper label="Name">
        <Input
          disabled={loading}
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </FieldWrapper>
      {client.id && !slug ? null : (
        <FieldWrapper label="Slug">
          <Input
            disabled={loading}
            onChange={(e) => setSlug(e.target.value)}
            value={slug}
          />
        </FieldWrapper>
      )}
      <FieldWrapper label="Custom ID Label">
        <Input
          disabled={loading}
          onChange={(e) => setCustomIdLabel(e.target.value)}
          value={customIdLabel}
        />
      </FieldWrapper>
      <FieldWrapper
        label={`Conductor Types (${conductorTypes.length})`}
        action={
          <ToggleArrow onClick={() => setExpanded(!isExpanded)}>
            {isExpanded ? (
              <Icon icon="DirectionUp" width={11} height={11} />
            ) : (
              <Icon icon="DirectionDown" width={11} height={11} />
            )}
          </ToggleArrow>
        }>
        <DropdownChildren isVisible={isExpanded}>
          {conductorTypes.map((type, index) => (
            <ListItemContainer key={type + `${index}`}>
              {type}
              <Button
                label="Delete"
                size="small"
                onClick={() => removeConductorType(index)}
              />
            </ListItemContainer>
          ))}
          <Label style={{ margin: '5px 0' }}>
            Add (hit return key to submit)
          </Label>
          <Input
            disabled={loading}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setConductorTypes([...conductorTypes, e.currentTarget.value]);
                e.currentTarget.value = '';
              }
            }}
          />
        </DropdownChildren>
      </FieldWrapper>
      <FieldWrapper label="Units">
        <Row>
          <Button
            label="Metric"
            onClick={() => setMetricUnits(true)}
            appearance={metricUnits ? 'primary' : 'secondary'}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          />
          <Button
            label="Imperial"
            onClick={() => setMetricUnits(false)}
            appearance={metricUnits ? 'secondary' : 'primary'}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          />
        </Row>
      </FieldWrapper>
      {deleting ? (
        <Row>
          Are you sure?
          <Button
            label="Yes, delete"
            appearance="secondary"
            size="small"
            onClick={deleteClient}
            style={{ margin: '0 10px' }}
          />
          <Button
            label="Cancel"
            appearance="secondary"
            size="small"
            onClick={() => setDeleting(false)}
          />
        </Row>
      ) : (
        <div>
          <Row>
            <Button
              disabled={loading}
              label={isNewClient ? 'Create' : 'Save'}
              onClick={updateClient}
              size="small"
            />
          </Row>
          {!isNewClient && (
            <DeleteButton onClick={() => setDeleting(true)}>
              Delete Company
            </DeleteButton>
          )}
        </div>
      )}
    </Form>
  );
};
