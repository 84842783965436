import styled from 'styled-components';

import { theme, toRem } from '@awareness-ui/design';

const Input = styled.input`
  width: 300px;
  height: ${toRem(theme.input.height)};
  padding: 0 ${toRem(11)};
  border-radius: ${toRem(5.5)};
  border: ${toRem(1)} solid ${theme.color.border.medium};
  outline: none;
  background-color: ${theme.color.input.bg.default};
  font-family: ${theme.font.family.inter};
  font-weight: ${theme.font.weight.regular};
  font-size: ${toRem(13.25)};
  line-height: 100%;
  color: ${theme.color.text[100]};

  &::placeholder {
    color: ${theme.color.placeholder};
  }

  &:hover {
    border-color: ${theme.color.input.border.hover};
    background-color: ${theme.color.input.bg.hover};
  }

  &:focus {
    border-color: ${theme.color.input.border.focus};
  }

  &:disabled {
    border: none;

    &:hover {
      background-color: ${theme.color.white};
    }

    &:focus {
      background-color: ${theme.color.white};
    }
  }
`;

export default Input;
