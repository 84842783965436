import React from 'react';

import styled from 'styled-components';

import { toRem } from '@awareness-ui/design';

import { Icon } from '../Icon';
import PaginationButton from './PaginationButton';

interface PaginationItemProps extends React.ButtonHTMLAttributes<any> {
  previous?: boolean;
  next?: boolean;
  label?: number | string;
  isActive?: boolean;
  onClick?: () => void;
}

const PrevIcon = styled(Icon)`
  position: relative;
  right: ${toRem(1)};
`;

const NextIcon = styled(Icon)`
  position: relative;
  left: ${toRem(1)};
`;

const PaginationItem: React.FC<PaginationItemProps> = ({
  previous,
  next,
  label,
  ...props
}) => {
  return (
    <PaginationButton {...props}>
      {label && label}
      {previous && (
        <PrevIcon icon="Previous" width={7} height={11} aria-label="Previous" />
      )}
      {next && <NextIcon icon="Next" width={7} height={11} aria-label="Next" />}
    </PaginationButton>
  );
};

export default PaginationItem;
