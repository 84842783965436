export function getRolesFormData(roles: string[], newRoles: string[]) {
  const add = newRoles.filter((r) => !roles.includes(r));
  const remove = roles.filter((r) => !newRoles.includes(r));
  if (!add.length && !remove.length) {
    return;
  }
  const formData = new FormData();
  add.forEach((i) => formData.append('add[]', i));
  remove.forEach((i) => formData.append('remove[]', i));
  return formData;
}
