import React, { useState, useEffect } from 'react';
import { Button } from '@awareness-ui/components';
import { ChannelName } from '@awareness/util';
interface Props {
  title: ChannelName;
  data: string;
}

export const TextDownloader: React.FC<Props> = ({ title, data }) => {
  const [downloadLink, setDownloadLink] = useState('');

  useEffect(() => {
    const download = new Blob([data], { type: 'text/csv' });
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);
    setDownloadLink(window.URL.createObjectURL(download));
  }, []);

  const reportName = `${title}-report-${new Date().toLocaleDateString()}.csv`;

  return (
    <a download={reportName} href={downloadLink}>
      <Button
        style={{ marginBottom: 20, marginLeft: 20 }}
        size="small"
        appearance="primary"
        label="Download CSV"
      />
    </a>
  );
};
