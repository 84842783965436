import React from 'react';

import styled from 'styled-components';

import { toRem } from '@awareness-ui/design';

interface LogoProps {
  className?: string;
  fill: string;
}

const SVG = styled.svg`
  width: ${toRem(30)};
  height: ${toRem(30)};
`;

const LogoMark: React.FC<LogoProps> = ({ className, fill }) => {
  return (
    <SVG className={className} viewBox="0 0 30 30" aria-label="Hayden Data">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0778 23.907C13.0778 27.2723 10.3001 30 6.87316 30C3.44699 30 0.668945 27.2723 0.668945 23.907C0.668945 20.5424 3.44699 17.8143 6.87316 17.8143C10.3001 17.8143 13.0778 20.5424 13.0778 23.907ZM10.7166 11.4618C10.7166 13.5462 8.99612 15.2361 6.87316 15.2361C4.75059 15.2361 3.02976 13.5462 3.02976 11.4618C3.02976 9.37703 4.75059 7.68752 6.87316 7.68752C8.99612 7.68752 10.7166 9.37703 10.7166 11.4618ZM9.17936 2.85866C9.17936 4.10953 8.14694 5.12339 6.87316 5.12339C5.59978 5.12339 4.56698 4.10953 4.56698 2.85866C4.56698 1.60817 5.59978 0.593933 6.87316 0.593933C8.14694 0.593933 9.17936 1.60817 9.17936 2.85866ZM19.4625 16.8822C17.9613 18.3564 15.5281 18.3564 14.0269 16.8822C12.5261 15.4084 12.5261 13.0182 14.0269 11.5444C15.5281 10.0702 17.9613 10.0702 19.4625 11.5444C20.9633 13.0182 20.9633 15.4084 19.4625 16.8822ZM24.6343 9.66845C23.7336 10.553 22.2735 10.553 21.3728 9.66845C20.4725 8.78394 20.4725 7.35007 21.3728 6.46594C22.2735 5.58105 23.7336 5.58105 24.6343 6.46594C25.5346 7.35007 25.5346 8.78394 24.6343 9.66845ZM19.5466 27.6817C17.424 27.6817 15.7032 25.9918 15.7032 23.907C15.7032 21.8226 17.424 20.1327 19.5466 20.1327C21.6696 20.1327 23.3904 21.8226 23.3904 23.907C23.3904 25.9918 21.6696 27.6817 19.5466 27.6817ZM28.5742 26.1717C27.3004 26.1717 26.268 25.1578 26.268 23.907C26.268 22.6565 27.3004 21.6426 28.5742 21.6426C29.8476 21.6426 30.88 22.6565 30.88 23.907C30.88 25.1578 29.8476 26.1717 28.5742 26.1717Z"
        fill={fill}
      />
    </SVG>
  );
};

export default LogoMark;
