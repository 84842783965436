import React from 'react';

import styled from 'styled-components';

import { theme } from '@awareness-ui/design';

interface LayoutSplitProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
  className?: string;
}

const Layout = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
`;

const Section = styled.section`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100vh;

  @media (min-width: ${theme.breakpoint.med}px) {
    width: 50%;
  }
`;

const Left = styled(Section)`
  background-color: ${theme.color.white};
`;

const Right = styled(Section)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  display: none;
  background-color: ${theme.color.secondary[100]};

  @media (min-width: ${theme.breakpoint.med}px) {
    display: flex;
  }
`;

const LayoutSplit: React.FC<LayoutSplitProps> = ({
  left,
  right,
  className,
}) => {
  return (
    <Layout className={className}>
      <Left>{left}</Left>
      <Right>{right}</Right>
    </Layout>
  );
};

export default LayoutSplit;
